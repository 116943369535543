import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import {
  GetFindClassByIdOnRegistrationModal,
  GetFindClassByIdOnRegistrationModalQuery,
  GetFindClassByIdOnRegistrationModalQueryVariables,
  GetRegistrationForWaitingListAction,
  GetRegistrationForWaitingListActionQuery,
  GetRegistrationForWaitingListActionQueryVariables,
  LearningArrangementType,
} from "@/graphql";
import { useLazyQuery } from "@apollo/client";
import { Form } from "@thepiquelab/archus-components-web";
import { useEffect, useState } from "react";
import { useCheckShouldOfferSeatToWaitingListWarning } from "../CheckWaitingList/useCheckWaitingList";
import SetWaitingListRegistration from "./SetWaitingListRegistration";

export const useSetWaitingListRegistration = (): {
  modal: any;
  setModalProps: (newProps: { registrationId: string }) => void;
  loading: boolean;
} => {
  const [form] = Form.useForm();
  const isShowHybridGroup = useFeatureHook([
    KEYWORD_ENUM.global_hybrid_grouping,
  ]);

  const [visible, setVisible] = useState(false);
  const [hybridVisible, setHybridVisible] = useState(false);
  const [attendingVisible, setAttendingVisible] = useState(false);

  const {
    checkOfferSeatToWaitingListWarning,
    modal: checkShouldOfferSeatToWaitingListWarningModal,

    isCheckShouldOfferSeatToWaitingListWarning,
  } = useCheckShouldOfferSeatToWaitingListWarning();

  const [
    queryRegistration,
    { data: actionRegistration, loading: registrationLoading },
  ] = useLazyQuery<
    GetRegistrationForWaitingListActionQuery,
    GetRegistrationForWaitingListActionQueryVariables
  >(GetRegistrationForWaitingListAction, { fetchPolicy: "network-only" });

  const [queryClass, { data: eligibilityData, loading: eligibilityLoading }] =
    useLazyQuery<
      GetFindClassByIdOnRegistrationModalQuery,
      GetFindClassByIdOnRegistrationModalQueryVariables
    >(GetFindClassByIdOnRegistrationModal, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (actionRegistration?.registration?.class?.id) {
      queryClass({
        variables: {
          classId: actionRegistration?.registration?.class?.id,
          studentId: actionRegistration?.registration?.student?.id,
          skipHoliday: true,
        },
      });
    }
  }, [actionRegistration?.registration]);

  useEffect(() => {
    const check = async (): Promise<void> => {
      if (eligibilityData?.classFromStudent?.id) {
        // if (actionRegistration?.registration?.effectedWaitingLists?.length) {
        try {
          await checkOfferSeatToWaitingListWarning(
            actionRegistration?.registration?.class?.id,
            actionRegistration?.registration?.startDate,
            {
              isWaitingListToReserved: true,
              studentId: actionRegistration?.registration?.student?.id,
            }
          );
        } catch (e) {
          return;
        }
        // }

        if (
          actionRegistration?.registration?.class?.learningArrangement ===
          LearningArrangementType.Hybrid
        ) {
          setHybridVisible(true);
          form.setFieldsValue({
            preferredLearningArrangement:
              actionRegistration?.registration?.enrollment
                ?.preferredLearningArrangement,
            group: isShowHybridGroup
              ? actionRegistration?.registration?.enrollment?.group
              : null,
          });
        } else {
          setAttendingVisible(true);
        }
      }
    };
    check();
  }, [eligibilityData]);

  const setModalProps = (newProps: { registrationId: string }): void => {
    queryRegistration({ variables: { id: newProps?.registrationId } });
  };

  return {
    modal: (
      <>
        <SetWaitingListRegistration
          visible={visible}
          actionRegistration={actionRegistration}
          eligibilityData={eligibilityData}
          form={form}
          setVisible={setVisible}
          attendingVisible={attendingVisible}
          hybridVisible={hybridVisible}
          setAttendingVisible={setAttendingVisible}
          setHybridVisible={setHybridVisible}
        />
        {checkShouldOfferSeatToWaitingListWarningModal}
      </>
    ),
    setModalProps,
    loading:
      registrationLoading ||
      eligibilityLoading ||
      isCheckShouldOfferSeatToWaitingListWarning,
  };
};

import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition, Observable } from "@apollo/client/utilities";
import Sentry from "@thepiquelab/web-sentry";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLError, print } from "graphql";
import { Client, ClientOptions, createClient } from "graphql-ws";
import Package from "../package.json";
import { AppConfig } from "./config";
import fragmentTypes from "./fragmentTypes.json";
import { showGlobalNotificationError } from "./utils/showGlobalNotificationError";

class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) =>
      this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ""}`
                )
              );
            }

            return sink.error(
              new Error(
                Array.isArray(err)
                  ? (err as GraphQLError[])
                      .map(({ message }) => message)
                      .join(", ")
                  : (err as GraphQLError).message
              )
            );
          },
        }
      )
    );
  }
}

const wsLink = (getToken: () => Promise<string>): ApolloLink =>
  new WebSocketLink({
    url: AppConfig.SubscriptionsEndpoint,
    connectionParams: async () => {
      const token = await getToken();
      if (!token) {
        return {};
      }
      return {
        Authorization: `Bearer ${token}`,
        "custom-sub": "",
      };
    },
  });

const errorLink = (logout: () => void) =>
  onError((error) => {
    const { graphQLErrors, networkError } = error;
    if (graphQLErrors) {
      console.log("graphQLErrors", graphQLErrors);
      graphQLErrors.forEach((e) => {
        console.error(e);
        console.error(`${e.message} in [${e.path}]`);
      });
      showGlobalNotificationError(graphQLErrors as any, error as any);
    }
    if (networkError) {
      Sentry.captureMessage(`[networkError] ${networkError.message}`, "fatal");
      if ((networkError as any)?.statusCode === 401) {
        setTimeout(() => logout(), 3000);
      }
    }
  });

const authLink = (getToken: () => Promise<string>): ApolloLink =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        getToken()
          .then((newToken) => {
            operation.setContext((context: Record<string, any>) => ({
              headers: {
                Authorization: `Bearer ${newToken}`,
                "custom-sub": "",
                ...context.headers,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            observer.error(error);
          });
      })
  );

const namedLink = (): ApolloLink =>
  new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      uri: `${AppConfig.GraphqlEndpoint}?${operation.operationName}`,
    }));
    return forward ? forward(operation) : null;
  });

let client: ApolloClient<NormalizedCacheObject> = null;

const createApolloClient = (
  getToken: () => Promise<string>,
  logout: () => void
): ApolloClient<NormalizedCacheObject> => {
  if (client) return client;

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink(getToken),
    authLink(getToken)
  );

  const links: ApolloLink[] = [
    errorLink(logout),
    link,
    // add the named link upon development
    ...(process.env.NODE_ENV !== "production" ? [namedLink()] : []),
    createUploadLink({ uri: AppConfig.GraphqlEndpoint }),
  ];

  client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: fragmentTypes.possibleTypes,
      // resultCaching: false,
    }),
    link: from(links),
    name: `Archus CRM`,
    version: Package.version,
    defaultOptions: {
      query: {
        errorPolicy: "all",
      },
    },
  });

  return client;
};

export default createApolloClient;

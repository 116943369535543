import { Link } from "react-router-dom";

interface IClassLinkProps {
  classId: string;
  linkText?: string;
  children?: React.ReactNode;
}
const ClassLink: React.FC<IClassLinkProps> = ({
  classId,
  linkText,
  children,
}) => {
  if (!classId) return <>{children}</>;

  const linkUrl = `/course-management/class/${classId?.trim()}`;
  return (
    <Link
      to={linkUrl}
      target={"_blank"}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <span className="font_semibold text-primary-blue">
        {linkText || children}
      </span>
    </Link>
  );
};

export default ClassLink;

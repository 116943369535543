import { ClassEligibility, GetSetDateModalClassByIdQuery } from "@/graphql";
import moment from "@thepiquelab/web-moment";

const processLessonsData = (
  lessons: GetSetDateModalClassByIdQuery["class"]["lessons"]
): GetSetDateModalClassByIdQuery["class"]["lessons"] => lessons ?? [];

export const getAvailableLessons = (
  lessons: GetSetDateModalClassByIdQuery["class"]["lessons"],
  eligibility?: ClassEligibility
): GetSetDateModalClassByIdQuery["class"]["lessons"] =>
  processLessonsData(lessons).filter((l) =>
    eligibility?.lessonDates
      ? eligibility?.lessonDates?.some((date) =>
          moment(l.lessonStart).isSame(date, "day")
        )
      : true
  );

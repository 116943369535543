import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { DraggableItemProps } from "./types";

/**
 * Draggable list's Item component
 * that is used inside render of DraggableList component
 * 
 * @example Draggable Item
 * ```
 * <DraggableList.Item
    key="unique-id"
    id="unique-id"
    content="Display text"
    index={0}
    onRemove={(itemId) => console.log('Item ID removed: ', itemId)}
  />
  * ```
 */
const DraggableItem: React.FC<DraggableItemProps> = (props) => {
  const { index, id, content, onRemove } = props;

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          className="c-bg-white c-rounded-default c-shadow c-px-3 c-py-5 c-mb-3 c-flex c-items-center"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div {...provided.dragHandleProps} className="c-mr-4 c-cursor-move">
            <MoreOutlined />
          </div>
          <div className="c-truncate c-font-semibold c-flex-1">{content}</div>
          {onRemove && (
            <CloseOutlined
              className="c-justify-self-end c-cursor-pointer"
              onClick={() => onRemove(id)}
            />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;

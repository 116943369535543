import { Skeleton } from "@thepiquelab/archus-components-web";

const ReceiptDetailSkeleton = () => (
  <div className="px-6 flex-row gap-4 w-a4 py-6">
    <div className="flex gap-2 justify-items-end">
      <Skeleton.Button active />
      <Skeleton.Button active />
      <Skeleton.Button active />
    </div>
    <div className="flex gap-2 justify-between mt-8">
      <Skeleton.Input active />
      <Skeleton.Button active size="default" />
    </div>
    <div className="mt-8">
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  </div>
);

export default ReceiptDetailSkeleton;

/* eslint-disable */
export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE_CORE_SERVICE,
    redirect_uri: window.location.origin ?? "http://localhost:3000",
    scope: process.env.REACT_APP_AUTH0_SCOPES,
    organization: process.env.REACT_APP_AUTH0_ORGANIZATION,
  },
};

export const AppConfig = {
  BackendBaseUrl:
    process.env.REACT_APP_ARCHUS_CORE_SERVICE_ENDPOINT ||
    "http://localhost:7001",
  CsdControllerServiceUrl:
    process.env.REACT_APP_ARCHUS_CSD_CONTROLLER_SERVICE_ENDPOINT,
  UserServiceUrl:
    process.env.REACT_APP_ARCHUS_USER_SERVICE_ENDPOINT ||
    `http://localhost:7103/api`,
  FinanceServiceUrl:
    process.env.REACT_APP_ARCHUS_FINANCE_SERVICE_ENDPOINT ||
    `http://localhost:7106/api`,
  EnrollmentServiceUrl:
    process.env.REACT_APP_ARCHUS_ENROLLMENT_SERVICE_ENDPOINT ||
    `http://localhost:7101/api`,
  CourseServiceUrl:
    process.env.REACT_APP_ARCHUS_COURSE_SERVICE_ENDPOINT ||
    `http://localhost:7102/api`,
  ExportServiceUrl:
    process.env.REACT_APP_ARCHUS_EXPORT_SERVICE_ENDPOINT ||
    `http://localhost:7105/api`,
  GraphqlEndpoint: `${process.env.REACT_APP_ARCHUS_CORE_SERVICE_ENDPOINT}/${process.env.REACT_APP_ARCHUS_GRAPHQL_PATH}`,
  SubscriptionsEndpoint:
    process.env.REACT_APP_ARCHUS_SUBSCRIPTION_ENDPOINT ||
    "ws://localhost:7001/subscriptions",
  OrganizationName: process.env.REACT_APP_ORGANIZATION_NAME || "Pilab",
  redirectPathname:
    process.env.REACT_APP_REDIRECT_PATH || window.location.pathname,
  taxPercentage: process.env.REACT_APP_TAX_PERCENTAGE
    ? Number(process.env.REACT_APP_TAX_PERCENTAGE)
    : 0.09,

  ParkNParcelPrintWaybillDomain:
    process.env.PARK_N_PARCEL_PRINT_WAYBILL_DOMAIN ||
    "https://staging-test.parknparcel.com",
  ParkNParcelTrackingDomain:
    process.env.PARK_N_PARCEL_TRACKING_DOMAIN ||
    "https://staging-tracking.parknparcel.com",
};

export const SentryConfig = {
  DSN: process.env.REACT_APP_SENTRY_DSN,
  Environment: process.env.REACT_APP_SENTRY_ENV || "local",
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    : 0.1,
};
export const IsProduction = SentryConfig.Environment === "production";

export const IsPermissionDebugMode =
  process.env.REACT_APP_PERMISSION_DEBUG_MODE === "true";

export const xeroCallBackUrlFlag = /accounting.budgets.read/;

export const CourseEligibilityBaseUrl =
  process.env.REACT_APP_MICROFRONTEND_CET || "https://cet-develop.thepilab.com";

export const IsQiankunPrefetch = process.env.REACT_APP_QIANKUN_PREFETCH;

export const SystemUserBaseUrl =
  process.env.REACT_APP_SYSTEMUSER_CET ||
  "archus-administration-gmyzdwk63-cavalry.vercel.app";

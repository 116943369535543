import { TaskType } from "@/graphql";
import { Button, Modal, Table } from "@thepiquelab/archus-components-web";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useQueryTasks } from "../Task/hooks";
import { CustomCommonColumn, CustomCommonColumnKey } from "../Task/TaskTable";
import { useUpdateTasks } from "../Task/useUpdateTasks";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const CompleteTaskModalButton: FC<{ taskType: TaskType }> = (props) => {
  const { taskType } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ids, setIds] = useState<string[]>([]);

  const columns = [
    CustomCommonColumn[CustomCommonColumnKey.TaskStatus],
    CustomCommonColumn[CustomCommonColumnKey.StudentName],
    CustomCommonColumn[CustomCommonColumnKey.StudentID],
    CustomCommonColumn[CustomCommonColumnKey.PrimaryParentName],
  ]?.map((item) => ({ ...item, sorter: false }));

  const { completeTasks, modal, selectedIds, setSelectedIds } = useUpdateTasks(
    taskType,
    completeCallback
  );

  const { fetchIndexTasks, data } = useQueryTasks();

  const dataSource = useMemo(
    () => data?.tasksWithIndex?.items ?? [],
    [JSON.stringify(data)]
  );

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (ids?.length > 0) {
      fetchIndexTasks({
        taskIds: ids,
      });
    }
  }, [ids?.length]);

  function initData(): void {
    try {
      const result = localStorage.getItem("taskIds");
      if (result) {
        setIds(JSON.parse(result));
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleOk = (): void => {
    if (selectedIds?.length === 0) {
      onClose();
      return;
    }
    completeTasks(selectedIds);
  };

  function completeCallback(successIds?: string[]): void {
    if (ids?.length > 0) {
      const newIds = ids?.filter((id) => !successIds?.includes(id));
      setIds(newIds);
      localStorage.setItem("taskIds", JSON.stringify(newIds));
    }
    onClose();
  }

  const handleCancel = (): void => {
    onClose();
  };
  const onClose = (): void => {
    setIsModalOpen(false);
    setSelectedIds([]);
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys: string[]) => {
      setSelectedIds(selectedRowKeys);
    },
  };

  return (
    <>
      <Button className="mr-2" onClick={() => setIsModalOpen(true)}>
        Complete Task
      </Button>
      <Modal
        width={850}
        title="Complete Task"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Complete"
      >
        <Table
          rowKey={"id"}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          scroll={{ y: "60vh" }}
          pagination={false}
          columns={columns}
          dataSource={ids?.length > 0 ? dataSource : []}
        />
      </Modal>
      {modal}
    </>
  );
};

export default CompleteTaskModalButton;

import empty from "@/assets/images/empty.svg";
import { OptionType } from "@/components/widget/FilterFormOption/types";
import { GetPaymentRecordModalDataQuery } from "@/graphql";
import { Empty } from "@thepiquelab/archus-components-web";
import { FC } from "react";
import { PaymentInfo } from "../../types";
import PaymentRecordCard from "../PaymentRecordCard";

export interface IPaymentRecordListProps {
  data: GetPaymentRecordModalDataQuery;
  paymentRecordStatus?: string;
  NameOfBankOptions: OptionType[];
  PaymentProcessorOptions: OptionType[];
  activeItem: PaymentInfo;
  onEdit: (data: PaymentInfo) => void;
  onVoid: (paymentId: string) => void;
}

const PaymentRecordList: FC<IPaymentRecordListProps> = (props) => {
  const {
    data,
    paymentRecordStatus,
    NameOfBankOptions,
    PaymentProcessorOptions,
    activeItem,
    onEdit,
    onVoid,
  } = props;

  const paidPayments = data?.invoice?.payments?.filter((e) =>
    paymentRecordStatus === "paid" ? !e?.isVoid : e?.isVoid
  );

  if (!paidPayments?.length) {
    return (
      <div className="h-full flex items-center justify-center my-4">
        <Empty
          image={empty}
          description={<span className="text-primary-navyLight">No Data</span>}
        />
      </div>
    );
  }

  return (
    <>
      {paidPayments.map((p, index) => (
        <PaymentRecordCard
          key={p ? p.id : index}
          paymentData={p}
          NameOfBankOptions={NameOfBankOptions}
          PaymentProcessorOptions={PaymentProcessorOptions}
          isEditing={activeItem?.id === p?.id}
          onEdit={onEdit}
          onVoid={onVoid}
        />
      ))}
    </>
  );
};

export default PaymentRecordList;

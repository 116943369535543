import { OptionType } from "@/components/widget/FilterFormOption/types";
import { useQuery } from "@apollo/client";
import {
  Button,
  DatePicker,
  FilterDrawer,
  FilterForm,
  FilterItemOptionTypeEnum,
  FormInstance,
  Row,
  Select,
} from "@thepiquelab/archus-components-web";
import { formatPhoneNumber } from "@thepiquelab/phonenumber";
import React, { useEffect, useMemo, useRef } from "react";
import {
  GetWhatsappAccounts,
  GetWhatsappAccountsQuery,
  GetWhatsappAccountsQueryVariables,
  TaskStatus,
} from "../../../graphql";
import { customizeEnumDisplay } from "../../../utils/customizeEnumValue";
import { enumToArray } from "../../../utils/enumUnit";
import formatLabel from "../../../utils/formatLabel";
import { FilterProps } from "../../common/AdvanceGrid/types";

const TaskFilter: React.FC<FilterProps<Record<string, any>>> = (props) => {
  const { value, visible, onChange, onClose } = props;

  const formRef = useRef<FormInstance<any>>();
  const { data: whatsappAccounts } = useQuery<
    GetWhatsappAccountsQuery,
    GetWhatsappAccountsQueryVariables
  >(GetWhatsappAccounts, {
    fetchPolicy: "cache-first",
    variables: {
      filterInput: { isArchived: false },
      pageInfo: { pageIndex: 0, pageSize: 0 },
    },
  });

  const whatsappAccountOptions: OptionType[] = useMemo(
    () =>
      whatsappAccounts?.whatsappAccounts?.items?.map((item) => ({
        label: `${item.name} - ${formatPhoneNumber(item.number)}`,
        value: item.id,
      })) || [],
    [whatsappAccounts]
  );

  useEffect(() => {
    if (visible && value && formRef?.current) {
      formRef?.current?.setFieldsValue(value);
    }
  }, [visible]);

  return (
    <FilterDrawer
      visible={visible}
      onClose={() => {
        formRef?.current?.resetFields();
        formRef.current = null;
        onClose?.();
      }}
    >
      <FilterDrawer.Content>
        <FilterForm
          onFinish={(val) => {
            formRef.current = null;

            const res = { ...val };
            if (val?.whatsappAccountId === "WithoutWALine") {
              res.isWithoutWAline = true;
            }

            onChange(res, val);
          }}
          ref={formRef}
          filterOptions={[
            {
              label: formatLabel("Status"),
              field: "status",
              type: FilterItemOptionTypeEnum.Checkbox,
              options: {
                label: formatLabel("All statuses"),
                options: enumToArray(TaskStatus)
                  .filter((s) => s.value !== TaskStatus.Void)
                  .map((s) => ({
                    label: customizeEnumDisplay(s.value, s.key),
                    value: s.value,
                  })),
              },
            },
            {
              field: "dueDateRange",
              label: formatLabel("Due On"),
              type: FilterItemOptionTypeEnum.ReactNode,
              render: () => (
                <DatePicker.RangePicker
                  className="w-full"
                  format="D MMM YYYY"
                  getPopupContainer={(triggerNode: any) =>
                    triggerNode.parentNode
                  }
                />
              ),
            },
            {
              field: "lastModifiedDateRange",
              label: formatLabel("Last Modified At"),
              type: FilterItemOptionTypeEnum.ReactNode,
              render: () => (
                <DatePicker.RangePicker
                  className="w-full"
                  format="D MMM YYYY"
                  getPopupContainer={(triggerNode: any) =>
                    triggerNode.parentNode
                  }
                />
              ),
            },
            {
              label: formatLabel("Parent WA Line"),
              field: "whatsappAccountId",
              type: FilterItemOptionTypeEnum.ReactNode,
              render: () => (
                <Select
                  placeholder="All Parent WA Lines"
                  options={[
                    { label: "Without WA Line", value: "WithoutWALine" },
                    ...whatsappAccountOptions,
                  ]}
                  allowClear
                />
              ),
            },
          ]}
        />
      </FilterDrawer.Content>
      <FilterDrawer.Footer>
        <Row justify="end">
          <Button
            onClick={() => {
              formRef?.current?.resetFields();
            }}
          >
            Clear
          </Button>
          <Button
            className="c-ml-3"
            type="primary"
            onClick={() => {
              formRef?.current?.submit();
            }}
          >
            Apply
          </Button>
        </Row>
      </FilterDrawer.Footer>
    </FilterDrawer>
  );
};

export default TaskFilter;

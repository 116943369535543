/* eslint-disable max-lines */
/* eslint-disable import/no-cycle  */
/* eslint-disable complexity */
import { ReactComponent as ToolIcon } from "@/assets/images/Tool.svg";
import AuthorizedByPermission, {
  EnumAuthHandleType,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import FeatureWrapper from "@/components/settings/Features/FeatureWrapper";
import { KEYWORD_ENUM } from "@/components/settings/Features/utils";
import { DisplayEditor } from "@/experimental/components/DisplayEditor";
import { SortDirection, UserCustomiseColumn } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import {
  CaretDownOutlined,
  ExportOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Skeleton,
  Space,
  Table,
  message,
} from "@thepiquelab/archus-components-web";
import { useBoolean, useSetState } from "ahooks";
import { debounce, isArray, isEmpty, isEqual, uniqWith } from "lodash";
import moment from "@thepiquelab/web-moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconFilter } from "../../../assets/images/IconFilter.svg";
import { ReactComponent as IconList } from "../../../assets/images/IconList.svg";
import DataPageHeader from "../DataPage/DataPageHeader";
import useUserCustomise from "./hooks/useUserCustomise";

import { TableItemSkeleton, TableSkeleton } from "../Skeleton/TableSkeleton";
import {
  EnhancedTableProps,
  QueryInputs,
  SortDescriptor,
} from "./EnhancedTableType";
import {
  calcAvailableFields,
  calcFieldsWidth,
  calcTableScrollWidth,
  formatFieldsWidth,
  transformFields,
} from "./EnhancedTableUtil";

export {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "./EnhancedTableType";
export type {
  DataPageColumnProps,
  EnhancedTableWidthType,
  QueryInputs,
} from "./EnhancedTableType";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function EnhancedTable<T = Record<string, any>>(
  props: EnhancedTableProps<T>
): React.ReactElement {
  const {
    tableName,
    className,
    title,
    data,
    total,
    loadMore,
    loading,
    loadMoreLoading,
    fields,
    onRowClick,
    commands,
    showMenuBar,
    searchable,
    defaultSortState,
    onQueryChange,
    toolbar,
    filter: AdvanceFilter,
    filterProps: advanceFilterProps,
    addButtonProp,
    customToolbarItemLeft,
    customToolbarItemMiddle,
    customToolbarItemRight,
    getCustomHeaderItem,
    inlineFilter: InlineFilter,
    isShowHeader = true,
    description,
    toolTip,
    height = "100%",
    isShowCustomise = true,
    isShowExport = true,
    isShowGroupExport = false,
    groupExportLoading = false,
    showExportPermissions,
    onExportCsv,
    onExportPdf,
    pageSize = 20,
    pageIndex = 0,
    showSelect,
    defaultSelectedItems = [],
    onSelectChange,
    checkboxDisable,
    showToolBarFilter,
    setRowClassName,
    isFilterNextToSearch,
    isStudentReport = false,
    gridTopToolBar,
    userCustomiseColumnsList,
    userCustomiseLoadState,
    handleCustomColumnsApply,
    handleCustomColumnsReset,
    showCustomToolbarItemMiddleFilter,
    useColumnNameInFilter = false,
    maxSelectedItemsNumber,
    initFilterValue,
    autoPagination,
  } = props;
  const navigate = useNavigate();

  /**
   * Boolean value to see if there is an external user customized
   * columns provided
   */
  const hasExternalCustomiseColumns =
    userCustomiseColumnsList &&
    userCustomiseLoadState &&
    handleCustomColumnsApply &&
    handleCustomColumnsReset;

  /**
   * Internal user customise columns state
   * that is initialized only if hasExternalCustomiseColumns = false
   */
  const {
    list: internalUserColumns,
    loadState: internalUserColumnsLoading,
    upsertCustomiseColumns,
    resetCustomiseColumns,
  } = useUserCustomise(hasExternalCustomiseColumns ? null : tableName);

  /**
   * Function callback to be called when DisplayEditor's
   * Apply button is clicked
   */
  const handleDisplayEditorApply = async (
    columns: UserCustomiseColumn[],
    savePreset?: boolean
  ): Promise<void> => {
    if (handleCustomColumnsApply) {
      await handleCustomColumnsApply(columns, savePreset);
    } else {
      await upsertCustomiseColumns(columns);
    }

    hideCustomModal();
  };

  /**
   * Function callback to be called when DisplayEditor's
   * Reset button is clicked
   */
  const handleDisplayEditorReset = (): void => {
    if (handleCustomColumnsApply) {
      handleCustomColumnsReset();
      return;
    }
    resetCustomiseColumns();
  };

  const consolidatedUserColumns = React.useMemo(
    () => internalUserColumns,
    [internalUserColumns]
  );

  const [selectedItems, setSelectedItems] =
    useState<Array<T>>(defaultSelectedItems);

  useEffect(() => {
    setSelectedItems(defaultSelectedItems);
  }, [defaultSelectedItems?.length]);

  const [
    isShowCustomModal,
    { setTrue: showCustomModal, setFalse: hideCustomModal },
  ] = useBoolean(false);
  const [query, setQuery] = useSetState<QueryInputs>({});

  const [filterVisible, { toggle: toggleFilter }] = useBoolean(false);

  const [sortState, setSortState] = useState<SortDescriptor>(defaultSortState);

  const [initFilterFormValue, setInitFilterFormValue] =
    useState<Record<string, any>>(initFilterValue);

  useEffect(() => {
    if (sortState) {
      const field = consolidatedUserColumns.find(
        (item) => item?.columnShowName === sortState.field
      );
      setQuery({
        sortInfo: {
          field: field?.columnName || sortState.field,
          direction:
            sortState.dir === "desc" ? SortDirection.Desc : SortDirection.Asc,
        },
      });
    } else if (query.sortInfo) {
      setQuery({ sortInfo: undefined });
    }
  }, [sortState]);

  useEffect(() => {
    if (onQueryChange) {
      onQueryChange(query);
    }
  }, [query]);

  const handleSearch = (value: string): void => {
    setQuery({
      filterInput: {
        ...(query?.filterInput || {}),
        name: value ? value?.trim() : undefined,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (
    value: Record<string, any>,
    filterFormValue?: Record<string, any>
  ): void => {
    toggleFilter();
    const { dateRange, lessonDateRange, ...res } = value;

    const filterInput = { ...res };

    Object.keys(filterInput).forEach((key) => {
      const filterInputValue = filterInput[key];
      if (isArray(filterInputValue) && isEmpty(filterInputValue)) {
        filterInput[key] = null;
      }
    });

    if (dateRange?.start && dateRange?.end) {
      filterInput.dateRange = {
        start: moment(dateRange?.start).startOf("day").format(),
        end: moment(dateRange?.end).endOf("day").format(),
      };
    } else {
      filterInput.dateRange = undefined;
    }
    if (lessonDateRange?.start && lessonDateRange?.end) {
      filterInput.lessonDateRange = {
        start: moment(lessonDateRange?.start).startOf("day").format(),
        end: moment(lessonDateRange?.end).endOf("day").format(),
      };
    }

    setQuery({ filterInput: { ...query?.filterInput, ...filterInput } });

    if (filterFormValue) setInitFilterFormValue(filterFormValue);
  };

  const rightBar = AdvanceFilter && (
    <FeatureWrapper keywords={KEYWORD_ENUM.global_filters}>
      <Button className={`btn  flex-shrink-0`} onClick={() => toggleFilter()}>
        <IconFilter />
        <span>Filters</span>
      </Button>
    </FeatureWrapper>
  );

  const fieldTitleList = useMemo(
    () =>
      fields
        .filter((field) => field.isShow !== false)
        .map((field) => field.title),
    [fields]
  );

  const titleFilter = (item: UserCustomiseColumn): boolean => {
    if (!tableName || !consolidatedUserColumns) return true;
    return (
      fieldTitleList.includes(item.columnShowName) ||
      fieldTitleList.includes(formatLabel(item.columnShowName))
    );
  };

  const downloadXls = (): void => {
    if (onExportCsv && data?.length) {
      onExportCsv({
        filterInput: query.filterInput,
      });
    } else {
      message.error({
        content: "There is no data to export",
      });
    }
  };

  const downloadPdf = (): void => {
    if (!selectedItems?.length) {
      message.warning("Please select at least one record to export as PDF.");
      return;
    }
    if (selectedItems?.length > 40) {
      message.error(
        "You can only select a maximum of 40 receipts for export to PDF."
      );
      return;
    }
    onExportPdf?.(selectedItems);
  };

  const isColumnLoading = internalUserColumnsLoading.isLoading && !!tableName;
  const isDataLoading = loading || loadMoreLoading;
  const isColumnOrDataLoading = isDataLoading || isColumnLoading;

  const fieldsShouldDisplay = useMemo(
    () =>
      calcAvailableFields(
        fields,
        tableName,
        consolidatedUserColumns,
        useColumnNameInFilter,
        isColumnLoading,
        commands
      ),
    [
      fields,
      tableName,
      consolidatedUserColumns,
      useColumnNameInFilter,
      commands,
      isColumnLoading,
    ]
  );

  return (
    <Row
      data-testid="EnhancedTable"
      wrap={false}
      className={`h-full w-full flex flex-col ${className} overflow-hidden`}
      style={{ height }}
    >
      <Col className="enhanced-table-header h-maxcontent" flex="0 0 auto">
        {isShowHeader && !getCustomHeaderItem && (
          <DataPageHeader
            title={title}
            total={
              isColumnOrDataLoading ? (
                <Skeleton
                  active
                  paragraph={false}
                  title={{
                    style: {
                      margin: 0,
                      height: 24,
                      width: "75px",
                    },
                  }}
                />
              ) : (
                total
              )
            }
            toolTip={toolTip}
            rightBar={!showToolBarFilter && rightBar}
            description={description}
            addButtonProp={addButtonProp}
          />
        )}
        {getCustomHeaderItem && (
          <div
            className="flex items-center justify-between"
            key="getCustomHeaderItem"
          >
            {getCustomHeaderItem(data)}
            {!showToolBarFilter && rightBar}
          </div>
        )}

        {AdvanceFilter && (
          <AdvanceFilter
            visible={filterVisible}
            value={initFilterFormValue}
            onChange={handleFilterChange}
            onClose={() => toggleFilter()}
            key="AdvanceFilter"
            {...(advanceFilterProps || {})}
          />
        )}

        {showMenuBar && (
          <div className="flex justify-between pb-6" key="showMenuBar">
            <Space size={8} key={"customized"}>
              {customToolbarItemLeft}
              {InlineFilter && (
                <InlineFilter
                  value={query?.filterInput}
                  onChange={(value) => setQuery({ filterInput: value })}
                />
              )}
              {searchable && (
                <Input
                  allowClear
                  size="large"
                  className="search-input"
                  placeholder="Search"
                  prefix={
                    <SearchOutlined className="text-primary-navyLight text-5" />
                  }
                  onChange={debounce((e) => {
                    handleSearch(e.target.value);
                  }, 300)}
                  onPressEnter={debounce((e) => {
                    handleSearch(e.target.value);
                  }, 100)}
                  data-testid="grid-search-input"
                />
              )}

              {customToolbarItemMiddle}
              {showCustomToolbarItemMiddleFilter && rightBar}
              {isStudentReport && (
                <Button
                  className="inline-flex items-center"
                  onClick={() => {
                    navigate("/customers/students/report");
                  }}
                >
                  <IconList className="mr-2 fill-current text-primary-navy" />
                  <span>Generate Report</span>
                </Button>
              )}
            </Space>
            <Space key={"show-export"}>
              {isFilterNextToSearch && rightBar}
              {isShowExport && (
                <FeatureWrapper
                  keywords={KEYWORD_ENUM.global_exports}
                  key="isShowExport"
                >
                  <AuthorizedByPermission
                    permissions={showExportPermissions}
                    authHandleType={EnumAuthHandleType.HIDE}
                  >
                    <Button onClick={downloadXls}>
                      <ExportOutlined />
                      {formatLabel("Export XLS")}
                    </Button>
                  </AuthorizedByPermission>
                </FeatureWrapper>
              )}
              {isShowGroupExport && (
                <FeatureWrapper
                  key={"isShowGroupExport"}
                  keywords={KEYWORD_ENUM.global_exports}
                >
                  <AuthorizedByPermission
                    permissions={showExportPermissions}
                    authHandleType={EnumAuthHandleType.HIDE}
                  >
                    <Dropdown.Button
                      loading={groupExportLoading}
                      icon={<CaretDownOutlined />}
                      className="ml-2"
                      onClick={downloadXls}
                      overlay={
                        <Menu>
                          <Menu.Item
                            className="font-semibold"
                            key={"1"}
                            onClick={downloadPdf}
                          >
                            <div className="flex items-center">
                              <FilePdfOutlined className="mr-2" />
                              Export PDF
                            </div>
                          </Menu.Item>
                          <AuthorizedByPermission
                            permissions={[EnumPermissions.RECEIPT_VIEW_EXPORT]}
                            authHandleType={EnumAuthHandleType.HIDE}
                          >
                            <Menu.Item
                              className="font-semibold"
                              key={"2"}
                              onClick={downloadXls}
                            >
                              <div className="flex items-center">
                                <FileExcelOutlined className="mr-2" />
                                Export XLS
                              </div>
                            </Menu.Item>
                          </AuthorizedByPermission>
                        </Menu>
                      }
                    >
                      <ExportOutlined />
                      Export
                    </Dropdown.Button>
                  </AuthorizedByPermission>
                </FeatureWrapper>
              )}

              {isShowCustomise && tableName && (
                <FeatureWrapper
                  key={"isShowCustomise"}
                  keywords={KEYWORD_ENUM.global_customise_table}
                >
                  <Button
                    className="inline-flex"
                    onClick={() => showCustomModal()}
                  >
                    <ToolIcon className="mr-2 text-primary-navyLight" />
                    <span>{formatLabel("Customise")}</span>
                  </Button>
                </FeatureWrapper>
              )}
              {showToolBarFilter && AdvanceFilter && rightBar}
              {customToolbarItemRight}
            </Space>
          </div>
        )}
        {gridTopToolBar?.()}
      </Col>

      <Col className="overflow-hidden" flex="auto">
        <Row
          wrap={false}
          className={`h-full w-full flex flex-col overflow-hidden`}
        >
          <Col
            className="h-maxcontent overflow-hidden bg-white"
            flex="0 0 auto"
          >
            <div className="px-4">{toolbar}</div>
          </Col>
          <Col className="overflow-hidden" flex="auto">
            <Table
              id="EnhancedTable"
              className="enhanced-table  h-full"
              rowKey={"id"}
              rowClassName={(i) => setRowClassName?.(i as unknown as T) || null}
              scroll={{
                x: calcTableScrollWidth(fieldsShouldDisplay),
                y: "100%",
              }}
              onRow={(record) => ({
                onDoubleClick: (event: any) => {
                  if (
                    event?.nativeEvent?.target?.className ===
                    "ant-checkbox-input"
                  ) {
                    return;
                  }
                  event?.preventDefault();
                  event?.stopPropagation();
                  onRowClick?.(record as unknown as T);
                },
                // onClick: (event) => {
                //   event?.preventDefault();
                //   event?.stopPropagation();
                // },
              })}
              showSorterTooltip={false}
              dataSource={(data as unknown as object[]) || []}
              columns={transformFields(fieldsShouldDisplay)}
              footer={
                loading
                  ? () => (
                      <div className="flex justify-end">
                        <Skeleton.Button active style={{ minWidth: 300 }} />
                      </div>
                    )
                  : null
              }
              pagination={{
                current: autoPagination ? undefined : pageIndex + 1,
                pageSize: autoPagination ? undefined : pageSize,
                total,
                showQuickJumper: true,
                showPrevNextJumpers: true,
                showSizeChanger: true,
                disabled: isColumnOrDataLoading,
                defaultPageSize: 20,
                pageSizeOptions: [5, 10, 15, 20, 25, 30],
              }}
              onChange={async (pagination, filters, sorter: any, extra) => {
                if (extra?.action === "paginate") {
                  await loadMore({
                    pageSize: pagination?.pageSize,
                    pageIndex:
                      pagination?.current > 0 ? pagination?.current - 1 : 0,
                  });
                }
                if (extra?.action === "sort") {
                  if (!sorter?.field) {
                    return;
                  }
                  setSortState({
                    field: sorter?.field,
                    dir: sorter.order === "descend" ? "desc" : "asc",
                  });
                }
              }}
              components={
                isColumnOrDataLoading
                  ? {
                      header: {
                        cell: null,
                        row: null,
                        wrapper: () => (
                          <div
                            style={{ height: 55 }}
                            className="grid items-center visible"
                          >
                            <TableItemSkeleton
                              style={{
                                width:
                                  calcFieldsWidth(fieldsShouldDisplay) || 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                              active
                              columnSize={fieldsShouldDisplay.length || 14}
                              columnWidth={({ index }) => {
                                const widthArray = formatFieldsWidth(
                                  fieldsShouldDisplay
                                ) as number[];
                                return widthArray[index] || 250;
                              }}
                            />
                          </div>
                        ),
                      },
                      body: {
                        cell: null,
                        row: null,
                        wrapper: ({ ...res }) => (
                          <TableSkeleton
                            active
                            size={14}
                            style={{
                              width: calcFieldsWidth(fieldsShouldDisplay) || 0,
                            }}
                            hasTableButton={false}
                            columnWidth={({ index }) => {
                              const widthArray = formatFieldsWidth(
                                fieldsShouldDisplay
                              ) as number[];
                              return widthArray[index] || 250;
                            }}
                            showPagination={false}
                            showBody
                            showHeader={false}
                            columnSize={fieldsShouldDisplay.length || 14}
                          />
                        ),
                      },
                    }
                  : null
              }
              rowSelection={
                showSelect
                  ? {
                      type: "checkbox",
                      fixed: "left",
                      columnWidth: 50,
                      selectedRowKeys: selectedItems?.map((i: any) => i?.id),
                      renderCell: (value, record, index, originNode) => (
                        <div
                          onClick={(e) => e.stopPropagation()}
                          className="enhanced-table-checkbox"
                        >
                          {originNode}
                        </div>
                      ),
                      onSelect: (
                        record,
                        selected,
                        selectedRows,
                        nativeEvent
                      ) => {
                        nativeEvent?.stopPropagation();
                        if (
                          maxSelectedItemsNumber &&
                          selectedRows?.length > maxSelectedItemsNumber
                        ) {
                          return;
                        }
                        setSelectedItems(selectedRows as unknown as T[]);
                        onSelectChange(selectedRows as unknown as T[]);
                      },
                      getCheckboxProps: (record) => ({
                        ...record,
                        disabled: checkboxDisable
                          ? checkboxDisable?.(record as unknown as T)
                          : null,
                      }),
                      onSelectAll: (selected) => {
                        const newSelectedItems = selected
                          ? uniqWith(
                              [
                                ...selectedItems,
                                ...data.filter(
                                  (item) => !checkboxDisable?.(item)
                                ),
                              ],
                              isEqual
                            )
                          : selectedItems.filter(
                              (item) => !data.some((i) => isEqual(i, item))
                            );

                        if (
                          maxSelectedItemsNumber &&
                          newSelectedItems?.length > maxSelectedItemsNumber
                        ) {
                          return;
                        }
                        setSelectedItems(newSelectedItems);
                        onSelectChange(newSelectedItems);
                      },
                    }
                  : null
              }
            />
          </Col>
        </Row>
        {/* tableName is important for the condition so that it does not show a
      loading screen when it is not supposed to be loading userColumns. */}
        {tableName && (
          <DisplayEditor
            visible={isShowCustomModal}
            items={consolidatedUserColumns?.filter(titleFilter) || []}
            onCancel={hideCustomModal}
            onApply={handleDisplayEditorApply}
            onReset={handleDisplayEditorReset}
            loadingState={userCustomiseLoadState || internalUserColumnsLoading}
          />
        )}
      </Col>
    </Row>
  );
}

export default EnhancedTable;

import React from "react";
import { Link } from "react-router-dom";

interface StudentLinkProps {
  id: string;
  linkText?: string;
  children?: React.ReactNode;
}
const StudentLink: React.FC<StudentLinkProps> = ({
  id,
  linkText,
  children,
}) => {
  if (!id) return <>{children}</>;

  const linkUrl = `/customers/students/profile/${id?.trim()}`;
  return (
    <Link
      to={linkUrl}
      target={"_blank"}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <span className="font_semibold text-primary-blue">
        {linkText || children}
      </span>
    </Link>
  );
};

export default StudentLink;

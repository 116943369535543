import { useSalutation } from "@/hooks/reference.hook";
import { UserApi } from "@thepiquelab/web-api";
import { FilterOptionTeacherDTO } from "@thepiquelab/web-types";
import { useRequest } from "ahooks";
import { useMemo } from "react";
import { OptionType } from "../types";

export const useTeacherOptions = (): {
  options: OptionType[];
  loading: boolean;
  teachers: FilterOptionTeacherDTO[];
} => {
  const { formatSalutation } = useSalutation();
  const { data: teacherOptions = [], loading } = useRequest(
    () => UserApi.getTeacherOptions(),
    {
      cacheKey: "teacher-options",
      staleTime: 5000,
    }
  );

  const options = useMemo(
    () =>
      teacherOptions.map((i) => ({
        label: formatSalutation(i.salutation, i.fullName),
        value: i._id,
      })),
    [teacherOptions]
  );

  return {
    options,
    loading,
    teachers: teacherOptions,
  };
};

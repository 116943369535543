import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Space } from "@thepiquelab/archus-components-web";
import { formatDate } from "@thepiquelab/web-moment";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const ReminderTaskAdditionalLessonOffer: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        const { id: classId, shorthand } = enrollment?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Holiday"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(
        value,
        record: TaskFieldsFragment & { isTaskDetailModal: boolean }
      ) {
        const { lesson } = record;
        if (!lesson?.holidays?.length) {
          return <>-</>;
        }
        return lesson?.holidays?.map((r) => (
          <Space
            direction={record?.isTaskDetailModal ? "horizontal" : "vertical"}
          >
            <div>{r?.name}</div>
            <div>{formatDate(r?.period?.start, { format: "SHORT" })}</div>
          </Space>
        ));
      },
    },

    {
      title: formatLabel("Affected Lesson"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { lesson } = record;
        if (!lesson?.id) {
          return <>-</>;
        }
        return (
          <div>
            {`Lesson ${lesson?.lessonNumber} | ${formatDate(
              lesson?.lessonStart,
              { format: "SHORT" }
            )}`}
          </div>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Additional Lesson Offer Reminder")}
        taskType={TaskType.ReminderAdditionalLessonOffer}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReminderTaskAdditionalLessonOffer;

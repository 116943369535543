import { message } from "@thepiquelab/archus-components-web";
import formatLabel from "./formatLabel";

export default function copyTextToClipboard(
  text: string,
  options?: { type?: string; showMessage?: boolean }
) {
  const { type, showMessage = true } = options || {};

  if (type) {
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data);
  } else {
    navigator.clipboard.writeText(text);
  }

  if (showMessage) {
    message.success(formatLabel("copied"));
  }
}

/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
import { ClassSeatDetailDTO } from "@/components/class/NewClassCapacity/classUtils";
import { Enrollment, InvoiceStatus, Lesson } from "@/graphql";
import { EnrollmentCapacityView } from "@/utils/getEnrollmentCapacityView";
import { Col, Row } from "@thepiquelab/archus-components-web";
import { formatDateTime } from "@thepiquelab/web-moment";
import { FC, memo, useMemo } from "react";
import { filterClassStatusText, getEnrollmentCapacityView } from "./utils";

interface Props {
  seats?: ClassSeatDetailDTO[];
  lesson?: Lesson[];
  currentEnrollment?: Enrollment;
  className?: string;
}
const filterTextArray = ["Paid", "Unpaid", "Reserved", "Transferring In"];
const ClassStatusBtn: FC<Props> = (props) => {
  const { seats, currentEnrollment, lesson, className } = props;
  const texts = useMemo(
    () =>
      seats
        .map((i) => {
          const val = getEnrollmentCapacityView(i, currentEnrollment);
          if (val?.[0]?.text) return val;
          return [];
        })
        ?.flat(2),
    [currentEnrollment, seats]
  );

  const findLessonId = (
    texts: Omit<EnrollmentCapacityView, "isHidden">[],
    currentEnrollment?: Enrollment
  ): string => {
    const attendances = currentEnrollment?.attendances;
    if (!attendances?.length) return null;
    if (texts.length === 1) {
      if (filterTextArray.includes(texts[0].text)) {
        return attendances?.[0]?.lesson?._id;
      }
      if (["Transferred Out", "Withdrawn"]?.includes(texts[0].text)) {
        return attendances?.filter(
          (i) => i?.endDateTime === currentEnrollment?.endDate
        )?.[0]?.lesson?._id;
      }
      return attendances?.[attendances?.length - 1]?.lesson?._id;
    }
    return null;
  };

  const currentLesson = useMemo(() => {
    if (!texts.length || !currentEnrollment || !lesson?.length) return null;
    const id = findLessonId(texts, currentEnrollment);
    const data = lesson.filter((i) => i._id === id)?.[0] || null;
    return data;
  }, [currentEnrollment, lesson, texts]);

  const UnpaidDueDate = useMemo(() => {
    if (
      texts?.length === 1 &&
      texts[0].text === "Unpaid" &&
      currentEnrollment?.invoices?.length
    ) {
      const invoiceIndex = currentEnrollment?.invoices?.findIndex(
        (i) => ![InvoiceStatus.Void, InvoiceStatus.Draft].includes(i.status)
      );
      const invoices = currentEnrollment?.invoices?.[invoiceIndex];

      return `PD: ${
        invoices?.dueDate
          ? formatDateTime(invoices?.dueDate, {
              format: "SHORT",
            })
          : ""
      }; `;
    }
    return "";
  }, [currentEnrollment?.invoices, texts]);

  return (
    <Row className={`flex-col ${className}`}>
      <Col>
        {texts?.map((i, index) => (
          <span
            key={`status_${index.toString()}`}
            style={{
              background: i?.bgColor,
              color: i.textColor,
            }}
            className="inline-block text-xs rounded-md py-1 px-2 font-bold ml-2"
          >
            {i.text}
          </span>
        ))}
      </Col>
      <Col className="pl-2 mt-1 text-3 text-primary-navyLight">
        {filterClassStatusText({
          texts,
          currentEnrollment,
          currentLesson,
          dueDate: UnpaidDueDate,
        })}
      </Col>
    </Row>
  );
};

export default memo(ClassStatusBtn);

import formatLabel from "@/utils/formatLabel";
import { Modal, Typography } from "@thepiquelab/archus-components-web";
import { FC, ReactElement, useRef, useState } from "react";
import InvoiceDetail from "./InvoiceDetail";
import { InvoiceActionType } from "./InvoiceDetailTypes";

interface InvoiceDetailModalProp {
  visible: boolean;
  invoiceId: string;
  onCancel: () => void;
  showBillTitle?: boolean;
  callback?: (type?: InvoiceActionType) => void;
}
const InvoiceDetailModal: FC<InvoiceDetailModalProp> = ({
  visible: detailVisible,
  invoiceId,
  onCancel,
  showBillTitle = false,
  callback,
}) => {
  const invoiceDetailRef = useRef<{ onModalClose?: () => void }>();

  return (
    <Modal
      visible={detailVisible}
      style={{
        width: 1275,
        minWidth: 1275,
      }}
      className="flex justify-center"
      bodyStyle={{ padding: "0", width: 1275 }}
      title={
        <Typography.Heading
          className="flex justify-between mr-6 items-center pt-1"
          level={3}
        >
          <span>{formatLabel("Invoice details")}</span>
        </Typography.Heading>
      }
      onCancel={() => {
        onCancel?.();
        invoiceDetailRef.current.onModalClose();
      }}
      footer={null}
    >
      <InvoiceDetail
        invoiceId={invoiceId}
        showBillTitle={showBillTitle}
        callback={callback}
        noSolidWidth
        ref={invoiceDetailRef}
      />
    </Modal>
  );
};

export const useInvoiceDetailModal = (
  modalProps: Omit<InvoiceDetailModalProp, "visible" | "onCancel"> = {
    invoiceId: undefined,
  }
): {
  invoiceDetailModal: ReactElement<
    Omit<InvoiceDetailModalProp, "visible" | "onCancel">
  >;
  setInvoiceDetailModalProps: (
    newProps: Omit<InvoiceDetailModalProp, "visible" | "onCancel">
  ) => void;
  close: () => void;
  show: () => void;
} => {
  const [visible, setVisible] = useState(false);

  const close = (): void => setVisible(false);
  const show = (): void => setVisible(true);

  const [props, setProps] = useState(modalProps);

  const setInvoiceDetailModalProps = (
    newProps: Omit<InvoiceDetailModalProp, "visible">
  ): void => {
    setProps({
      ...props,
      ...newProps,
      callback: (type) => {
        if (type === InvoiceActionType.REGENERATE) {
          close();
        }
        if (newProps?.callback) {
          newProps?.callback();
        } else {
          props?.callback();
        }
      },
    });
  };

  return {
    invoiceDetailModal: (
      <InvoiceDetailModal visible={visible} {...props} onCancel={close} />
    ),
    setInvoiceDetailModalProps,
    close,
    show,
  };
};

export default InvoiceDetailModal;

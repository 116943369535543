import { ApolloProvider } from "@apollo/client";
import {
  ConfigProviderToken,
  generateNotificationConfig,
  message,
  notification,
} from "@thepiquelab/archus-components-web";
import { useCheckVersion } from "@thepiquelab/archus-react-versioner";
import { useAuth0Client } from "@thepiquelab/web-auth0";
import { ErrorBoundary } from "@thepiquelab/web-sentry";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import createApolloClient from "./ApolloClient";
import { actions } from "./MicroAppStateActions";
import { EnumPermissions } from "./components/authorize/Permissions";
import CacheProvider from "./components/common/CacheProvider";
import { Auth0Config } from "./config";
import { useAccessControl } from "./hooks/useAccessControl";
import AdminIndexPage from "./pages/admin";
import ErrorsIndexPage from "./pages/errors";

const App: React.FC = () => {
  const { logout, getToken } = useAuth0Client();

  const { hasAllPermissions } = useAccessControl();

  const key = new Date().getTime().toString();

  const [versionKeys, setVersionKeys] = useState<Array<string>>([]);

  const { removeWorker, updateCacheStorage } = useCheckVersion({
    interval: 60000,
    update(version) {
      if (versionKeys.length > 0) {
        versionKeys.forEach((i) => notification?.destroy(i));
      }
      setVersionKeys([...versionKeys, key]);

      notification.open({
        key,
        ...generateNotificationConfig({
          title: "Update Available!",
          isDismissable: false,
          description:
            "You need to update this app. The version you are using does not include the latest security features.",
          type: "info",
          primaryButtonLabel: "Get Latest Update",
          onPressPrimaryButton: () => {
            notification.destroy(key);
            message.success("Update successful!");
            updateCacheStorage(version);
            window.location.reload();
          },
        }),
        duration: null,
      });
    },
  });

  useEffect(() => {
    return () => {
      removeWorker();
    };
  }, []);

  return (
    <ErrorBoundary>
      <ApolloProvider
        client={createApolloClient(
          async () => getToken(actions),
          () =>
            logout({
              logoutParams: {
                returnTo: Auth0Config.authorizationParams.redirect_uri,
              },
            })
        )}
      >
        <CacheProvider>
          <ConfigProviderToken>
            <BrowserRouter>
              <Routes>
                <Route
                  path={"*"}
                  element={
                    hasAllPermissions([EnumPermissions.ARCHUS_CRM_ACCESS]) ? (
                      <AdminIndexPage />
                    ) : (
                      <ErrorsIndexPage />
                    )
                  }
                />
              </Routes>
            </BrowserRouter>
          </ConfigProviderToken>
        </CacheProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;

import SelectCalendarDateSkeleton from "@/components/common/Skeleton/ClassArrangementSkeleton/Modal/SelectCalendarDateSkeleton";
import SelectCheckboxDateSkeleton from "@/components/common/Skeleton/ClassArrangementSkeleton/Modal/SelectCheckboxDateSkeleton";
import { useQuery } from "@apollo/client";
import moment from "@thepiquelab/web-moment";
import { RegistrationStage } from "@thepiquelab/web-types";
import React, { useMemo } from "react";
import {
  ClassEligibility,
  ClassType,
  GetSetDateModalClassById,
  GetSetDateModalClassByIdQuery,
  GetSetDateModalClassByIdQueryVariables,
  SelectedLessonInput,
} from "../../../graphql";
import RegistrationDate from "../RegisterModal/RegularModal/RegularModal";
import ReserveModal from "../RegisterModal/ReserveModal/ReserveModal";
import TrialModal from "../RegisterModal/TrialModal/TrialModal";
import WaitListModal from "../RegisterModal/WaitListModal/WaitListModal";
import WorkshopModal from "../RegisterModal/WorkshopModal/WorkshopModal";
import { getAvailableLessons } from "../RegistrationFlow/Checks/registrationChecksUtils";

interface Props {
  registrationStartDate: Date;
  registrationDueDate?: Date;
  selectedLessons?: SelectedLessonInput[];
  selectClassId: string;
  classType: ClassType;
  height?: string;
  eligibility?: ClassEligibility;
  onRegistrationDateChange?: (v: Date) => void;
  onAttendingLessonsChange?: (v: Array<SelectedLessonInput>) => void;
  onTextAreaChange?: (v: string) => void;
  onDueDateChange?: (v: Date) => void;
  stage: RegistrationStage;
  hideRemarks?: boolean;
}

const SetDateModal: React.FC<Props> = ({
  registrationStartDate,
  registrationDueDate,
  selectClassId,
  selectedLessons,
  eligibility,
  height,
  onRegistrationDateChange,
  onAttendingLessonsChange,
  onTextAreaChange,
  onDueDateChange,
  classType,
  stage,
  hideRemarks,
}) => {
  const { data: classData, loading } = useQuery<
    GetSetDateModalClassByIdQuery,
    GetSetDateModalClassByIdQueryVariables
  >(GetSetDateModalClassById, {
    variables: {
      classId: selectClassId,
      skipHoliday: true,
    },
  });

  const onAttendingLessons = (v: Array<SelectedLessonInput>) => {
    if (onAttendingLessonsChange) onAttendingLessonsChange(v);
  };

  const availableLessons = useMemo(
    () => getAvailableLessons(classData?.class?.lessons, eligibility) || [],
    [classData, eligibility]
  );

  if (loading || !classData) {
    if (classType === ClassType.Workshop || stage === RegistrationStage.Trial) {
      return <SelectCheckboxDateSkeleton />;
    }
    return <SelectCalendarDateSkeleton />;
  }

  if (classType === ClassType.Workshop) {
    return (
      <WorkshopModal
        stage={stage}
        lessonsData={classData?.class?.lessons || []}
        selectedLessons={selectedLessons}
        availableLessonIds={availableLessons?.map((l) => l.id)}
        onAttendingLessonsChange={onAttendingLessons}
        onDueDateChange={onDueDateChange}
        onRegistrationDateChange={onRegistrationDateChange}
        onTextAreaChange={onTextAreaChange}
        hideRemarks={hideRemarks}
      />
    );
  }

  switch (stage) {
    case RegistrationStage.Regular:
      return (
        <RegistrationDate
          lessonDates={availableLessons.map((l) => moment(l.lessonStart))}
          onRegistrationDateChange={onRegistrationDateChange}
        />
      );

    case RegistrationStage.Trial:
      return (
        <TrialModal
          term={classData?.class?.term}
          lessonsData={classData?.class?.lessons}
          selectedLessons={selectedLessons}
          availableLessonIds={availableLessons?.map((l) => l.id)}
          onAttendingLessonsChange={onAttendingLessons}
          onDueDateChange={onDueDateChange}
          onRegistrationDateChange={onRegistrationDateChange}
        />
      );

    case RegistrationStage.WaitingList:
      return (
        <WaitListModal
          availableDates={availableLessons?.map((l) => moment(l.lessonStart))}
          onSelect={onRegistrationDateChange}
          registrationStartDate={registrationStartDate}
          onTextAreaChange={onTextAreaChange}
          onDueDateChange={onDueDateChange}
          hideRemarks={hideRemarks}
        />
      );

    case RegistrationStage.Reservation:
      return (
        <ReserveModal
          registrationDueDate={registrationDueDate}
          availableDates={availableLessons.map((l) => moment(l.lessonStart))}
          onSelect={onRegistrationDateChange}
          registrationStartDate={registrationStartDate}
          onTextAreaChange={onTextAreaChange}
          onDueDateChange={onDueDateChange}
          hideRemarks={hideRemarks}
        />
      );
    default:
      return null;
  }
};

export default SetDateModal;

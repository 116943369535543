import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Space, Typography } from "@thepiquelab/archus-components-web";
import { formatDate, formatTimeRange } from "@thepiquelab/web-moment";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const ReminderTaskWorkshopRL: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: "Class to Skip",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;
        const fromClass = attendance?.linkArrangement?.fromClass;
        return (
          <ClassLink classId={fromClass?.id}>{fromClass?.shorthand}</ClassLink>
        );
      },
    },
    {
      title: "Lesson to Skip",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(
        value,
        record: TaskFieldsFragment & { isTaskDetailModal: boolean }
      ) {
        const { attendance } = record;
        const fromLesson = attendance?.linkArrangement?.fromLesson;
        return (
          <Space
            direction={record?.isTaskDetailModal ? "horizontal" : "vertical"}
          >
            <Typography.Heading level={4}>
              {formatDate(fromLesson?.lessonStart)}
            </Typography.Heading>
            <Typography.Text size="middle" className=" text-primary-navyLight">
              {`${formatTimeRange(
                fromLesson?.lessonStart,
                fromLesson?.lessonEnd,
                {
                  showDayOfWeek: true,
                }
              )}`}
            </Typography.Text>
          </Space>
        );
      },
    },
    {
      title: "Replacement Class",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;
        const toClass = attendance?.linkArrangement?.toClass;
        return (
          <ClassLink classId={toClass?.id}>{toClass?.shorthand}</ClassLink>
        );
      },
    },
    {
      title: "Replacement Lesson",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.M_MEDIUM],
      render(
        value,
        record: TaskFieldsFragment & { isTaskDetailModal: boolean }
      ) {
        const { attendance } = record;
        const toLesson = attendance?.linkArrangement?.toLesson;
        return (
          <Space
            direction={record?.isTaskDetailModal ? "horizontal" : "vertical"}
          >
            <Typography.Heading level={4}>
              {formatDate(toLesson?.lessonStart)}
            </Typography.Heading>
            <Typography.Text size="middle" className=" text-primary-navyLight">
              {`${formatTimeRange(toLesson?.lessonStart, toLesson?.lessonEnd, {
                showDayOfWeek: true,
              })}`}
            </Typography.Text>
          </Space>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Workshop Replacement Lesson Reminder")}
        taskType={TaskType.ReminderWorkshopReplacementLesson}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReminderTaskWorkshopRL;

import { SeatDisplayInfo } from "@/components/common/SeatView/types";
import {
  CapacitySeatType,
  EducationStage,
  EnrollmentStatus,
  SeatType,
} from "@/graphql";
import { EnrollmentCapacityView } from "@/utils/getEnrollmentCapacityView";
import Handlebars from "handlebars";
// import getEnrollmentCapacityView from "@/utils/getEnrollmentCapacityView";
import moment from "@thepiquelab/web-moment";
import {
  enrollmentHoldingCapacity,
  enrollmentRegularCapacity,
  enrollmentRequestCapacity,
  enrollmentReservedCapacity,
  enrollmentTransferInCapacity,
  enrollmentTransferOutCapacity,
  enrollmentUnpaidCapacity,
  enrollmentWaitingListCapacity,
  enrollmentWithdrawingCapacity,
  SeatColor,
  SeatsType,
} from "./classType";

export type ClassSeatDetailDTO = {
  enrollmentStartDate?: string;
  enrollmentEndDate?: string;
  student?: {
    id?: string;
    fullName?: string;
    gender?: string;
    avatarUrl?: string;
    userId?: string;
    alternateUserId?: string;
    primarySchool?: { id: string; name: string };
    secondarySchool?: {
      id: string;
      name: string;
    };
    academicLevel?: {
      id: string;
      educationStage: EducationStage;
    };
  };
  enrollmentStatus?: EnrollmentStatus;
  type?: CapacitySeatType;
  seatType?: SeatType;
  hasPaid?: boolean;
  isEnrollmentStarted?: boolean;
};

export type ClassSeatDTO = {
  start?: string;
  end?: string;
  seatDetails?: ClassSeatDetailDTO[];
};

export type ClassCapacityDTO = {
  regular?: number;
  replacement?: number;
  used?: number;
  waiting?: number;
  seats?: ClassSeatDTO[];
};

const getEnrollmentCapacityView = (
  seat: ClassSeatDetailDTO
): Omit<EnrollmentCapacityView, "isHidden"> => {
  const { type, enrollmentStatus, hasPaid, isEnrollmentStarted } = seat;

  if (type === CapacitySeatType.UserRequest) return enrollmentRequestCapacity;

  if (type === CapacitySeatType.Reserved) return enrollmentReservedCapacity;

  if (type === CapacitySeatType.WaitingList)
    return enrollmentWaitingListCapacity;

  // Reservation enrollment status is holding, but we think it's active.
  if (enrollmentStatus === EnrollmentStatus.Holding)
    return enrollmentHoldingCapacity;

  if (!hasPaid) return enrollmentUnpaidCapacity;

  if (type === CapacitySeatType.TransferIn) return enrollmentTransferInCapacity;

  if (type === CapacitySeatType.TransferOut)
    return enrollmentTransferOutCapacity;

  if (type === CapacitySeatType.Withdrawing)
    return enrollmentWithdrawingCapacity;

  const regular = (enrollmentRegularCapacity as any)[type]?.find(
    (e: any) => e.checkFirstLesson === isEnrollmentStarted
  );

  if (regular) return regular;

  return {
    status: enrollmentStatus,
    label: enrollmentStatus?.toLowerCase(),
    color: SeatColor.Holding,
    text: enrollmentStatus?.toLowerCase(),
  };
};

export default getEnrollmentCapacityView;

export const filterSeatType = (
  seat: ClassSeatDTO[],
  type: SeatType
): ClassSeatDTO[] => {
  if (!seat?.length) return [];
  return seat.filter((i) => i.seatDetails.every((s) => s.seatType === type));
};

export const calcRemaining = (a: number, b: number): number =>
  a > b ? a - b : 0;

export const handleCalcSeats = (seats: SeatsType): number => {
  const res = seats?.filter((i) => i?.every((v) => v?.type !== "Waiting List"));
  return res?.reduce((total, i) => total + i.length, 0);
};

export const handleCalcCardSeats = (
  seats: (string | [string, string])[]
): number => seats?.reduce((total, i) => total + i.length, 0);

// calc  enrollment  capacity
const generateSeatInfo = (seat: ClassSeatDetailDTO): SeatDisplayInfo => {
  const { label, color, template, text } = getEnrollmentCapacityView(seat);

  let type = label;

  let date = seat?.enrollmentStartDate;

  if (
    [CapacitySeatType.TransferOut, CapacitySeatType.Withdrawing].includes(
      seat.type
    ) &&
    seat.hasPaid &&
    seat.isEnrollmentStarted
  ) {
    date = seat?.enrollmentEndDate;
  }
  if (template) {
    const typeTemplate = Handlebars.compile(template);
    type = typeTemplate({
      date: moment(date).format("D/M"),
    });
  }
  return { title: seat.student?.fullName, type, color, text };
};

export const handleMapSeatInfo = (val: ClassSeatDTO[]): SeatsType => {
  if (!val.length) return [];

  return val.map((s) =>
    s.seatDetails.map((info) => generateSeatInfo(info))
  ) as SeatsType;
};

export const generateSeatData = ({
  capacity,
  studentId,
}: {
  capacity: ClassCapacityDTO;
  studentId: string;
}): {
  regularSeats: SeatsType;
  makeupSeats: SeatsType;
  bypassSeats: SeatsType;
  regularSeatsEmpty: SeatsType;
  makeUpSeatsEmpty: SeatsType;
} => {
  const { seats } = capacity || {};
  const validSeats = seats?.filter((s) =>
    s.seatDetails.some((info) => !studentId || info.student?.id !== studentId)
  );

  const regularSeats = handleMapSeatInfo(
    filterSeatType(validSeats, SeatType.Regular)
  );
  const makeupSeats = handleMapSeatInfo(
    filterSeatType(validSeats, SeatType.Makeup)
  );
  const bypassSeats = handleMapSeatInfo(
    filterSeatType(validSeats, SeatType.Bypass)
  );

  const regularEmpty = calcRemaining(capacity.regular, regularSeats?.length);
  const makeUpEmpty = calcRemaining(capacity.replacement, makeupSeats?.length);

  const regularSeatsEmpty = [...Array(regularEmpty)].map(() => [
    { title: null, color: SeatColor.Empty },
  ]) as SeatsType;

  const makeUpSeatsEmpty = [...Array(makeUpEmpty)].map(() => [
    { title: null, color: SeatColor.Empty },
  ]) as SeatsType;

  return {
    regularSeats,
    makeupSeats,
    bypassSeats,
    regularSeatsEmpty,
    makeUpSeatsEmpty,
  };
};

// class capacity card
const generateCardSeatInfo = (seat: ClassSeatDetailDTO): string => {
  const { color } = getEnrollmentCapacityView(seat);
  return color;
};

export const handleMapCardSeatInfo = (
  val: ClassSeatDTO[]
): [string, string][] => {
  if (!val.length) return [];
  return val.map(
    (s) =>
      s.seatDetails.map((info) => generateCardSeatInfo(info)) as [
        string,
        string,
      ]
  );
};

export const generateCardSeatData = ({
  capacity,
}: {
  capacity: ClassCapacityDTO;
}): {
  regularSeats: [string, string][];
  makeupSeats: [string, string][];
  bypassSeats: [string, string][];
  regularSeatsEmpty: string[];
  makeUpSeatsEmpty: string[];
} => {
  const { seats } = capacity || {};

  const regularSeats = handleMapCardSeatInfo(
    filterSeatType(seats, SeatType.Regular)
  );
  const makeupSeats = handleMapCardSeatInfo(
    filterSeatType(seats, SeatType.Makeup)
  );
  const bypassSeats = handleMapCardSeatInfo(
    filterSeatType(seats, SeatType.Bypass)
  );

  const regularEmpty = calcRemaining(capacity.regular, regularSeats.length);
  const makeUpEmpty = calcRemaining(capacity.replacement, makeupSeats.length);

  const regularSeatsEmpty = [...Array(regularEmpty)].map(
    () => SeatColor.Empty
  ) as string[];

  const makeUpSeatsEmpty = [...Array(makeUpEmpty)].map(
    () => SeatColor.Empty
  ) as string[];

  return {
    regularSeats,
    makeupSeats,
    bypassSeats,
    regularSeatsEmpty,
    makeUpSeatsEmpty,
  };
};

export const handleExcludeHoldingSeat = ({
  currentCapacity,
  excludeHolding,
  excludeReservation = false,
  studentId = "",
}: {
  currentCapacity: ClassCapacityDTO;
  excludeHolding: boolean;
  excludeReservation?: boolean;
  studentId?: string;
}): ClassCapacityDTO => {
  let capacity = currentCapacity;
  if (excludeReservation) {
    capacity = {
      ...capacity,
      seats: capacity?.seats?.filter((i) =>
        i?.seatDetails?.some(
          (v: any) => !(v?.student?.id === studentId && v?.isReserved)
        )
      ),
    };
  }
  if (excludeHolding)
    capacity = {
      ...capacity,
      seats: capacity?.seats?.filter((i) =>
        i?.seatDetails?.some(
          (v: any) =>
            !(
              v?.student?.id === studentId &&
              v?.status === EnrollmentStatus.Holding
            )
        )
      ),
    };
  return capacity;
};

export const calcCapacityTotal = (data: {
  regular?: number;
  replacement?: number;
}): number => {
  const { regular = 0, replacement = 0 } = data || {};
  return Number(regular + replacement);
};
export const calcCapacitySeatsLeft = (data: {
  regular?: number;
  used?: number;
}): number => {
  const { regular = 0, used = 0 } = data || {};
  const remainingSeats = regular - used;
  return remainingSeats > 0 ? remainingSeats : 0;
};

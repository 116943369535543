import { ReactComponent as IconUpload } from "@/assets/images/IconUpload.svg";
import { DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import { UploadFile, UploadProps } from "@thepiquelab/archus-components-web";
import { Upload } from "antd";
import React, { useState } from "react";

const { Dragger } = Upload;

interface UploadFileStePropProp {
  fileList: UploadFile[];
  setFileList: (fileList: UploadFile[]) => void;
  onValidation?: (bool: boolean) => void;
}

const blankFileSize = 103;

const UploadFileStep: React.FC<UploadFileStePropProp> = ({
  fileList,
  setFileList,
  onValidation,
}) => {
  const [showValidation, setShowValidation] = useState(false);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    beforeUpload: (file) => {
      if (file?.size <= blankFileSize) {
        setShowValidation(true);
        onValidation?.(true);
      }
      setFileList([file]);

      return false;
    },
    itemRender: () => <></>,
    fileList,
  };

  return (
    <div>
      <div key="template" className="font-semibold">
        <a
          href={`${process.env.PUBLIC_URL}/import-registration-template.csv`}
          target="_self"
          rel="noreferrer"
        >
          Download a CSV Template
        </a>
      </div>
      <div className="mt-6" key="dragger">
        <Dragger {...props} className="h-64">
          <p
            key="icon"
            className="ant-upload-drag-icon flex text-center justify-items-center justify-center"
          >
            <IconUpload className="h-12 w-12" fill="#5B9DFF" />
          </p>
          <p key="text" className="ant-upload-text font-semibold">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </div>
      {fileList?.length ? (
        <div className="mt-6 " key="file-list">
          {fileList.map((file) => (
            <div
              key={file.uid}
              className="flex justify-between items-center justify-items-center h-14 border border-gray-400  px-4 rounded-md"
            >
              <div className="flex items-center justify-items-center gap-2">
                <PaperClipOutlined />
                <div>{file.name}</div>
              </div>
              <DeleteOutlined
                className="cursor-pointer"
                onClick={() => {
                  setFileList(
                    fileList.filter(
                      (currentFile) => file.uid !== currentFile.uid
                    )
                  );
                  setShowValidation(false);
                  onValidation(false);
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {showValidation ? (
        <div className="mt-6 text-primary-redDark">
          Can not upload empty CSV file.
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default UploadFileStep;

import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import moment, { FormatType, formatTimeRange } from "@thepiquelab/web-moment";
import { omit } from "lodash";
import Papa from "papaparse";

export enum ImportClassesStep {
  "GuideLine" = 0,
  "UploadFile" = 1,
  "DataPreview" = 2,
}

export enum EnumImportClassTempTitle {
  "CourseName" = "Course Name",
  "CourseType" = "Course Type",
  "AdhocClass" = "Adhoc Class?",
  "ReplacementLessonClass" = "Replacement Lesson Class?",
  "ClassYear" = "Class Year",
  "ClassName" = "Class Name",
  "ClassCode" = "Class Code",
  "ClassShorthand" = "Class Shorthand",
  "RunNumber" = "Run Number",
  "LessonDay" = "Lesson Day",
  "Duration" = "Duration",
  "FirstLessonDate" = "First Lesson Date",
  "LastLessonDate" = "Last Lesson Date",
  "NoOfLessons" = "No. of Lessons",
  "ScheduleHoliday" = "Skip Schedule Holiday?",
  "ClassTeacher" = "Class Teacher",
  "LearningArrangement" = "Learning Arrangement",
  "Classroom" = "Classroom",
  "ClassCapacity" = "Class Capacity",
  "ReplacementLessonCapacity" = "Replacement Lesson Capacity",
  "ZoomMeetingID" = "Zoom Meeting ID",
  "ZoomMeetingPasscode" = "Zoom Meeting Passcode",
  "ZoomMeetingLink" = "Zoom Meeting Link",
  "GoogleMeetID" = "Google Meet ID",
  "GoogleMeetLink" = "Google Meet Link",
  "AutoGenerateZoomMeetingCredentials" = "Auto Generate Zoom Meeting Credentials",
  "AutoGenerateGoogleMeetCredentials" = "Auto Generate Google Meet Credentials",
}

export const csvColumnTitles = [
  EnumImportClassTempTitle.CourseName,
  EnumImportClassTempTitle.CourseType,
  EnumImportClassTempTitle.ClassYear,
  EnumImportClassTempTitle.ClassName,
  EnumImportClassTempTitle.ClassCode,
  EnumImportClassTempTitle.ClassShorthand,
  EnumImportClassTempTitle.RunNumber,
  EnumImportClassTempTitle.LessonDay,
  EnumImportClassTempTitle.Duration,
  EnumImportClassTempTitle.FirstLessonDate,
  EnumImportClassTempTitle.LastLessonDate,
  EnumImportClassTempTitle.NoOfLessons,
  EnumImportClassTempTitle.ScheduleHoliday,
  EnumImportClassTempTitle.ClassTeacher,
  EnumImportClassTempTitle.LearningArrangement,
  EnumImportClassTempTitle.Classroom,
  EnumImportClassTempTitle.ClassCapacity,
  EnumImportClassTempTitle.ReplacementLessonCapacity,
  EnumImportClassTempTitle.ZoomMeetingID,
  EnumImportClassTempTitle.ZoomMeetingPasscode,
  EnumImportClassTempTitle.ZoomMeetingLink,
  EnumImportClassTempTitle.GoogleMeetID,
  EnumImportClassTempTitle.GoogleMeetLink,
  EnumImportClassTempTitle.AutoGenerateZoomMeetingCredentials,
  EnumImportClassTempTitle.AutoGenerateGoogleMeetCredentials,
];

export const DefaultImportClassPreviewColumns = [
  {
    title: EnumImportClassTempTitle.CourseName,
    dataIndex: EnumImportClassTempTitle.CourseName,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.CourseType,
    dataIndex: EnumImportClassTempTitle.CourseType,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.AdhocClass,
    dataIndex: EnumImportClassTempTitle.AdhocClass,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ReplacementLessonClass,
    dataIndex: EnumImportClassTempTitle.ReplacementLessonClass,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ClassYear,
    dataIndex: EnumImportClassTempTitle.ClassYear,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ClassName,
    dataIndex: EnumImportClassTempTitle.ClassName,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.ClassCode,
    dataIndex: EnumImportClassTempTitle.ClassCode,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ClassShorthand,
    dataIndex: EnumImportClassTempTitle.ClassShorthand,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.RunNumber,
    dataIndex: EnumImportClassTempTitle.RunNumber,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.LessonDay,
    dataIndex: EnumImportClassTempTitle.LessonDay,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.Duration,
    dataIndex: EnumImportClassTempTitle.Duration,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.FirstLessonDate,
    dataIndex: EnumImportClassTempTitle.FirstLessonDate,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.LastLessonDate,
    dataIndex: EnumImportClassTempTitle.LastLessonDate,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.NoOfLessons,
    dataIndex: EnumImportClassTempTitle.NoOfLessons,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.ScheduleHoliday,
    dataIndex: EnumImportClassTempTitle.ScheduleHoliday,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ClassTeacher,
    dataIndex: EnumImportClassTempTitle.ClassTeacher,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.LearningArrangement,
    dataIndex: EnumImportClassTempTitle.LearningArrangement,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.Classroom,
    dataIndex: EnumImportClassTempTitle.Classroom,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ClassCapacity,
    dataIndex: EnumImportClassTempTitle.ClassCapacity,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ReplacementLessonCapacity,
    dataIndex: EnumImportClassTempTitle.ReplacementLessonCapacity,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.ZoomMeetingID,
    dataIndex: EnumImportClassTempTitle.ZoomMeetingID,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.ZoomMeetingPasscode,
    dataIndex: EnumImportClassTempTitle.ZoomMeetingPasscode,
    type: EnumEnhancedTableWidthType.MiddleLarge,
  },
  {
    title: EnumImportClassTempTitle.ZoomMeetingLink,
    dataIndex: EnumImportClassTempTitle.ZoomMeetingLink,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.GoogleMeetID,
    dataIndex: EnumImportClassTempTitle.GoogleMeetID,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.GoogleMeetLink,
    dataIndex: EnumImportClassTempTitle.GoogleMeetLink,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.AutoGenerateZoomMeetingCredentials,
    dataIndex: EnumImportClassTempTitle.AutoGenerateZoomMeetingCredentials,
    type: EnumEnhancedTableWidthType.Middle,
  },
  {
    title: EnumImportClassTempTitle.AutoGenerateGoogleMeetCredentials,
    dataIndex: EnumImportClassTempTitle.AutoGenerateGoogleMeetCredentials,
    type: EnumEnhancedTableWidthType.Middle,
  },
].map((column) => ({
  ...omit(column, ["type"]),
  width:
    enumEnhancedTableWidthType[column.type] ||
    enumEnhancedTableWidthType.middle,
}));

const DefaultImportClassPreviewColumnsDataIndexes: string[] =
  DefaultImportClassPreviewColumns.map(
    (column: { dataIndex: string }) => column.dataIndex
  );
export type DefaultImportClassPreviewColumnsDataIndexType =
  (typeof DefaultImportClassPreviewColumnsDataIndexes)[number];

export interface ClassRecord {
  [key: string]: string;
}

export interface NamesMapToIdType {
  [key: string]: string;
}

export interface ImportClassYear {
  id: string;
  year: number;
  academicWeeks: {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  }[];
  terms?: {
    id: string;
    academicLevels?: {
      id: string;
    }[];
    term4Period: {
      end: Date;
    };
  }[];
}

export interface IVenueNameToVenue {
  [key: string]: {
    id: string;
    shorthand: string;
    unitNo?: string;
    branch: {
      name: string;
      alias: string;
    };
  };
}

export const IMPORT_CLASSES_DATE_FORMAT: FormatType[] = [
  "D MMM YYYY (ddd)",
  "D MMM YYYY",
  "D/M/YYYY",
  "D/MM/YYYY",
];
export const IMPORT_CLASSES_TIME_FORMAT = "HH:mm";

export const IMPORT_CLASSES_SPLIT_REGEXP = /\n+/i;
export const IMPORT_CLASSES_WEEKDAY_SPLIT_REGEXP = /\n+|,+/i;
export const IMPORT_CLASSES_SPLIT = "\n";

export const downloadCSV = (csvContent: string, fileName: string): void => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = window.document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
};

export const DurationTimes = [
  "8AM",
  "8.30AM",
  "9AM",
  "9.30AM",
  "10AM",
  "10.30AM",
  "11AM",
  "11.30AM",
  "12PM",
  "12.30PM",
  "1PM",
  "1.30PM",
  "2PM",
  "2.30PM",
  "3PM",
  "3.30PM",
  "4PM",
  "4.30PM",
  "5PM",
  "5.30PM",
  "6PM",
  "6.30PM",
  "7PM",
  "7.30PM",
  "8PM",
  "8.30PM",
  "9PM",
  "9.30PM",
];

export type DurationTimesType = (typeof DurationTimes)[number];

type DurationTimesMapType = {
  [key in DurationTimesType]: string;
};
export const DurationTimesMap: DurationTimesMapType = {
  "8AM": "08:00",
  "8.30AM": "08:30",
  "9AM": "09:00",
  "9.30AM": "09:30",
  "10AM": "10:00",
  "10.30AM": "10:30",
  "11AM": "11:00",
  "11.30AM": "11:30",
  "12PM": "12:00",
  "12.30PM": "12:30",
  "1PM": "13:00",
  "1.30PM": "13:30",
  "2PM": "14:00",
  "2.30PM": "14:30",
  "3PM": "15:00",
  "3.30PM": "15:30",
  "4PM": "16:00",
  "4.30PM": "16:30",
  "5PM": "17:00",
  "5.30PM": "17:30",
  "6PM": "18:00",
  "6.30PM": "18:30",
  "7PM": "19:00",
  "7.30PM": "19:30",
  "8PM": "20:00",
  "8.30PM": "20:30",
  "9PM": "21:00",
  "9.30PM": "21:30",
};

// // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// export const getImportClassesInput = (
//   wrapSelectedClasses: { index: string }[],
//   courseNamesMap: NamesMapToIdType,
//   coursesData: any,
//   academicYearNamesMap: NamesMapToIdType,
//   venueNamesMap: NamesMapToIdType,
//   teacherNamesMap: NamesMapToIdType,
//   teacherIdToInitialMap: NamesMapToIdType,
//   teachersData: GetTeachersForImportClassesQuery
// ) => ();

/**
 * @description timeString -> utcTimeString  : 9:00 -> 01:00:00Z
 * @param timeString
 * @param onlyTimeFormat
 *  false - 11:00:00.000Z
 *  true - 2023-08-31T11:00:00.000Z
 * @returns
 */
export const getUtcTimeString = (
  timeString: string,
  onlyTimeFormat?: boolean
): string => {
  const fullString = moment(
    moment()
      .set({
        hour: Number(timeString.split(":")[0]),
        minute: Number(timeString.split(":")?.[1] || 0),
        second: 0,
        millisecond: 0,
      })
      .utc()
  ).format();
  if (onlyTimeFormat) {
    return fullString.split("T")[1];
  }
  return fullString;
};

export const getJsonFromCsvFile = (
  file: File,
  transform?: (cellContent: string, headerName: string) => string
): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;
      const finalTransform =
        transform ||
        ((cellContent: string, headerName: string): string => {
          let cellString = cellContent?.trim();
          if (headerName === EnumImportClassTempTitle.ClassTeacher) {
            cellString = cellString || "To Be Assigned";
          }
          /**
           * remove the preceding and following newlines and Spaces
           */
          return cellString;
        });

      const jsonData = Papa.parse(csvData as string, {
        header: true,
        skipEmptyLines: true,
        transform: finalTransform,
      });
      resolve(jsonData);
    };

    reader.onerror = () => {
      reject();
    };

    reader.readAsText(file);
  });

export const validateDurations = (value: string): boolean => {
  const trimValue = value?.trim();
  const durations = trimValue.split(IMPORT_CLASSES_SPLIT_REGEXP);
  const isValidTime = (timeString: string): boolean => {
    const time = moment(timeString, ["h.mmA", "h:mmA", "hA", "H:mm"]);

    const minTime = moment().hour(8).startOf("hour");
    const maxTime = moment().hour(21).minute(30).startOf("minute");
    return (
      time.isValid() &&
      time.isBetween(minTime, maxTime, "minute", "[]") &&
      [0, 30].includes(time.minute())
    );
  };

  for (let i = 0; i < durations.length; i += 1) {
    const [start, end] = durations[i].split(/to|-/gi);
    if (!isValidTime(start?.trim())) {
      return false;
    }

    if (!isValidTime(end?.trim())) {
      return false;
    }
  }
  return true;
};

export const formatDurations = (value: string): string => {
  if (!validateDurations(value)) {
    return value;
  }
  const durations = value
    .split(IMPORT_CLASSES_SPLIT_REGEXP)
    .map((duration) => {
      const [start, end] = duration.split(/to|-/gi);
      const startMoment = moment(start?.trim(), [
        "h.mmA",
        "h:mmA",
        "hA",
        "H:mm",
      ]);
      const endMoment = moment(end?.trim(), ["h.mmA", "h:mmA", "hA", "H:mm"]);
      return formatTimeRange(startMoment.toDate(), endMoment.toDate());
    })
    .join("\n");
  return durations;
};

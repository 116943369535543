import {
  EnrollmentType,
  GetStudentsTrialEnrollment,
  GetStudentsTrialEnrollmentQuery,
  GetStudentsTrialEnrollmentQueryVariables,
} from "@/graphql";
import { useQuery } from "@apollo/client";
import { Modal } from "@thepiquelab/archus-components-web";
import moment, { formatDate } from "@thepiquelab/web-moment";
import { uniq } from "lodash";
import "./index.scss";

export const useCheckIfHasTrialInCourse = (): {
  checkHasTrial: (studentId: string, courseId: string) => Promise<void>;
} => {
  const { data, loading, refetch } = useQuery<
    GetStudentsTrialEnrollmentQuery,
    GetStudentsTrialEnrollmentQueryVariables
  >(GetStudentsTrialEnrollment, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const checkHasTrial = async (
    studentId: string,
    courseId: string
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      const init = async () => {
        const trialData = await refetch({
          studentId,
          StudentEnrollmentFilterInput: {
            courseIds: [courseId],
            enrollmentTypes: [EnrollmentType.Trial],
            dateTimeRange: {
              start: moment().startOf("year").toDate(),
              end: moment().endOf("year").toDate(),
            },
          },
        });
        const trialEnrollments = trialData?.data?.student?.enrollments;

        let classShorthandsData = null;
        const hasTrial = trialEnrollments?.length > 0;
        if (!hasTrial) {
          resolve();
        } else {
          classShorthandsData = trialEnrollments.map(
            (enrollment: any) => enrollment.class.shorthand
          );
          const groupedTrialEnrollments: any = uniq(classShorthandsData)?.map(
            (classShorthand) =>
              trialEnrollments.filter(
                (enrollment: any) =>
                  enrollment.class.shorthand === classShorthand
              )
          );
          const confirmModal = Modal.confirm({
            title: (
              <div className="check-trial-in-course-modal-header c-text-sm p-6 border rounded-lg font-semibold">
                Confirmation
              </div>
            ),
            width: 520,
            icon: <></>,
            // closeIcon: <IconClose />,
            closable: true,
            wrapClassName: "check-trial-in-course-modal",
            content: (
              <div className="flex flex-col gap-6 px-6 pt-6">
                Student has trial on{" "}
                {groupedTrialEnrollments
                  ?.map(
                    (group: any) =>
                      `${group
                        .map((enrollment: any) =>
                          formatDate(enrollment?.startDate, { format: "SHORT" })
                        )
                        .join(", ")} in ${group?.[0]?.class?.shorthand}`
                  )
                  .join(", ")}
                {", are you sure you want to proceed?"}
              </div>
            ),
            onCancel: () => {
              setTimeout(() => {
                reject();
              }, 200);
              confirmModal.destroy();
            },
            onOk: () => {
              setTimeout(() => {
                resolve();
              }, 200);
              confirmModal.destroy();
            },
          });
        }
      };
      init();
    });

  return {
    checkHasTrial,
  };
};

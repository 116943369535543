import { ErrorResponse } from "@apollo/client/link/error";
import {
  generateNotificationConfig,
  message,
  notification,
} from "@thepiquelab/archus-components-web";
import { GraphQLError } from "graphql";

enum GraphQLErrorExtensionType {
  "Business Errors" = "Business Errors",
}

export enum GraphQLErrorExtensionCode {
  "BUSINESS_PROCESS_ERROR" = "BUSINESS_PROCESS_ERROR",
  "RESOURCE_NOT_FOUND_ERROR" = "RESOURCE_NOT_FOUND_ERROR",
}
enum GraphQLErrorExtensionAlertType {
  "Notification" = "Notification",
  "Toast" = "Toast",
}

const getErrorTitle = (operationName: string): string => {
  if (operationName?.includes("sendReminder")) {
    return "Sent Message Failed";
  }
  return "Error";
};
export const showGlobalNotificationError = (
  errors: GraphQLError[],
  originalError: ErrorResponse
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const operationName = originalError?.operation?.operationName;
  const errorTitle = getErrorTitle(operationName);
  errors?.forEach((error) => {
    const code = error?.extensions?.code;
    const alertType = error?.extensions?.alertType;
    const errorMessage = error?.extensions?.message;

    const isBusinessError =
      code === GraphQLErrorExtensionCode.BUSINESS_PROCESS_ERROR;
    const isNotificationAlertType =
      alertType === GraphQLErrorExtensionAlertType.Notification;
    const isToastAlertType = alertType === GraphQLErrorExtensionAlertType.Toast;

    if (isBusinessError && isNotificationAlertType && errorMessage) {
      notification.open({
        ...generateNotificationConfig({
          title: errorTitle,
          description: errorMessage as string,
          type: "error",
        }),
        duration: null,
      });
    }
    if (isBusinessError && isToastAlertType && errorMessage) {
      message.error(errorMessage as string);
    }
  });
};

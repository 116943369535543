import { ReactComponent as IconCompleteTask } from "@/assets/images/IconCheck.svg";
import { ReactComponent as IconClose } from "@/assets/images/IconClose.svg";
import { ReactComponent as IconReopen } from "@/assets/images/IconRefresh.svg";
import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import TaskDetailSkeleton from "@/components/common/Skeleton/TaskDetailSkeleton";
import CommonBadge from "@/components/widget/Badge/CommonBadge";
import ParentLink from "@/components/widget/CommonLink/ParentLink";
import StudentLink from "@/components/widget/CommonLink/StudentLink";
import { TaskStatus, TaskType } from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { CaretDownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  ArchusModal as Modal,
  Row,
  Space,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { formatPhoneNumber } from "@thepiquelab/phonenumber";
import { formatDateTime } from "@thepiquelab/web-moment";
import React, { ReactNode, useEffect, useRef } from "react";
import FollowUpStatusSelect, {
  FollowUpType,
} from "../../Modules/FollowUpStatusSelect";
import Activity from "../Activity/Activity";
import TaskRemarks from "../Remarks/TaskRemarks";
import TaskDueDatePicker from "../TaskDueDatePicker";
import { CustomCommonColumn, CustomCommonColumnKey } from "../TaskTable";
import {
  useCompleteTasks,
  useQueryTask,
  useReopenTask,
  useVoidTask,
} from "../hooks";
import { TaskStatusTagMap } from "../utils";

interface TaskDetailModalProps {
  taskId?: string;
  columns: AdvanceTableColumnType[];
  callback?: () => Promise<void> | void;
  onClose: () => void;
  taskType?: TaskType;
}

const TaskDetailModal: React.FC<TaskDetailModalProps> = (props) => {
  const { taskId, columns, taskType, callback, onClose } = props;
  const { modal, show, close, setModalProps } = useConfirmModal();
  const [completeTasks, { loading }] = useCompleteTasks();
  const [voidTask, { loading: voidLoading }] = useVoidTask();

  const [reopenTask, { loading: reopenLoading }] = useReopenTask();

  // Record whether table data is refreshed.
  const ref = useRef(0);

  const [queryTask, { data: taskData, loading: refetchTaskLoading, refetch }] =
    useQueryTask();

  useEffect(() => {
    if (taskId) {
      queryTask({ variables: { id: taskId } });
    }
  }, [taskId]);

  const getParentAndStudent = () => {
    let student;
    let parent;
    switch (taskData?.task?.type) {
      case TaskType.FollowUpReplacementLessonOffer:
      case TaskType.ReminderChangeClass:
      case TaskType.ReminderLessonUnderHoliday:
      case TaskType.ReminderWorkshopReplacementLesson:
      case TaskType.FollowUpAbsent: {
        const { attendance } = taskData?.task;
        student = attendance?.student;
        parent = student?.primaryParent;
        break;
      }
      case TaskType.FollowUpPayment: {
        const { invoice } = taskData?.task;
        const { billedFor } = invoice || {};
        student = billedFor;
        parent = student?.primaryParent;
        break;
      }
      case TaskType.FollowUpReservation: {
        const { registration } = taskData?.task;
        student = registration?.student;
        parent = student?.primaryParent;
        break;
      }
      case TaskType.FollowUpFeeBalance: {
        const { balance } = taskData?.task;
        parent = balance?.parent;
        break;
      }
      case TaskType.FollowUpTrial:
      case TaskType.ReminderLesson:
      case TaskType.ReminderAdditionalLessonOffer:
      case TaskType.ReminderTrialLesson:
      case TaskType.ReminderAdditionalLesson: {
        const { enrollment } = taskData?.task;
        student = enrollment?.student;
        parent = student?.primaryParent;
        break;
      }
      default:
        break;
    }
    return {
      student,
      parent,
    };
  };

  const renderItem = (label: string, value: string | ReactNode): ReactNode => {
    const isStudentNameLabel =
      label === CustomCommonColumn[CustomCommonColumnKey.StudentName]?.title;
    const isStudentIDLabel =
      label === CustomCommonColumn[CustomCommonColumnKey.StudentID]?.title;
    const isPrimaryParentNameLabel =
      label ===
      CustomCommonColumn[CustomCommonColumnKey.PrimaryParentName]?.title;
    const isPrimaryParentMobileLabel =
      label ===
      CustomCommonColumn[CustomCommonColumnKey.PrimaryParentMobile]?.title;
    const isPrimaryParentWALineLabel =
      label ===
      CustomCommonColumn[CustomCommonColumnKey.PrimaryParentWALine]?.title;

    const { student, parent } = getParentAndStudent();

    if (isStudentNameLabel) {
      value = (
        <StudentLink id={student?.id}>{student?.fullName || "-"}</StudentLink>
      );
    }
    if (isStudentIDLabel) {
      value = <>{student?.userId || "-"}</>;
    }

    if (isPrimaryParentNameLabel) {
      value = (
        <ParentLink id={parent?.id}>{parent?.fullName || "-"}</ParentLink>
      );
    }
    if (isPrimaryParentMobileLabel) {
      value = <>{`${formatPhoneNumber(parent?.mobile)}`}</>;
    }

    if (isPrimaryParentWALineLabel) {
      const { linkedWhatsappAccount } = parent || {};
      value = linkedWhatsappAccount ? (
        <span className="font_regular">
          {`[${linkedWhatsappAccount?.number?.number?.slice(0, 4)}]`}
        </span>
      ) : (
        <></>
      );
    }

    return (
      <Row key={`${taskData?.task?.id}-${label}`} align="middle">
        <Col span={9}>
          <Typography.Heading level={4}>{label}:</Typography.Heading>
        </Col>
        <Col span={15}>{value}</Col>
      </Row>
    );
  };

  const handleVoidTask = async (id: string): Promise<void> => {
    try {
      await voidTask({ variables: { id } });
      ref.current += 1;
      refetch();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleCompleteTasks = async (ids: string[]): Promise<void> => {
    try {
      await completeTasks({
        variables: {
          command: {
            type: taskType,
            ids,
          },
        },
      });
      ref.current += 1;
      refetch();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const handleMarkAsComplete = (id: string): void => {
    setModalProps({
      onClose: () => {
        close();
      },
      onConfirm: async () => {
        try {
          setModalProps({ confirmLoading: true });
          await handleCompleteTasks([id]);
          setModalProps({ confirmLoading: false });
          close();
        } catch (error) {
          message.error(formatErrorMessage(error));
          setModalProps({ confirmLoading: false });
        }
      },
      confirmLoading: loading,
    });
    show();
  };

  const handleConfirmVoidTask = (id: string): void => {
    setModalProps({
      onClose: () => {
        close();
      },
      onConfirm: async () => {
        try {
          setModalProps({ confirmLoading: true });
          await handleVoidTask(id);
          setModalProps({ confirmLoading: false });
          close();
        } catch (error) {
          message.error(formatErrorMessage(error));
          setModalProps({ confirmLoading: false });
        }
      },
      confirmLoading: loading,
    });
    show();
  };

  const handleReopenTask = async (): Promise<void> => {
    try {
      setModalProps({ confirmLoading: true });
      await reopenTask({ variables: { id: taskId } });
      ref.current += 1;
      refetch();
      setModalProps({ confirmLoading: false });
      close();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  return (
    <>
      <Modal
        visible={!!taskId}
        onClose={() => {
          onClose();
          if (ref?.current > 0) {
            ref.current = 0;
            callback();
          }
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        destroyOnClose
      >
        <Modal.Header title={"Task Details"} />
        <Modal.Content className="pb-0">
          {refetchTaskLoading ? (
            <TaskDetailSkeleton />
          ) : (
            <Space direction="vertical" size={24} style={{ display: "flex" }}>
              {taskType !== TaskType.FollowUpFeeBalance && (
                <div className="flex justify-end">
                  {taskData?.task?.status === TaskStatus.Complete ? (
                    <Button
                      loading={reopenLoading}
                      icon={<IconReopen className="w-5 h-4 mr-1" />}
                      onClick={() => {
                        setModalProps({
                          onConfirm: handleReopenTask,
                          onClose: close,
                          confirmLoading: reopenLoading,
                        });
                        show();
                      }}
                    >
                      Reopen Task
                    </Button>
                  ) : (
                    <Dropdown.Button
                      icon={<CaretDownOutlined />}
                      className="flex flex-row items-center"
                      overlay={
                        <Menu>
                          <Menu.Item
                            onClick={() => {
                              handleConfirmVoidTask(taskData?.task?.id);
                            }}
                          >
                            <div className="flex flex-row items-center">
                              <IconClose className="w-4 h-3 mr-2" />
                              Void Task
                            </div>
                          </Menu.Item>
                        </Menu>
                      }
                      onClick={() => {
                        handleMarkAsComplete(taskData?.task?.id);
                      }}
                      disabled={
                        ![TaskStatus.Overdue, TaskStatus.Pending].includes(
                          taskData?.task?.status
                        )
                      }
                      loading={loading || voidLoading}
                    >
                      <IconCompleteTask className="w-5 h-4" />
                      <span className="ml-2">Mark As Complete</span>
                    </Dropdown.Button>
                  )}
                </div>
              )}

              {renderItem(
                "Task Created At",
                formatDateTime(taskData?.task?.createDateTime, {
                  format: "SHORT",
                })
              )}
              {renderItem(
                "Task Due At",
                <div className="w-56">
                  <TaskDueDatePicker
                    task={taskData?.task}
                    callback={() => {
                      ref.current += 1;
                      refetch();
                    }}
                  />
                </div>
              )}
              {renderItem(
                "Task Status",
                <CommonBadge
                  textColor={
                    TaskStatusTagMap[taskData?.task?.status]?.textColor
                  }
                  bgColor={TaskStatusTagMap[taskData?.task?.status]?.bgColor}
                  label={taskData?.task?.status}
                />
              )}
              {columns?.map((i) => {
                if (i?.title === formatLabel("Trial Follow Up Status")) {
                  return renderItem(
                    i?.title?.toString(),
                    <FollowUpStatusSelect
                      enrollment={{
                        id: taskData?.task?.enrollment?.id,
                        followUpStatus:
                          taskData?.task?.enrollment?.followUpStatus,
                        followUpStatusLastModifiedBy: {
                          fullName:
                            taskData?.task?.enrollment
                              ?.followUpStatusLastModifiedBy?.fullName,
                        },
                        followUpStatusLastModifiedDateTime:
                          taskData?.task?.enrollment
                            ?.followUpStatusLastModifiedDateTime,
                      }}
                      disabled={taskData?.task?.status === TaskStatus.Void}
                      followUpType={FollowUpType.Trial}
                      className="w-full"
                      callback={() => {
                        ref.current += 1;
                        refetch();
                      }}
                    />
                  );
                }
                if (i?.title === formatLabel("Reservation F/U Status")) {
                  return renderItem(
                    i?.title?.toString(),
                    <FollowUpStatusSelect
                      enrollment={{
                        id: taskData?.task?.registration?.enrollment?.id,
                        followUpStatus:
                          taskData?.task?.registration?.enrollment
                            ?.followUpStatus,
                        followUpStatusLastModifiedBy: {
                          fullName:
                            taskData?.task?.registration?.enrollment
                              ?.followUpStatusLastModifiedBy?.fullName,
                        },
                        followUpStatusLastModifiedDateTime:
                          taskData?.task?.registration?.enrollment
                            ?.followUpStatusLastModifiedDateTime,
                      }}
                      disabled={taskData?.task?.status === TaskStatus.Void}
                      followUpType={FollowUpType.Reservation}
                      className="w-full"
                      callback={() => {
                        ref.current += 1;
                        refetch();
                      }}
                    />
                  );
                }
                return renderItem(
                  i?.title?.toString(),
                  i?.render(
                    "",
                    { ...taskData?.task, isTaskDetailModal: true },
                    1
                  ) as ReactNode
                );
              })}
              <TaskRemarks
                task={taskData?.task}
                callback={() => {
                  ref.current += 1;
                  refetch();
                }}
              />
              <Activity
                sourceId={taskData?.task?.id}
                cacheKey={taskData?.task?.lastModifiedDateTime.toISOString()}
              />
            </Space>
          )}
        </Modal.Content>
      </Modal>
      {modal}
    </>
  );
};

export default TaskDetailModal;

import formatLabel from "@/utils/formatLabel";
import { Form, InputNumber } from "@thepiquelab/archus-components-web";
import { formatCurrency } from "@thepiquelab/phonenumber";
import BigNumber from "bignumber.js";
import { FC } from "react";

type BalanceAmountInputProps = {
  creditRemain?: string;
  maxBalance: number;
  isEditMode?: boolean;
  value?: number | string;
};

const BalanceAmountInput = (
  props: BalanceAmountInputProps & {
    onChange?: (value: number | string) => void;
  }
) => {
  const { creditRemain = 0, isEditMode, value, maxBalance, onChange } = props;

  if (isEditMode) return <span>{formatCurrency(value)}</span>;

  return (
    <div className="flex items-center gap-4">
      <InputNumber
        prefix="$"
        value={value}
        onChange={onChange}
        min={new BigNumber(creditRemain).lte(0) || isEditMode ? 0 : 0.01}
        precision={2}
        max={
          new BigNumber(creditRemain).lte(maxBalance)
            ? Number(creditRemain)
            : maxBalance
        }
        disabled={new BigNumber(creditRemain).lte(0) || isEditMode}
      />
      <span className="font-semibold">/ {formatCurrency(creditRemain)}</span>
    </div>
  );
};

const BalanceAmountItem: FC<
  BalanceAmountInputProps & { validator: () => Promise<void> }
> = (props) => {
  const { validator } = props;

  return (
    <Form.Item
      name={"balanceAmount"}
      label={formatLabel("Apply Balance")}
      dependencies={["method", "amount"]}
      validateFirst
      rules={[
        {
          validator: async (rule, value) => {
            if (value > 0) {
              await validator();
            }
          },
        },
      ]}
    >
      <BalanceAmountInput {...props} />
    </Form.Item>
  );
};

export default BalanceAmountItem;

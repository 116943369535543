import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import {
  GetInCompleteRegistration,
  GetInCompleteRegistrationQuery,
  GetInCompleteRegistrationQueryVariables,
} from "../../../graphql";

export const useHaveInCompleteRegistration = () => {
  const { data: incompleteData, refetch } = useQuery<
    GetInCompleteRegistrationQuery,
    GetInCompleteRegistrationQueryVariables
  >(GetInCompleteRegistration, { fetchPolicy: "network-only" });

  const isHaveIncomplete = useMemo(() => {
    if (!incompleteData) return false;
    return incompleteData?.sessions?.length > 0;
  }, [incompleteData]);

  return { isHaveIncomplete, incompleteData, refresh: refetch };
};

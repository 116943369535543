import { Skeleton } from "@thepiquelab/archus-components-web";

const CollectFeeDetailSkeleton = () => (
  <div className="p-8">
    <Skeleton active />
    <Skeleton active />
    <Skeleton active />
  </div>
);

export default CollectFeeDetailSkeleton;

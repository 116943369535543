import { Button, Space } from "@thepiquelab/archus-components-web";
import React, { useEffect, useState } from "react";

export interface ButtonData {
  /**
   * ID for the button to render. This will be used for determining which button
   * is active if the onSelect callback is used.
   */
  id: string;
  /**
   * The label of the button. It can be provided with any ReactNode if a string
   * is not sufficient.
   */
  label: string | React.ReactNode;
  /**
   * An additional value for the button to return. This can be used to further
   * extend the functionality of the button if more than an ID is needed.
   */
  value?: unknown;
}

interface ButtonsProps {
  /**
   * An array of buttons to render. If buttons are defined, children will not
   * be rendered.
   */
  buttons?: ButtonData[];
  children?: React.ReactNode;
  /**
   * ID of the default active button.
   * If not provided, the first button will be set as active.
   */
  defaultActiveButtonId?: string;
  /**
   * The buttons will be rendered side-by-side if set to true;
   * otherwise there will be a spacing in between each button.
   */
  grouped?: boolean;
  /**
   * This callback function is attached to the rendered elements from buttons.
   * The “onClick” property will return the entire ButtonData
   * for the clicked button.
   */
  onClick?: (data: ButtonData) => void;
  /**
   * When provided, one of the buttons will be selectable and stays active.
   * If none of the buttons has the “active” property set to true then
   * the first button would be active.
   */
  onSelect?: (data: ButtonData) => void;
}

/**
 * Create a group of buttons that can be "Clicked" or "Selected". If onSelect is
 * used, the the button that is selected will be highlighted. Only one button
 * can be selected at a time. If onClick is used, there will be no highlighting.
 * The user can also use their own components instead of using the "buttons"
 * parameter.
 */
const Buttons: React.FC<ButtonsProps> = ({
  buttons = [],
  children,
  defaultActiveButtonId,
  grouped = false,
  onClick,
  onSelect,
  ...props
}) => {
  if (!buttons.length && !children) throw SyntaxError;
  const [active, setActive] = useState<ButtonData>();

  /**
   * Send a callback for onClick or onSelect.
   * @param data the data to send out through onClick
   */
  const handleClick = (data: ButtonData): void => {
    if (onClick) {
      onClick(data);
    }
    if (onSelect) {
      // send callback for onSelect and update active state
      onSelect(data);
      setActive(data);
    }
  };

  useEffect(() => {
    if (onSelect && !active && buttons.length) {
      setActive(
        defaultActiveButtonId
          ? buttons.find((i) => defaultActiveButtonId === i.id)
          : buttons[0]
      );
    }
  }, [active, buttons, defaultActiveButtonId, onSelect]);

  if (buttons.length) {
    if (grouped) {
      return (
        <div
          className="bg-gray-400 rounded-default border-0 flex flex-no-wrap w-maxcontent"
          {...props}
        >
          {grouped &&
            buttons.map((button) => (
              <button
                key={button.id}
                className={`rounded-default border-0 h-12 py-0 px-8 font_semibold ${
                  active?.id === button.id
                    ? "bg-white shadow animate-toggleRadioButton text-primary-blue font_bold"
                    : "font_semibold"
                }`}
                type="button"
                id={button.id}
                onClick={() => handleClick(button)}
              >
                {button.label}
              </button>
            ))}
        </div>
      );
    }
    return (
      <Space {...props}>
        {buttons.map((b) => (
          <Button onClick={() => handleClick(b)}>{b.label}</Button>
        ))}
      </Space>
    );
  }
  return <div>{children}</div>;
};

export default Buttons;

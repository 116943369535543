import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Space } from "@thepiquelab/archus-components-web";
import { formatDate } from "@thepiquelab/web-moment";
import React from "react";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const ReminderTaskLessonUnderHoliday: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;
        const { id: classId, shorthand } = attendance?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Holiday"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(
        value,
        record: TaskFieldsFragment & { isTaskDetailModal: boolean }
      ) {
        const { holidays } = record;
        if (!holidays?.length) {
          return <>-</>;
        }
        return holidays?.map((r) => (
          <Space
            direction={record?.isTaskDetailModal ? "horizontal" : "vertical"}
          >
            <div>{r?.name}</div>
            <div>{formatDate(r?.period?.start, { format: "SHORT" })}</div>
          </Space>
        ));
      },
    },
    {
      title: formatLabel("Lesson Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { attendance } = record;

        return (
          <div>
            {attendance?.lesson?.lessonStart
              ? formatDate(attendance?.lesson?.lessonStart, { format: "SHORT" })
              : "-"}
          </div>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Lesson Under PH Reminder")}
        taskType={TaskType.ReminderLessonUnderHoliday}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReminderTaskLessonUnderHoliday;

import AdminLink from "@/components/widget/CommonLink/AdminLink";
import {
  ActivityLogSubType,
  ActivityLogType,
  GetActivitiesBySourceIdsQuery,
} from "@/graphql";
import { formatDateTime } from "@thepiquelab/web-moment";
import { ReactNode } from "react";

const formatAdmins = (adds: { fullName: string; id: string }[]): ReactNode => {
  return adds?.map((add, index) => (
    <span>
      {index ? ", " : ""}
      <AdminLink id={add?.id}>{add?.fullName}</AdminLink>
    </span>
  ));
};

type ActivityLog = GetActivitiesBySourceIdsQuery["activityLogs"]["items"][0];

export const renderActivity = (activity: ActivityLog): ReactNode => {
  let result = <></>;

  const createdBy = (
    <AdminLink id={activity?.createdBy?.id}>
      {activity?.createdBy?.fullName}
    </AdminLink>
  );
  const createdAt = formatDateTime(activity?.createDateTime, {
    format: "SHORT",
  });
  const adds = formatAdmins(activity?.adds);
  const removes = formatAdmins(activity?.removes);
  const keywords = activity?.keywords?.map((keyword) => keyword)?.join(", ");

  const getFromToText = (format?: (item: any) => ReactNode): ReactNode => {
    const previousValue = activity?.previousAndUpdateValue?.previousValue;
    const value = activity?.previousAndUpdateValue?.value;
    const formatFn = format || ((item: any) => item);
    return `from ${previousValue ? formatFn(previousValue) : "-"} to ${
      value ? formatFn(value) : "-"
    }`;
  };
  if (ActivityLogType.Task === activity?.type) {
    /**
     * Create
     */
    switch (activity?.subType) {
      case ActivityLogSubType.Created:
        result = (
          <>
            {createdBy} created task on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskAddedChecklist:
        result = (
          <>
            {createdBy} added {keywords} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskAddedItem:
        result = (
          <>
            {createdBy} added {keywords} on {createdAt}
          </>
        );
        break;

      /**
       * Update
       */
      // Update Assignee
      case ActivityLogSubType.TaskAssigned:
        result = (
          <>
            {createdBy} assigned task to {adds} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskAssignedChecklist:
        result = (
          <>
            {createdBy} assigned {keywords} to {adds} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskAssignedItem:
        result = (
          <>
            {createdBy} assigned {keywords} to {adds} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskUnassigned:
        result = (
          <>
            {createdBy} unassigned task from {removes} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskUnassignedChecklist:
        result = (
          <>
            {createdBy} unassigned {keywords} from {removes} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskUnassignedItem:
        result = (
          <>
            {createdBy} unassigned {keywords} from {removes} on {createdAt}
          </>
        );
        break;
      // Update status
      case ActivityLogSubType.TaskCompleted:
        result = (
          <>
            {createdBy} marked task as complete on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskVoided:
        result = (
          <>
            {createdBy} voided task on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskReopen:
        result = (
          <>
            {createdBy} reopened task on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskCompletedItem:
        result = (
          <>
            {createdBy} completed {keywords} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskUncompletedItem:
        result = (
          <>
            {createdBy} uncompleted {keywords} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskDeletedItem:
        result = (
          <>
            {createdBy} deleted {keywords} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskDeletedChecklist:
        result = (
          <>
            {createdBy} deleted {keywords} on {createdAt}
          </>
        );
        break;
      case ActivityLogSubType.TaskUpdatedDueDate:
        result = (
          <>
            {createdBy} updated task due date{" "}
            {getFromToText((value: any): ReactNode => {
              return formatDateTime(value, { format: "SHORT" });
            })}{" "}
            on {createdAt}
          </>
        );
        break;
      default:
        break;
    }
  }
  return result ? <div>{result}</div> : "";
};

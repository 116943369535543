import { ReactComponent as IconPlus } from "@/assets/images/IconPlus.svg";
import formatLabel from "@/utils/formatLabel";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
} from "@thepiquelab/archus-components-web";
import React, { memo } from "react";
import { CreateDiscountInput, DiscountType } from "../../../graphql";

const { Item, useForm } = Form;
const { Option } = Select;

export type Discount = {
  id: string;
  discountName: string;
  discountType: DiscountType;
  discountAmount: string;
  discountRate: string;
  isArchived: boolean;
  isVisible: boolean;
};

interface DiscountFormProps {
  loading?: boolean;
  onSave?: (value: CreateDiscountInput) => void;
}

export const discountUnit: Record<DiscountType, string> = {
  [DiscountType.Percentage]: "%",
  [DiscountType.Amount]: "$",
};

const DiscountForm: React.FC<DiscountFormProps> = (props) => {
  const { loading, onSave } = props;
  const [form] = useForm();
  const discountType = Form.useWatch("discountType", form);

  const discountAmountValidator = async (
    rule: any,
    value: number
  ): Promise<void> => {
    if (discountType === DiscountType.Amount) {
      if (value <= 0) throw new Error("Amount should be more than 0.");
    } else {
      if (!/^[0-9]+$/.test(String(value)))
        throw new Error("Percentage must be integer!");

      if (value <= 0 || value > 99)
        throw new Error("Percentage must be 1 - 99!");
    }
  };

  const handleFinish = (value: CreateDiscountInput): void => {
    onSave(value as Discount);
    form.resetFields();
  };

  return (
    <>
      <Form
        onFinish={handleFinish}
        form={form}
        layout="vertical"
        requiredMark={false}
      >
        <Item
          label="Discount Name"
          name="discountName"
          rules={[{ required: true, message: "Field is required." }]}
        >
          <Input maxLength={50} />
        </Item>
        <div className="flex w-full justify-between">
          {discountType === DiscountType.Amount ? (
            <Item
              dependencies={["discountType"]}
              className="w-10/12"
              label="Discount Amount"
              validateFirst
              name="discountAmount"
              rules={[
                { required: true, message: "Field is required." },
                // {
                //   validator: discountAmountValidator,
                // },
              ]}
            >
              <InputNumber
                className="w-full"
                prefix={"$"}
                min={0.01}
                precision={2}
              />
            </Item>
          ) : (
            <Item
              dependencies={["discountType"]}
              className="w-10/12"
              label="Discount Amount"
              validateFirst
              name="discountRate"
              rules={[
                { required: true, message: "Field is required." },
                // {
                //   validator: discountAmountValidator,
                // },
              ]}
            >
              <InputNumber
                className="w-full"
                prefix={null}
                min={1}
                max={100}
                precision={0}
              />
            </Item>
          )}

          <Item
            label
            name="discountType"
            // rules={[{ required: true, message: "Field is required." }]}
            initialValue={DiscountType.Percentage}
          >
            <Select<DiscountType>
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {Object.keys(discountUnit).map((key: DiscountType) => (
                <Option key={key} value={key}>
                  {discountUnit[key]}
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className="flex justify-end">
          <Button
            className="pr-0"
            type="link"
            onClick={form.submit}
            loading={loading}
            icon={<IconPlus className="mr-2" />}
          >
            {formatLabel("Add")}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default memo(DiscountForm);

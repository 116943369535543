import { Avatar, Tooltip } from "@thepiquelab/archus-components-web";
import { upperCase } from "lodash";
import React from "react";

interface AssigneeAvatarProps {
  assignee: {
    _id: string;
    initials?: string;
    fullName?: string;
    avatarUrl?: string;
  };
}

const AssigneeAvatar: React.FC<AssigneeAvatarProps> = (props) => {
  const { assignee } = props;
  const { initials, fullName, avatarUrl } = assignee;

  const AvatarText =
    initials?.substring(0, 2) ||
    fullName
      ?.split(" ")
      ?.map((item) => item[0])
      ?.join("")
      ?.substring(0, 2);
  return (
    <Tooltip title={fullName}>
      {avatarUrl ? (
        <Avatar src={avatarUrl} />
      ) : (
        <Avatar>
          <span>{upperCase(AvatarText)}</span>
        </Avatar>
      )}
    </Tooltip>
  );
};

export default AssigneeAvatar;

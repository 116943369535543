/* eslint-disable no-restricted-syntax */
import { Checkbox, Col, Row, Select } from "@thepiquelab/archus-components-web";
import moment, { formatDate, formatTimeRange } from "@thepiquelab/web-moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  BasicTermFieldsFragment,
  DateTimeRange,
  GetSetDateModalClassByIdQuery,
  SelectedLessonInput,
} from "../../../../graphql";
import styles from "./TrialModal.module.scss";

interface Props {
  lessonsData: GetSetDateModalClassByIdQuery["class"]["lessons"];
  availableLessonIds: string[];
  onAttendingLessonsChange: (v: Array<SelectedLessonInput>) => void;
  onDueDateChange: (v: Date) => void;
  onRegistrationDateChange: (v: Date) => void;
  selectedLessons?: SelectedLessonInput[];
  term?: BasicTermFieldsFragment;
}

const formatCurrentTerm = (res?: BasicTermFieldsFragment) => {
  for (const [, value] of Object.entries(res)) {
    if (
      moment().isBetween(
        moment((value as DateTimeRange).start),
        moment((value as DateTimeRange).end),
        "day"
      )
    ) {
      return {
        start: (value as DateTimeRange).start,
        end: (value as DateTimeRange).end,
      };
    }
  }
  return null;
};

const formatCurrentPeriod = (res?: BasicTermFieldsFragment): string => {
  for (const [key, value] of Object.entries(res)) {
    if (
      moment().isBetween(
        (value as DateTimeRange).start,
        (value as DateTimeRange).end,
        "day"
      )
    ) {
      return key;
    }
  }

  return null;
};

const TrialModal: React.FC<Props> = ({
  lessonsData,
  availableLessonIds,
  onAttendingLessonsChange,
  onDueDateChange,
  onRegistrationDateChange,
  selectedLessons = [],
  term,
}) => {
  const [selectData, setSelectData] =
    useState<Array<SelectedLessonInput>>(selectedLessons);

  const [period, setPeriod] = useState<{ start: Date; end: Date }>(
    formatCurrentTerm(term)
  );
  const [selectValue, setSelectValue] = useState<string>(
    formatCurrentPeriod(term)
  );

  useEffect(() => {
    onAttendingLessonsChange(selectData);
  }, [selectData]);

  const sortedLessons = useMemo(() => {
    if (period?.start) {
      return lessonsData
        .slice()
        .sort((a, b) => a.lessonNumber - b.lessonNumber)
        ?.filter((a) =>
          moment(a.lessonStart).isBetween(
            moment(period.start),
            moment(period.end),
            "day",
            "[]"
          )
        );
    }
    return lessonsData.slice().sort((a, b) => a.lessonNumber - b.lessonNumber);
  }, [lessonsData, period]);

  const onChange = (checkedValues: any[]): void => {
    const selectedLessons = sortedLessons.filter((item) =>
      checkedValues.includes(item.id)
    );

    const lastLessonDate =
      selectedLessons && selectedLessons.length
        ? selectedLessons[selectedLessons.length - 1].lessonEnd
        : new Date();

    const firstLessonDate = selectedLessons?.length
      ? selectedLessons[0].lessonStart
      : new Date();

    const checkData = selectedLessons.map((item) => ({
      lessonId: item.id,
    }));

    onDueDateChange(lastLessonDate);
    setSelectData(checkData);
    onRegistrationDateChange(firstLessonDate);
  };

  const onSelect = (value: keyof BasicTermFieldsFragment): void => {
    setSelectValue(value);
    setPeriod({
      start: (term[value] as DateTimeRange).start,
      end: (term[value] as DateTimeRange).end,
    });
  };

  return (
    <div data-testid="TrialModal">
      <div>Select Term</div>
      <Select
        className="w-full mb-4 mt-2"
        onSelect={onSelect}
        value={selectValue}
        options={[
          { value: "term1Period", label: "Term 1" },
          { value: "term2Period", label: "Term 2" },
          { value: "term3Period", label: "Term 3" },
          { value: "term4Period", label: "Term 4" },
        ]}
      />

      <div className="h-40vh overflow-auto">
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={onChange}
          value={selectData && selectData.map((item) => item.lessonId)}
        >
          {sortedLessons.map((item) => (
            <Row className="mb-2" key={item.id}>
              <Col>
                <Checkbox
                  value={item.id}
                  className="inline-flex items-start"
                  disabled={!availableLessonIds?.includes(item.id)}
                >
                  <span className="flex flex-col">
                    <span
                      className={`${
                        item.attendance &&
                        item.maximumClassCapacity <= item.attendance.length
                          ? styles.TrialModal__full
                          : "font_semibold"
                      } `}
                    >
                      {`${formatDate(item.lessonStart)}`}
                    </span>
                    <span style={{ color: "#8C9297" }}>
                      {formatTimeRange(item.lessonStart, item.lessonEnd, {
                        showDayOfWeek: true,
                      })}
                    </span>
                  </span>
                </Checkbox>
              </Col>
            </Row>
          ))}
        </Checkbox.Group>
      </div>
    </div>
  );
};

export default TrialModal;

import { ReactComponent as IconCopy } from "@/assets/images/IconCopyFull.svg";
import { ReactComponent as IconError } from "@/assets/images/IconError.svg";
import { ReactComponent as IconHandoff } from "@/assets/images/IconHandoff.svg";
import { ReactComponent as IconOffline } from "@/assets/images/IconOffline.svg";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import QRCodeModal from "@/components/settings/ProcessAutomation/Whatsapp/QRCodeModal";
import { useLoginWhatsappAccount } from "@/components/settings/ProcessAutomation/Whatsapp/WhatsappHooks";
import VariableName from "@/components/settings/Template/VariableName";
import {
  controls,
  formatVariableName,
  isUndefined,
  prevWhatsAppMessage,
  removeBraftEditorClassName,
} from "@/components/settings/Template/tools";
import {
  BasicTemplateFieldsFragment,
  BulkSendReminderInput,
  GetReminderFormParentQuery,
  ReminderType,
  SendReminderInput,
  Telephone,
  WhatsappAccountStatus,
} from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  FormInstance,
  message,
  notification,
  Select,
} from "@thepiquelab/archus-components-web";
import { formatPhoneNumber } from "@thepiquelab/phonenumber";
import BraftEditor, { ControlType } from "braft-editor";
import { intersection } from "lodash";
import { useEffect, useMemo, useState } from "react";
import ReceiverCheckBox from "../common/ReceiverCheckbox";
import { RichTextEditorType, richTextEditorValidator } from "../utils";
import { useLazyQueryWhatsappAccounts } from "./hooks";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ContentUtils } from "braft-utils";

import useCopy from "@/hooks/dom/useCopy";
import { useSalutation } from "@/hooks/reference.hook";
import { download } from "@/utils/download";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { ExportOutlined } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import "braft-editor/dist/index.css";
import {
  handleCopyAllReminder,
  handleExportReminder,
} from "../PreviewMessage/Utils";
import Line from "../common/Line";
import {
  useCompileTemplate,
  useLazyQueryBulkExportReminderDocx,
  useLazyQueryExportReminderDocx,
} from "../hooks";

interface WhatsappFormProps {
  form?: FormInstance;
  parent?: GetReminderFormParentQuery["parent"];
  templates?: BasicTemplateFieldsFragment[];
  attachmentLabel?: string;
  isShowSendInvoice?: boolean;
  showPreview?: boolean;
  onPreview?: (isEmail: boolean) => void;
  previewLoading?: boolean;
  isBulk: boolean;
  partialSendReminderInput: Partial<SendReminderInput>;
  formatCompileInput: (
    isEmail: boolean
  ) => SendReminderInput | BulkSendReminderInput;
}

const layout = {
  wrapperCol: {
    offset: 3,
    span: 21,
  },
};

const msgKey = "FinanceNotification";
const notificationDurationTime = 5;
const messageKey = "tasks";
const WhatsappForm: React.FC<WhatsappFormProps> = (props) => {
  const {
    form,
    parent,
    templates,
    showPreview,
    attachmentLabel = "Invoice",
    isShowSendInvoice = true,
    onPreview,
    previewLoading,
    isBulk,
    partialSendReminderInput,
    formatCompileInput,
  } = props;
  const [queryWhatsappAccounts, { data: accounts, loading }] =
    useLazyQueryWhatsappAccounts();
  const [loginWhatsappAccount, { data: loginData, loading: loginLoading }] =
    useLoginWhatsappAccount();
  const { copy } = useCopy();
  const { formatSalutation } = useSalutation();
  const [
    compileTemplate,
    { data: compiledData, loading: compiledDataLoading },
  ] = useCompileTemplate((error: ApolloError) => {
    message.error(formatErrorMessage(error));
  });

  const [ExportReminderDocx, { data: exportData, loading: exportLoading }] =
    useLazyQueryExportReminderDocx({
      fetchPolicy: "network-only",
      onCompleted() {
        download(exportData?.exportReminderDocx, messageKey);
      },
      onError() {
        message.error({
          content: "Export was unsuccessful",
          key: messageKey,
        });
      },
    });
  const [
    bulkExportReminderDocx,
    { data: bulkExportData, loading: bulkExportLoading },
  ] = useLazyQueryBulkExportReminderDocx({
    fetchPolicy: "network-only",
    onCompleted() {
      download(bulkExportData?.bulkExportReminderDocx, messageKey);
    },
    onError() {
      message.error({
        content: "Export was unsuccessful",
        key: messageKey,
      });
    },
  });

  const isShowWhatsAppFeature = useFeatureHook([KEYWORD_ENUM.global_whatsApp]);
  const whatsAppSenderId = Form.useWatch("whatsAppSenderId", form);
  const whatsAppTemplateId = Form.useWatch("whatsAppTemplateId", form);
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState<boolean>(false);
  const { Handoff, Offline } = WhatsappAccountStatus;

  const currentAccount = useMemo(() => {
    if (!whatsAppSenderId) {
      return null;
    }

    return accounts?.whatsappAccounts?.items?.find(
      (item) => item.id === whatsAppSenderId
    );
  }, [whatsAppSenderId, accounts]);

  const selectedTemplate = useMemo(() => {
    if (!whatsAppTemplateId) {
      return null;
    }
    return templates?.find((t) => t.id === whatsAppTemplateId);
  }, [templates, whatsAppTemplateId]);

  let whatsAppControls: ControlType[] = [
    ...intersection(controls, ["undo", "redo", "emoji"]),
  ];
  if (isBulk) {
    whatsAppControls = [
      ...whatsAppControls,
      {
        key: "whatsApp-variable-dropdown",
        type: "dropdown",
        text: "Add variables",
        autoHide: true,
        className: "whatsApp-variable-dropdown",
        component: (
          <VariableName
            usageType={selectedTemplate?.usageType}
            onClick={(name) => {
              const editor = form?.getFieldValue("whatsappMessage");
              if (editor) {
                form?.setFieldsValue({
                  whatsappMessage: ContentUtils.insertText(editor, name),
                });
              } else {
                form?.setFieldsValue({
                  whatsappMessage: ContentUtils.insertText(
                    BraftEditor.createEditorState(null),
                    name
                  ),
                });
              }
              removeBraftEditorClassName("whatsApp-variable-dropdown");
            }}
          />
        ),
      },
    ];
  }

  const openNotification = (
    status: WhatsappAccountStatus | string = "",
    number: Telephone,
    description: React.ReactNode,
    icon: React.ReactNode
  ): void => {
    notification.open({
      className: "relative",
      icon,
      duration: notificationDurationTime,
      description,
      key: msgKey,
      message:
        number && status ? (
          <p>{`${formatLabel(status)}: ${formatPhoneNumber(number)}`}</p>
        ) : null,
    });
  };

  const handleConnectivity = async (
    id: string,
    status: WhatsappAccountStatus
  ): Promise<void> => {
    switch (status) {
      case Offline:
        // handleOpenQrCodeModal(id);
        break;
      case Handoff:
        // await handleReconnect(id);
        break;
      default:
        break;
    }
  };

  const handleSelectSendFrom = (id: string): void => {
    const { whatsappAccounts } = accounts || {};
    const account = whatsappAccounts?.items?.find((item) => item?.id === id);
    const { status, number } = account || {};

    if (!isShowWhatsAppFeature) {
      return null;
    }

    switch (status) {
      case Offline:
        return openNotification(
          status,
          number,
          <>
            <Line className="bg-primary-red" />
            <span>WhatsApp account is currently offline.</span>
            <span
              className="pl-2 cursor-pointer text-primary-blue"
              onClick={() => handleConnectivity(id, status)}
            >
              Login
            </span>
          </>,
          <IconOffline className="w-6 h-6" />
        );
      case Handoff:
        return openNotification(
          status,
          number,
          <>
            <Line className="bg-primary-blue" />
            <span>WhatsApp account is currently used in another computer.</span>
            <span
              className="pl-2 cursor-pointer text-primary-blue"
              onClick={() => handleConnectivity(id, status)}
            >
              Use Here
            </span>
          </>,
          <IconHandoff className="w-5 h-5 ml-0" />
        );
      default:
        return null;
    }
  };

  const handleLoginDone = async (): Promise<void> => {
    // await queryWhatsappAccount({
    //   variables: { id: form.getFieldValue("WhatsAppSenderId") },
    // });

    setQrCodeModalVisible(false);
  };

  const handleOpenQrCodeModal = (id: string): void => {
    loginWhatsappAccount({ variables: { id } });
    setQrCodeModalVisible(true);
  };

  const updatedByTemplate = (template: BasicTemplateFieldsFragment): void => {
    const { whatsAppMessage } = template;
    const whatsapp = prevWhatsAppMessage(
      formatVariableName(whatsAppMessage, false)
    );
    if (!isBulk) {
      form.setFieldsValue({
        whatsAppTemplateId: template.id,
      });
      return;
    }
    form.setFieldsValue({
      whatsappMessage: BraftEditor.createEditorState(whatsapp),
      whatsAppTemplateId: template.id,
    });
  };

  const handleWhatsAppTemplateChange = (templateId: string): void => {
    if (templateId && templates?.length) {
      const template = templates.find(({ id }) => id === templateId);
      updatedByTemplate(template);
    }
  };

  const handleExport = async (type: ReminderType): Promise<void> => {
    const whatsappMessage = form?.getFieldValue("whatsappMessage") || "";
    handleExportReminder({
      type,
      input: {
        ...formatCompileInput(true),
        ...partialSendReminderInput,
      },
      whatsappInput: {
        message: isUndefined(formatVariableName(whatsappMessage?.toHTML?.())),
        templateId: selectedTemplate.id,
      },
      isBulk,
      bulkExportReminderDocx,
      ExportReminderDocx,
    });
  };
  const handleCopyAll = async (type: ReminderType): Promise<void> => {
    let whatsappMessage = form?.getFieldValue("whatsappMessage") || "";
    whatsappMessage = isUndefined(
      formatVariableName(whatsappMessage?.toText?.())
    );
    handleCopyAllReminder({
      type,
      compiledData: [
        {
          ...compiledData.compileTemplate,
          whatsappMessage,
        },
      ],
      copy,
      formatSalutation,
      isBulk,
    });
  };

  useEffect(() => {
    if (isShowWhatsAppFeature && parent) {
      queryWhatsappAccounts({
        variables: {
          filterInput: {
            isArchived: false,
          },
          pageInfo: {
            pageSize: 0,
            pageIndex: 0,
          },
        },
      });
    }
  }, [isShowWhatsAppFeature, parent]);

  useEffect(() => {
    if (templates?.length) {
      const template = templates[0];
      updatedByTemplate(template);
    }
  }, [templates]);

  useEffect(() => {
    if (selectedTemplate && !isBulk) {
      compileTemplate({
        variables: {
          input: {
            types: [ReminderType.Whatsapp],
            whatsappInput: {
              message: selectedTemplate?.whatsAppMessage,
              templateId: selectedTemplate.id,
            },
            ...partialSendReminderInput,
          },
        },
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    const whatsappMessage = compiledData?.compileTemplate?.whatsappMessage;
    if (whatsappMessage) {
      form.setFieldsValue({
        whatsappMessage: BraftEditor.createEditorState(
          `<p>${prevWhatsAppMessage(whatsappMessage)}</p>`
        ),
      });
    }
  }, [compiledData]);

  return (
    <div className="p-4">
      {[Offline, Handoff].includes(currentAccount?.status) && (
        <Alert
          showIcon={false}
          type="error"
          className="mb-8 bg-red-100 border-red-400 rounded-none"
          message={
            <div className="inline-flex items-center p-0 mt-2 text-primary-red">
              <IconError className="mr-2" />
              WhatsApp Line is currently unavailable. This message will be
              queued.
            </div>
          }
        />
      )}
      {parent ? (
        <>
          <Form.Item
            name="whatsAppSenderId"
            label="Send from"
            rules={[
              {
                required: true,
                message: "Field is required.",
              },
            ]}
            colon
          >
            <Select
              loading={loading}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={accounts?.whatsappAccounts?.items?.map((a) => ({
                label: `${a.name} - ${formatPhoneNumber(a.number)}`,
                value: a.id,
              }))}
              onChange={handleSelectSendFrom}
            />
          </Form.Item>
          <Form.Item
            name={"whatsAppReceiveIds"}
            label="To "
            rules={[
              {
                required: true,
                message: formatLabel("Field is required."),
              },
            ]}
            colon
          >
            <ReceiverCheckBox
              primaryParent={parent}
              parents={parent.relatedParents}
              reminderType={ReminderType.Whatsapp}
            />
          </Form.Item>
        </>
      ) : null}
      <Divider />
      <Form.Item
        label={formatLabel("Template")}
        name="whatsAppTemplateId"
        initialValue={templates?.[0]?.id}
        rules={[
          {
            required: true,
            message: "Field is required.",
          },
        ]}
        colon
      >
        <Select
          // loading={templatesLoading}
          allowClear
          onChange={handleWhatsAppTemplateChange}
        >
          {templates?.map((t) => (
            <Select.Option key={t.id} value={t.id}>
              {formatLabel(t.title)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={"whatsappMessage"}
        {...layout}
        rules={[
          {
            validator: () =>
              richTextEditorValidator(RichTextEditorType.whatsappMessage, form),
          },
        ]}
      >
        <BraftEditor
          language={"en"}
          className="bg-gray-200 border border-gray-500 rounded-default font-arial"
          controls={whatsAppControls}
          onChange={(editorState: any) => {
            form.setFieldsValue({ whatsappMessage: editorState });
          }}
        />
      </Form.Item>
      <Form.Item
        className="mb-0"
        wrapperCol={{
          span: 21,
          offset: 3,
        }}
      >
        <div
          className={`flex items-center ${
            isShowSendInvoice ? "justify-between" : "justify-end"
          } font_regular text-4 text-primary-blue`}
        >
          {isShowSendInvoice ? (
            <Form.Item
              label={formatLabel("")}
              name="isWhatsappSendInvoice"
              valuePropName="checked"
              style={{ margin: 0 }}
              initialValue
            >
              <Checkbox checked className="ml-2 text-primary-blue">
                {`Attach ${attachmentLabel}`}
              </Checkbox>
            </Form.Item>
          ) : (
            <></>
          )}
          <div className="flex items-center justify-end gap-x-2">
            {showPreview && isBulk && (
              <div className="flex justify-end">
                <Button
                  loading={previewLoading}
                  onClick={() => {
                    onPreview(false);
                  }}
                >
                  Preview
                </Button>
              </div>
            )}
            {!isBulk && (
              <>
                <Button
                  icon={<IconCopy className="mr-2" />}
                  onClick={() => {
                    handleCopyAll(ReminderType.Whatsapp);
                  }}
                >
                  Copy
                </Button>
                <Button
                  icon={<ExportOutlined className="mr-2" />}
                  loading={exportLoading}
                  onClick={() => {
                    handleExport(ReminderType.Whatsapp);
                  }}
                >
                  Export
                </Button>
              </>
            )}
          </div>
        </div>
      </Form.Item>
      <QRCodeModal
        visible={qrCodeModalVisible}
        onLoginDone={handleLoginDone}
        onRefresh={() => {
          handleOpenQrCodeModal(currentAccount.id);
        }}
        loading={loginLoading}
        imgSrc={loginData?.whatsappLogin?.image}
      />
    </div>
  );
};

export default WhatsappForm;

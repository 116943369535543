import { Skeleton } from "@thepiquelab/archus-components-web";

const SelectCalendarDateSkeleton = () => (
  <div className="flex flex-col gap-6 overflow-auto">
    <div className="flex justify-between items-center gap-4">
      <Skeleton.Button active className="w-full" />
      <Skeleton.Button active className="w-full" />
    </div>

    <div className="flex items-center justify-around gap-4">
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
    </div>
    <div className="flex items-center justify-around gap-4">
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
    </div>
    <div className="flex items-center justify-around gap-4">
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
    </div>
    <div className="flex items-center justify-around gap-4">
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
      <Skeleton.Button className="w-12 overflow-auto rounded-default" />
    </div>
  </div>
);

export default SelectCalendarDateSkeleton;

import AuthorizedByPermission, {
  EnumAuthHandleType,
  IAuthorizedPermissions,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown } from "@thepiquelab/archus-components-web";
import type { MenuProps } from "antd";
import { Menu } from "antd";

import formatLabel from "@/utils/formatLabel";
import React from "react";
import { CSSProperties } from "styled-components";
import "./DropdownAction.scss";

export declare interface Action extends IAuthorizedPermissions {
  icon?: React.ReactNode;
  label: string;
  onClick?: (id?: string) => void;
  disabled?: boolean;
  hide?: boolean;
  type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed";
  className?: string;
  style?: CSSProperties;
  permissions?: EnumPermissions[];
  key?: string;
}

export declare interface ActionDropdownProps {
  id?: string;
  actions: Array<Action>;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

type MenuItem = Required<MenuProps>["items"][number];

const DropdownAction: React.FC<ActionDropdownProps> = ({
  id,
  actions,
  children,
  className = "",
  style,
}) => {
  const item = actions
    .filter((action) => !action.hide)
    .map((action, index) => ({
      ...(action as any),
      key: action.label,
      onClick: (event) => {
        const { domEvent } = event;
        domEvent?.preventDefault();
        domEvent?.stopPropagation();
        if (action.onClick) {
          action.onClick(id);
        }
      },
      className: `${index === 0 ? "" : "border-t"} py-2 ${
        action.className ?? ""
      }`,
      style: action.style,
      disabled: action.disabled,
      "data-testid": `dropdown-menu-item-${index}`,
      icon: action.icon,
      label: formatLabel(action.label),
    })) as MenuItem[];

  const menu = (
    <AuthorizedByPermission authHandleType={EnumAuthHandleType.FOR_MENU}>
      <Menu
        className={`overflow-hidden w-maxcontent`}
        style={style}
        data-testid="dropdown-menu"
        items={item}
      />
    </AuthorizedByPermission>
  );

  const DefaultChildren = (
    <EllipsisOutlined
      className={`dropdown_action ${className}`}
      data-testid="DropdownAction"
      onClick={(event) => event.stopPropagation()}
    />
  );
  return (
    <>
      {actions.length ? (
        <Dropdown overlay={menu}>{children || DefaultChildren}</Dropdown>
      ) : (
        children || DefaultChildren
      )}
    </>
  );
};

export default DropdownAction;

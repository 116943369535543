import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Loading from "../../components/common/Loading";

const InsufficientCredentialsPage = lazy(
  () => import("./InsufficientCredentialsPage")
);
const ErrorsIndex: React.FC = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path="/errors" element={<InsufficientCredentialsPage />} />
      <Navigate to="/errors" />
    </Routes>
  </Suspense>
);

export default ErrorsIndex;

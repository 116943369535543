import { Skeleton } from "@thepiquelab/archus-components-web";
import { FC } from "react";

const HybridGroupsModalSkeleton: FC = () => (
  <div className="flex flex-col gap-6 overflow-auto">
    <div className="flex justify-around">
      <Skeleton.Button active className="h-13" />
      <Skeleton.Button active className="h-13" />
      <Skeleton.Button active className="h-13" />
    </div>
    <div className="w-1/2 rounded-default overflow-auto">
      <Skeleton.Input active className="w-full" />
    </div>
    <div className="flex justify-between items-center">
      <div className="flex items-center w-2/5">
        <Skeleton.Avatar active size="large" />
        <div className="flex flex-col gap-1 ml-2 w-4/5">
          <Skeleton.Input size="small" className="w-full" />
          <Skeleton.Input size="small" className="w-full" />
        </div>
      </div>
      <div className="flex flex-col gap-1 w-1/5 rounded-default overflow-auto">
        <Skeleton.Input className="w-full" />
      </div>
    </div>
    <div className="flex justify-between items-center">
      <div className="flex items-center w-2/5">
        <Skeleton.Avatar active size="large" />
        <div className="flex flex-col gap-1 ml-2 w-4/5">
          <Skeleton.Input size="small" className="w-full" />
          <Skeleton.Input size="small" className="w-full" />
        </div>
      </div>
      <div className="flex flex-col gap-1 w-1/5 rounded-default overflow-auto">
        <Skeleton.Input className="w-full" />
      </div>
    </div>
  </div>
);

export default HybridGroupsModalSkeleton;

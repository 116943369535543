import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { Steps } from "@thepiquelab/archus-components-web";
import React, { ComponentType, SVGProps } from "react";
import { CSSProperties } from "styled-components";

export interface ProgressProps {
  // steps?: StepsProps;
  className?: string;
  step?: StepType[];
}

export type StepStatus = "wait" | "process" | "finish" | "error";

export type IconType = ComponentType<
  CustomIconComponentProps | SVGProps<SVGSVGElement>
>;

export type StepType = {
  title: string;
  subTitle?: string;
  description?: string;
  status?: StepStatus;
  icon?: IconType;
};
type IconStyle = {
  wait: CSSProperties;
  process: CSSProperties;
  error: CSSProperties;
  finish: CSSProperties;
};
const { Step } = Steps;

const Progress: React.FC<ProgressProps> = (props) => {
  const { step, className } = props;

  const iconStyle: IconStyle = {
    wait: {
      fontSize: "30px",
      color: "#A7A9BD",
      background: "#F0F1F9",
      padding: "1rem",
      borderRadius: "50%",
    },
    process: {
      fontSize: "30px",
      color: "#fff",
      background: "#5B9DFF",
      padding: "1rem",
      borderRadius: "50%",
    },
    error: {
      fontSize: "30px",
      color: "#fff",
      background: "#F36B7F",
      padding: "1rem",
      textAlign: "center",
      borderRadius: "50%",
    },
    finish: {
      fontSize: "30px",
      overflow: "hidden",
      color: "#fff",
      background: "#5B9DFF",
      padding: "1rem",
      borderRadius: "50%",
    },
  };

  const getIcon = (s: StepType, index: number) => {
    if (s.status && s.icon) {
      if (index === 0) {
        return {
          icon: <Icon component={s.icon} style={iconStyle[s.status]} />,
        };
      }
      if (index > 0) {
        return {
          icon: (
            <Icon
              component={s.icon}
              style={s.status ? iconStyle[s.status] : iconStyle.wait}
            />
          ),
        };
      }
    }
    if (s.icon && s.status) {
      return { icon: <Icon component={s.icon} style={iconStyle[s.status]} /> };
    }
    if (!s.status && s.icon) {
      return index > 0
        ? { icon: <Icon component={s.icon} style={iconStyle.wait} /> }
        : {
            icon: <Icon component={s.icon} style={iconStyle.finish} />,
          };
    }

    if (s.icon) {
      return {
        icon: (
          <Icon
            component={s.icon}
            style={s.status ? iconStyle[s.status] : iconStyle.finish}
          />
        ),
      };
    }

    return null;
  };

  const finishOrErrorIndex = ((): number => {
    let current = -1;
    for (let i = step.length - 1; i >= 0; i -= 1) {
      if (step[i].status === "error" || step[i].status === "finish") {
        current = i;
        break;
      }
    }
    return current;
  })();

  const getSteps = (): StepType[] =>
    step.map((s, index) =>
      index < finishOrErrorIndex ? { ...s, status: "finish" } : s
    );

  return (
    <Steps
      labelPlacement="vertical"
      className={`m-6 steps ${className || ""} w-auto`}
    >
      {getSteps().map((s, index) => (
        <Step
          title={s.title}
          {...getIcon(s, index)}
          status={s.status}
          description={s?.description}
          subTitle={s?.subTitle}
        />
      ))}
    </Steps>
  );
};

export default Progress;

import { isNil } from "lodash";

export const formatLocation = (
  branch: {
    name: string;
    alias: string;
  },
  venue: {
    shorthand: string;
    unitNo?: string;
  }
): string => {
  if (!venue) return "";
  return `${branch?.alias || ""} ${formatVenueName(venue)}`;
};

export const formatVenueName = (venue: {
  shorthand: string;
  unitNo?: string;
}): string => {
  if (!venue) {
    return "";
  }
  const { shorthand, unitNo } = venue;
  if (unitNo) {
    return `#${unitNo} ${shorthand}`;
  }
  return shorthand || "";
};

export const formaLocationWithSort = (
  branch: {
    name: string;
    alias: string;
  },
  venue: {
    shorthand: string;
    unitNo?: string;
  }
): string => {
  const location = formatLocation(branch, venue);
  if (!location) {
    return "";
  }
  const { shorthand } = venue;
  const number = shorthand.match(/\d+$/)?.[0];
  if (isNil(number)) {
    return location;
  }
  const numberWithPrefix = number.padStart(2, "0");
  const res = location.replace(/\d+$/, "") + numberWithPrefix;
  return res;
};

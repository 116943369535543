import { formatDateTime } from "@thepiquelab/web-moment";
import { StepStatus, StepType } from "..";
import { InvoiceStatus } from "../../../../graphql";
import IconPaths, { ProgressIconType } from "./IconPaths";
import {
  obtainPaymentSubTitle,
  obtainStatusByInvoice,
} from "./obtainBasicTemplate";

interface Invoice {
  status: InvoiceStatus;
  amountPaid: string;
  total: string;
  lastModifiedDateTime: Date;
  createDateTime: Date;
  payments?: Array<{ isVoid?: boolean; createDateTime: Date }>;
}

const getInvoiceSteps = (invoice: Partial<Invoice>): Array<StepType> => {
  const stepStatus: StepStatus = obtainStatusByInvoice(
    invoice.status,
    invoice.payments
  );

  return [
    {
      title: "Invoice Generated",
      description: formatDateTime(invoice.createDateTime, { format: "SHORT" }),
      status: "finish",
      icon: IconPaths[ProgressIconType.CREATE],
    },
    {
      title: "Payment",
      description:
        invoice.status === InvoiceStatus.Paid && invoice?.payments?.length
          ? formatDateTime(
              invoice.payments[invoice.payments.length - 1].createDateTime
            )
          : "",
      subTitle: obtainPaymentSubTitle(invoice.status, invoice.payments),
      status: stepStatus,
      icon: IconPaths[ProgressIconType.PAYMENT],
    },
    {
      title: "Done",
      status: stepStatus === "finish" ? "finish" : "wait",
      icon: IconPaths[ProgressIconType.DONE],
    },
  ];
};

export const invoiceTransform = (invoice: any): Array<StepType> => {
  if (!invoice) return [];

  return getInvoiceSteps(invoice);
};

import {
  Button,
  Empty,
  Modal,
  Skeleton,
} from "@thepiquelab/archus-components-web";
import Countdown from "antd/lib/statistic/Countdown";
import moment, { Moment } from "@thepiquelab/web-moment";
import React, { useState } from "react";

interface QRCodeModalProps {
  visible?: boolean;
  title?: string;
  loading?: boolean;
  onLoginDone: () => void;
  onRefresh?: () => void;
  imgSrc?: string;
}
const QRCodeModal: React.FC<QRCodeModalProps> = ({
  title = "Login",
  visible,
  imgSrc,
  loading,
  onLoginDone,
  onRefresh,
}) => {
  const [deadline, setDeadline] = useState<Moment>(moment());
  const [isShowCountdown, setIsShowCountdown] = useState(false);

  const handleRefresh = (): void => {
    setDeadline(moment().add(30, "s"));
    setIsShowCountdown(true);
    onRefresh?.();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onLoginDone}
      title={title}
      centered
      footer={
        <div className="flex justify-end">
          <Button
            className="mr-2 items-center"
            style={{ display: "flex" }}
            onClick={handleRefresh}
            disabled={loading || isShowCountdown}
          >
            <span>Refresh</span>
            {isShowCountdown && (
              <Countdown
                className="inline-flex"
                value={deadline.valueOf()}
                format="(s)"
                onFinish={() => {
                  setIsShowCountdown(false);
                }}
                valueStyle={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  padding: ".5rem 0",
                  marginLeft: "0.5rem",
                }}
              />
            )}
          </Button>
          <Button type="primary" onClick={onLoginDone}>
            Done
          </Button>
        </div>
      }
    >
      {loading && (
        <Skeleton.Avatar
          size={390}
          shape="square"
          active={loading}
          className="flex items-center justify-center"
        />
      )}

      {!loading && imgSrc && (
        <img className="w-full" src={imgSrc} alt="qrCode" />
      )}

      {!loading && !imgSrc && <Empty />}
    </Modal>
  );
};
export default QRCodeModal;

import { ReactComponent as IconCopy } from "@/assets/images/IconCopyFull.svg";
import {
  formatTemplateBr,
  formatTextBoldFromWhatsAppFormatToEmailFormat,
  formatVariableName,
  prevWhatsAppMessage,
} from "@/components/settings/Template/tools";
import { Telephone } from "@/graphql";
import useCopy from "@/hooks/dom/useCopy";
import { useSalutation } from "@/hooks/reference.hook";

import { Button } from "@thepiquelab/archus-components-web";
import { formatPhoneNumber } from "@thepiquelab/phonenumber";
import { FC, memo } from "react";

interface WhatsappMessageData {
  whatsappMessage?: string;
  parent?: {
    fullName?: string;
    mobile?: Telephone;
    email?: string;
    salutation?: string;
    linkedWhatsappAccount?: {
      number?: Telephone;
    };
  };
  students?: {
    fullName?: string;
  }[];
}

interface Props {
  item?: WhatsappMessageData;
}

export const formatWhatsappNumber = (linkedWhatsappAccount: {
  number?: Telephone;
}): string => {
  const whatsappNumber = linkedWhatsappAccount
    ? `[${linkedWhatsappAccount?.number?.number?.slice(0, 4)}]`
    : "";

  return whatsappNumber;
};

export const formatWhatsappMessage = (item: WhatsappMessageData): string =>
  item?.whatsappMessage?.trim();

const WhatsappMessage: FC<Props> = (props) => {
  const { item } = props;
  const { formatSalutation } = useSalutation();

  const { copy } = useCopy();

  const onCopy = (): void => {
    const text = formatWhatsappMessage(item);
    copy(text);
  };

  return (
    <div>
      {item?.parent?.linkedWhatsappAccount ? (
        <div className="mb-1 font-normal">
          {formatWhatsappNumber(item?.parent?.linkedWhatsappAccount)}
        </div>
      ) : (
        <></>
      )}

      <div className="mb-6 font-normal">
        <span>&lt;&lt;{formatPhoneNumber(item?.parent?.mobile)}&gt;&gt;</span>
        &nbsp;
        <span>
          &lt;&lt;
          {formatSalutation(item?.parent?.salutation, item?.parent?.fullName)}
          &gt;&gt;
        </span>
        &nbsp;/&nbsp;
        <span>
          &lt;&lt;
          {item?.students?.map((student) => student.fullName)?.join(", ")}
          &gt;&gt;
        </span>
      </div>
      <div
        className="mb-6 font-normal"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatTemplateBr(
            prevWhatsAppMessage(
              formatTextBoldFromWhatsAppFormatToEmailFormat(
                formatVariableName(item.whatsappMessage, false)
              )
            )
          ),
        }}
      />
      <Button icon={<IconCopy className="mr-2" />} onClick={onCopy}>
        Copy Message
      </Button>
    </div>
  );
};

export default memo(WhatsappMessage);

import { createRoot } from "react-dom/client";
import Helmet from "react-helmet";
import Package from "../package.json";
import { actions } from "./MicroAppStateActions";
import {
  AppConfig,
  Auth0Config,
  IsQiankunPrefetch,
  SentryConfig,
  SystemUserBaseUrl,
} from "./config";
import * as serviceWorker from "./serviceWorker";

/** do not delete the line between the style file
 *  to avoid the order of the style files be changed */
import "./assets/style/tailwind.css";

import "./theme.less";

import { message } from "@thepiquelab/archus-components-web";
import { AxiosClient } from "@thepiquelab/web-api";
import Auth0Provider from "@thepiquelab/web-auth0";
import Sentry, { ErrorBoundary } from "@thepiquelab/web-sentry";
import { prefetchApps } from "qiankun";
import App from "./App";
import Loading from "./components/common/Loading";
import "./index.scss";
import "./tailwind.generate.css";

const showOrganizationTitle = (type: string): string => {
  switch (type) {
    case "TPL":
      return "The Pique Lab | Archus School Management System";
    case "Pilab":
      return "The Pi Lab | Archus School Management System";
    default:
      return "The Pique Lab | Archus School Management System";
  }
};

const title = showOrganizationTitle(AppConfig.OrganizationName);

if (!IsQiankunPrefetch) {
  prefetchApps([
    {
      name: "archus-administration",
      entry: SystemUserBaseUrl,
    },
  ]);
}

actions.onGlobalStateChange((state, prev) => {
  // console.info("onGlobalStateChange: ", state, prev);
});

Sentry.init({
  dsn: SentryConfig.DSN,
  tracesSampleRate: SentryConfig.tracesSampleRate,
  release: `${Package.name}@${Package.version}`,
});

AxiosClient.init();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <Auth0Provider
      {...Auth0Config}
      loadingNode={<Loading />}
      message={message}
      qkActions={actions}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <App />
    </Auth0Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {
  GetStudentsForImportRegistration,
  GetStudentsForImportRegistrationQuery,
  GetStudentsForImportRegistrationQueryVariables,
  StudentBaseFieldsFragment,
  StudentFilterInput,
} from "@/graphql";
import { useLazyQuery } from "@apollo/client";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  EnumImportRegistrationTempTitle,
  ImportRegistrationRecord,
} from "./Config";

export const useStudentListToValidate = (
  registrationData: ImportRegistrationRecord[]
): {
  studentList: StudentBaseFieldsFragment[];
  mergeStudentListUniq: (students: StudentBaseFieldsFragment[]) => void;
  clearStudentList: () => void;
  queryStudents: (
    filterInput: StudentFilterInput
  ) => Promise<StudentBaseFieldsFragment[]>;
  queryInitialStudents: () => Promise<void>;
  loading: boolean;
} => {
  const [studentList, setStudentList] = useState<StudentBaseFieldsFragment[]>(
    []
  );
  const [queryStudents, { loading }] = useLazyQuery<
    GetStudentsForImportRegistrationQuery,
    GetStudentsForImportRegistrationQueryVariables
  >(GetStudentsForImportRegistration, {
    fetchPolicy: "no-cache",
  });

  const clearStudentList = (): void => {
    setStudentList([]);
  };

  const mergeStudentListUniq = (
    students: StudentBaseFieldsFragment[]
  ): void => {
    setStudentList((prevStudentList) =>
      uniqBy([...students, ...prevStudentList], "id")
    );
  };

  const handleQueryStudents = async (
    filterInput: StudentFilterInput
  ): Promise<StudentBaseFieldsFragment[]> => {
    const res = await queryStudents({
      variables: {
        filterInput,
        pageInfo: {
          pageIndex: 0,
          pageSize: 0,
        },
      },
    });

    return res?.data?.students?.items || [];
  };

  const queryInitialStudents = async (): Promise<void> => {
    let students: StudentBaseFieldsFragment[] = [];
    const studentNames = registrationData
      .map(
        (registration) =>
          registration[EnumImportRegistrationTempTitle.StudentName]
      )
      .filter(Boolean);
    const userIds = registrationData
      .map(
        (registration) =>
          registration[EnumImportRegistrationTempTitle.StudentID]
      )
      .filter(Boolean);
    if (studentNames.length) {
      const dataWithStudentName = await handleQueryStudents({
        studentNames,
      });
      const studentWithStudentName = dataWithStudentName || [];
      // mergeStudentListUniq([...studentWithStudentName]);
      students = [...studentWithStudentName, ...students];
    }

    if (userIds?.length) {
      const dataWithStudentId = await handleQueryStudents({
        userIds,
      });
      const studentWithStudentId = dataWithStudentId || [];
      students = [...studentWithStudentId, ...students];
    }
    mergeStudentListUniq([...students]);
  };

  return {
    studentList,
    mergeStudentListUniq,
    clearStudentList,
    queryStudents: handleQueryStudents,
    queryInitialStudents,
    loading,
  };
};

import {
  Col,
  DatePicker,
  Input,
  Row,
} from "@thepiquelab/archus-components-web";
import moment, { Moment } from "@thepiquelab/web-moment";
import React, { useEffect, useState } from "react";
import StartDateCalendar, {
  Props,
} from "../RegularModal/Calendar/StartDateCalendar";

interface ReserveProp extends Props {
  registrationStartDate: Date;
  registrationDueDate?: Date;
  onTextAreaChange: (v: string) => void;
  onDueDateChange: (v: Date) => void;
  hideRemarks?: boolean;
}

const ReserveModal: React.FC<ReserveProp> = ({
  registrationStartDate,
  registrationDueDate,
  availableDates,
  onSelect,
  onDueDateChange,
  onTextAreaChange,
  hideRemarks,
}) => {
  const [dueDate, setDueDate] = useState(
    registrationDueDate
      ? moment(registrationDueDate)
      : moment(new Date()).add(7, "day")
  );

  useEffect(() => {
    onDatePickerChange(
      registrationDueDate
        ? moment(registrationDueDate)
        : moment(new Date()).add(7, "day")
    );
  }, []);

  const onDatePickerChange = (date: Moment): void => {
    setDueDate(date);
    onDueDateChange(date?.toDate());
  };

  const onTextAreaAlter = (v: any): void => {
    onTextAreaChange(v.target.value);
  };

  return (
    <div data-testid="ReserveModal">
      <Row>
        <StartDateCalendar
          registrationStartDate={registrationStartDate}
          availableDates={availableDates}
          onSelect={onSelect}
        />
      </Row>
      <Row className={"mt-4 mb-2 font-bold"}>Due Date</Row>
      <Row align={"middle"} gutter={20}>
        <Col>
          <DatePicker
            value={dueDate}
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            onChange={onDatePickerChange}
            showToday={false}
          />
        </Col>
      </Row>

      {!hideRemarks && (
        <>
          <Row className={"mt-6 mb-2 font-bold"}>Remarks</Row>
          <Row>
            <Input.TextArea rows={2} allowClear onChange={onTextAreaAlter} />
          </Row>
        </>
      )}
    </div>
  );
};

export default ReserveModal;

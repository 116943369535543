import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import BalanceHistoryLink from "@/components/widget/CommonLink/BalanceHistoryLink";
import { TaskIndexFieldsFragment, TaskStatus, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Typography } from "@thepiquelab/archus-components-web";
import { formatCurrency } from "@thepiquelab/phonenumber";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const FollowUpFeeBalance: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Current Balance"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value: string, record: TaskIndexFieldsFragment) {
        const balance = record?.balance?.balance;
        if ([TaskStatus?.Complete, TaskStatus?.Void].includes(record?.status)) {
          return <>-</>;
        }
        return (
          <Typography.Heading level={4}>
            <BalanceHistoryLink
              id={record?.balance?.id}
              parentId={record?.parent?.id}
            >
              {balance && formatCurrency(balance)}
            </BalanceHistoryLink>
          </Typography.Heading>
        );
      },
    },
  ];
  if (!visible) {
    return <></>;
  }
  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        pagination={{
          ...pagination,
        }}
        columns={columns}
        title={formatLabel("Fee Balance Follow Up")}
        taskType={TaskType.FollowUpFeeBalance}
        callback={callback}
      />
    </div>
  );
};

export default FollowUpFeeBalance;

import AuthorizedByPermission, {
  EnumAuthHandleType,
  IAuthorizedPermissions,
} from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { IsProduction } from "@/config";
import { useCheckTaxRateForCurrentYear } from "@/hooks/useCheckTaxRateForCurrentYear";
import { Dropdown, Menu } from "@thepiquelab/archus-components-web";
import { ItemType } from "antd/es/menu/interface";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconPlusSquareOuter } from "../../../assets/images/IconPlusSquareOuter.svg";
import Notification from "./Notification";

interface MenuBarProp {
  collapse: boolean;
  children: React.ReactNode;
}
export type IMenuItemTypeAuthed = ItemType | IAuthorizedPermissions;
const MenuBar: React.FC<MenuBarProp> = ({ collapse, children }) => {
  const navigate = useNavigate();

  const { handleCheckTaxRate } = useCheckTaxRateForCurrentYear();

  const menuItems: IMenuItemTypeAuthed[] = [
    {
      label: <span>Register a Student</span>,
      key: "/class-arrangement/registration?click",
      permissions: [EnumPermissions.REGISTRATION_UPDATE],
    },
    {
      label: <span>Arrange Replacement Lesson</span>,
      key: "/class-arrangement/makeup?click",
      permissions: [EnumPermissions.ARRANGEMENT_UPDATE],
    },
    {
      label: <span>Transfer a Student</span>,
      key: "/class-arrangement/transfer?click",
      permissions: [EnumPermissions.ARRANGEMENT_UPDATE],
    },
    {
      label: <span>Withdraw a Student</span>,
      key: "/class-arrangement/withdraw?click",
      permissions: [EnumPermissions.ARRANGEMENT_UPDATE],
    },
  ];
  const flexDirection = collapse ? "flex-col" : "flex-row";
  return (
    <div
      data-testid="MenuBarComponent"
      className={`flex ${flexDirection} bg-white`}
    >
      <div
        className={`flex ${flexDirection} items-center content-center flex-wrap justify-start gap-2`}
      >
        {!IsProduction && (
          <div key="notification" className="flex">
            <Notification iconClass={collapse ? "w-5 h-5" : ""} />
          </div>
        )}

        {!IsProduction && (
          <Dropdown
            trigger={["click"]}
            key="dropdown"
            overlay={
              <AuthorizedByPermission authHandleType={EnumAuthHandleType.HIDE}>
                <Menu
                  key="dropdown"
                  onClick={({ key }) => {
                    if (key === "/class-arrangement/makeup?click") {
                      navigate(key);
                      return;
                    }

                    if (!handleCheckTaxRate()) {
                      return;
                    }
                    navigate(key);
                  }}
                  items={menuItems as ItemType[]}
                  inlineCollapsed={false}
                  mode="inline"
                  // _internalDisableMenuItemTitleTooltip
                />
              </AuthorizedByPermission>
            }
            arrow={{ pointAtCenter: true }}
            overlayClassName="w-60"
          >
            <IconPlusSquareOuter
              className={`cursor-pointer ${collapse ? "w-5 h-5" : "w-4 h-4"}`}
            />
          </Dropdown>
        )}
        {children}
      </div>
    </div>
  );
};

export default MenuBar;

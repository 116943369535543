import { Auth0Config } from "@/config";
import { ErrorScreen } from "@thepiquelab/archus-components-web";
import { useAuth0Client } from "@thepiquelab/web-auth0";
import InsufficientCredentials from "../../assets/images/InsufficientCredentials.svg";

function InsufficientCredentialsPage() {
  const { logout } = useAuth0Client();
  return (
    <ErrorScreen
      logo=""
      title="Error"
      description="We are sorry, but you do not have access to this page."
      primaryButtonLabel="Return to Login"
      onPressPrimaryButton={() => {
        logout({
          logoutParams: {
            returnTo: Auth0Config.authorizationParams.redirect_uri,
          },
        });
      }}
      illustration={InsufficientCredentials}
    />
  );
}

export default InsufficientCredentialsPage;

import UserSettings from "@/components/settings/UserSettings/UserSettings";
import { SecurityScanFilled, SettingFilled } from "@ant-design/icons";
import { Menu, Modal } from "@thepiquelab/archus-components-web";
import { useAuth0Client } from "@thepiquelab/web-auth0";
import React, { useState } from "react";

const { Item } = Menu;

interface MenuBarDropdownProps {
  onDropdownVisible?: (visible: boolean) => void;
}

const MenuBarDropdown: React.FC<MenuBarDropdownProps> = (props) => {
  const { onDropdownVisible } = props;
  const { logout } = useAuth0Client();

  const [isShowUserSetting, setIsShowUserSetting] = useState<boolean>(false);

  const handleChangeModalVisible = (visible: boolean): void => {
    if (visible && onDropdownVisible) {
      onDropdownVisible(false);
    }

    setIsShowUserSetting(visible);
  };

  return (
    <Menu
      className="setting-menu shadow bg-white rounded-default"
      selectable={false}
    >
      <Item key="/settings/user" onClick={() => handleChangeModalVisible(true)}>
        <div className="flex items-center gap-2">
          <SettingFilled />
          <div className="flex items-center">User Settings</div>
        </div>
      </Item>
      <Item
        key="/logout"
        onClick={() =>
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
        }
      >
        <div className="flex items-center gap-2">
          <SecurityScanFilled />
          <div className="flex items-center">Log Out</div>
        </div>
      </Item>
      <Modal
        visible={isShowUserSetting}
        onCancel={() => handleChangeModalVisible(false)}
        title="Profile Settings"
        width="520px"
        footer={null}
      >
        <UserSettings />
      </Modal>
    </Menu>
  );
};

export default MenuBarDropdown;

import {
  ClassFilterInput,
  GetClassesForImportRegistration,
  GetClassesForImportRegistrationQuery,
  GetClassesForImportRegistrationQueryVariables,
} from "@/graphql";
import { useLazyQuery } from "@apollo/client";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  EnumImportRegistrationTempTitle,
  ImportRegistrationRecord,
} from "./Config";

export type ClassForValidate =
  GetClassesForImportRegistrationQuery["classes"]["items"][0];
export const useClassListToValidate = (
  registrationData: ImportRegistrationRecord[]
): {
  classList: ClassForValidate[];
  mergeClassListUniq: (classes: ClassForValidate[]) => void;
  clearClassList: () => void;
  queryClasses: (filterInput: ClassFilterInput) => Promise<ClassForValidate[]>;
  queryInitialClasses: () => Promise<void>;
  loading: boolean;
} => {
  const [classList, setClassList] = useState<ClassForValidate[]>([]);
  const [queryClasses, { loading }] = useLazyQuery<
    GetClassesForImportRegistrationQuery,
    GetClassesForImportRegistrationQueryVariables
  >(GetClassesForImportRegistration, {
    fetchPolicy: "no-cache",
  });

  const clearClassList = () => {
    setClassList([]);
  };

  const mergeClassListUniq = (classes: ClassForValidate[]) => {
    setClassList((prevStudentList) =>
      uniqBy([...classes, ...prevStudentList], "id")
    );
  };

  const handleQueryClasses = async (
    filterInput: ClassFilterInput
  ): Promise<ClassForValidate[]> => {
    const res = await queryClasses({
      variables: {
        filterInput,
        pageInfo: {
          pageIndex: 0,
          pageSize: 0,
        },
      },
    });
    return res.data?.classes?.items;
  };

  const queryInitialClasses = async (): Promise<void> => {
    const classCodes = registrationData
      .map(
        (registration) =>
          registration[EnumImportRegistrationTempTitle.ClassCode]
      )
      .filter(Boolean);
    if (!classCodes?.length) return;

    const dataWithClassCodes = await handleQueryClasses({
      classCodes,
    });

    const studentWithStudentId = dataWithClassCodes || [];
    mergeClassListUniq(studentWithStudentId);
  };

  return {
    classList,
    mergeClassListUniq,
    clearClassList,
    queryClasses: handleQueryClasses,
    queryInitialClasses,
    loading,
  };
};

import {
  useBulkCreateHistoryHistory,
  useCompleteImportRegistrations,
} from "@/components/registration/ImportRegistration/hooks";
import {
  BulkCreateHistory,
  BulkCreateHistoryStatus,
  BulkCreateHistoryType,
  ExportFailedImportRegistrations,
  ExportFailedImportRegistrationsMutation,
  ExportFailedImportRegistrationsMutationVariables,
  PostImportRegistrationHistory,
  PostImportRegistrationHistorySubscription,
  PostImportRegistrationHistorySubscriptionVariables,
} from "@/graphql";
import { useExportCsvMutation } from "@/hooks/useExportCsv";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { useSubscription } from "@apollo/client";
import {
  ArchusAlert as Alert,
  Spin,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { useDBUser } from "@thepiquelab/web-auth0";
import { FC, memo, useContext, useEffect, useMemo } from "react";
import { RefreshListRegistrationsContext } from "./Context";

const messageKey = "import registration";

const formatAbsNumber = (total: number): number => (total > 0 ? total : 0);

const ImportRegistrationNotification: FC = () => {
  const { dbUser } = useDBUser();

  const { data } = useBulkCreateHistoryHistory(
    BulkCreateHistoryType.Registration
  );

  const { setIsRefresh } = useContext(RefreshListRegistrationsContext);

  const { data: subscriptionData } = useSubscription<
    PostImportRegistrationHistorySubscription,
    PostImportRegistrationHistorySubscriptionVariables
  >(PostImportRegistrationHistory);

  const { exportCsv, exportLoading } = useExportCsvMutation<
    ExportFailedImportRegistrationsMutation,
    ExportFailedImportRegistrationsMutationVariables
  >(ExportFailedImportRegistrations);

  const [completeImportRegistrations] = useCompleteImportRegistrations();

  const res = useMemo(
    () =>
      subscriptionData?.postImportRegistrationHistory ||
      data?.bulkCreateHistory,
    [data, subscriptionData]
  );

  useEffect(() => {
    if (
      res?.status === BulkCreateHistoryStatus.ToBeConfirmed &&
      Number(res?.total) - Number(res?.failed) > 0
    ) {
      setIsRefresh(true);
    }
  }, [res]);

  const succeeded = useMemo(
    () => formatAbsNumber(Number(res?.processed) - Number(res?.failed)),
    [res]
  );

  const onClose = async (callback?: () => void): Promise<void> => {
    if (!res?.id) {
      return;
    }
    if (!res?.isExportFailedDocuments && res?.failed) {
      message.warning(
        "Please download the list of failed registration to dismiss this notification."
      );
      return;
    }
    try {
      await completeImportRegistrations({
        variables: {
          id: res.id,
        },
        refetchQueries: [
          {
            query: BulkCreateHistory,
            variables: {
              type: BulkCreateHistoryType.Registration,
            },
          },
        ],
      });
      callback();
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  const onExport = (): void => {
    if (!res?.id) {
      return;
    }
    try {
      exportCsv({
        id: res.id,
      });
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  return (
    <div className="h-maxcontent w-full">
      <Spin spinning={exportLoading}>
        {res?.status === BulkCreateHistoryStatus.Processing && (
          <Alert
            icon
            type="info"
            description={
              <div>
                Registering Students:{" "}
                <Typography.Text className="font-semibold">
                  {res?.processed}
                </Typography.Text>{" "}
                of{" "}
                <Typography.Text className="font-semibold">
                  {res?.total}
                </Typography.Text>{" "}
                processed.{" "}
                <Typography.Text className="font-semibold">
                  {res?.failed}
                </Typography.Text>{" "}
                failed.{" "}
                <Typography.Text className="font-semibold">
                  {succeeded}
                </Typography.Text>{" "}
                succeeded.
              </div>
            }
          />
        )}

        {res?.status === BulkCreateHistoryStatus.ToBeConfirmed && (
          <Alert
            icon
            type="info"
            description={
              <div>
                Import Complete!{" "}
                <Typography.Text className="font-semibold">
                  {formatAbsNumber(Number(res?.total) - Number(res?.failed))}
                </Typography.Text>{" "}
                of{" "}
                <Typography.Text className="font-semibold">
                  {res?.total}
                </Typography.Text>{" "}
                registration successfully imported.{" "}
                {Number(res?.failed) === 0 ? (
                  <></>
                ) : (
                  <span>
                    <span
                      className="text-primary-blue font-semibold cursor-pointer"
                      onClick={onExport}
                    >
                      Click here
                    </span>{" "}
                    to Download Failed Registrations.
                  </span>
                )}
              </div>
            }
            onClose={dbUser?._id === res?.createdBy?.id ? onClose : null}
          />
        )}
      </Spin>
    </div>
  );
};

export default memo(ImportRegistrationNotification);

import { AppConfig } from "@/config";
import {
  Button,
  generateNotificationConfig,
  notification,
} from "@thepiquelab/archus-components-web";

import { actions } from "@/MicroAppStateActions";
import { CsdControllerApi } from "@thepiquelab/web-api";
import { useAuth0Client } from "@thepiquelab/web-auth0";
import useEventSource from "@thepiquelab/web-sse";
import { DeviceStatus } from "@thepiquelab/web-types";
import { useNavigate } from "react-router-dom";

const useDeviceEventSource = () => {
  const { getToken } = useAuth0Client();
  const navigate = useNavigate();

  const { init } = useEventSource();

  const initEventSource = async () => {
    const authorization = await getToken(actions);
    const url = `${AppConfig.CsdControllerServiceUrl}/devices/listener`;

    init({
      url,
      authorization,
      onMessage(e) {
        const data: string = e?.data || "";

        if (data?.includes(`"status":"DISCONNECTED"`)) {
          const timeoutId = setTimeout(async () => {
            const room = JSON.parse(data);

            const device = await CsdControllerApi.getDevice(room._id);

            if (device.status === DeviceStatus.Disconnected) {
              const csdNodes = document.getElementsByClassName(
                "csd-controller-notification"
              );
              if (csdNodes?.length > 0) return;

              const key = `open${Date.now()}`;

              notification.open({
                ...generateNotificationConfig({
                  type: "warning",
                  title: "Class Schedule Display App Disconnected!",
                  description: "Class Schedule Display App has disconnected!",
                }),
                key,
                duration: null,
                className: "csd-controller-notification",
                btn: (
                  <Button
                    type="primary"
                    onClick={() => {
                      notification.destroy(key);
                      navigate(`/settings/devices`);
                    }}
                  >
                    Go Check
                  </Button>
                ),
              });
            }

            clearTimeout(timeoutId);
          }, 300000);
        }
      },
    });
  };

  return {
    initEventSource,
  };
};

export default useDeviceEventSource;

import formatErrorMessage from "@/utils/formatErrorMessage";
import { useQuery } from "@apollo/client";
import { DatePicker, message } from "@thepiquelab/archus-components-web";
import moment, { Moment } from "@thepiquelab/web-moment";
import React from "react";
import {
  GetInvoiceDueDateData,
  GetInvoiceDueDateDataQuery,
  GetInvoiceDueDateDataQueryVariables,
} from "../../../../graphql";
import { useUpdateInvoiceDueDate } from "../../../finance/FinanceHooks";

interface InvoiceDueDateProps {
  invoiceId: string;
  className?: string;
  callback?: () => void;
  dueDateDisabled?: boolean;
}

const InvoiceDueDate: React.FC<InvoiceDueDateProps> = (props) => {
  const { invoiceId, dueDateDisabled = false, className, callback } = props;
  const { data } = useQuery<
    GetInvoiceDueDateDataQuery,
    GetInvoiceDueDateDataQueryVariables
  >(GetInvoiceDueDateData, { variables: { invoiceId }, skip: !invoiceId });
  const [updateInvoiceDueDate] = useUpdateInvoiceDueDate();
  const handleDateChange = async (date: Moment): Promise<void> => {
    try {
      await updateInvoiceDueDate({
        variables: {
          updateInvoiceInput: {
            id: invoiceId,
            dueDate: date?.toDate() ?? null,
          },
        },
      });
      callback?.();
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };
  return (
    <div className={`flex items-center ${className}`}>
      <span className={"mr-2"}>Due on:</span>
      <DatePicker
        disabled={dueDateDisabled}
        value={data?.invoice?.dueDate && moment(data?.invoice?.dueDate)}
        format={"D MMM YYYY"}
        getPopupContainer={(triggerNode: any) => triggerNode}
        showTime={{ minuteStep: 30, use12Hours: true, format: "HH:mm" }}
        showNow={false}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default InvoiceDueDate;

import { CommonCacheContext } from "@/components/common/CacheProvider";
import { ReferenceDataCategory } from "@/graphql";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { isNil } from "lodash";
import { useContext, useMemo } from "react";

export interface ReferenceData {
  category: string;
  key: string;
  value: string;
  countryCode?: string;
}

export class MapWithDefault<K, V> extends Map {
  constructor(private defaultValue?: any) {
    super();
  }

  get(key: K): V | undefined {
    if (isNil(key)) {
      return null;
    }
    if (super.get(key)) return super.get(key);
    return this.defaultValue;
  }
}

const useReferenceDataMap = (
  category: ReferenceDataCategory,
  showArchived = false,
  fetchPolicy: WatchQueryFetchPolicy = "cache-first"
): MapWithDefault<string, string> => {
  const { referenceData } = useContext(CommonCacheContext);

  return useMemo(() => {
    const transformed = new MapWithDefault<string, string>("");
    referenceData
      ?.filter((item) => item.category === category)
      ?.filter((item) => showArchived || !item.isArchived)
      ?.forEach((reference) => {
        if (
          category === ReferenceDataCategory.Salutation &&
          reference.key === "undisclosed"
        ) {
          transformed.set(reference.key, "");
        } else {
          transformed.set(reference.key, reference.value);
        }
      });

    return transformed;
  }, [referenceData, category]);
};

const useSalutation = (): {
  formatSalutation: (salutation: string, name: string) => string;
} => {
  const salutationMap = useReferenceDataMap(ReferenceDataCategory.Salutation);
  const formatSalutation = (salutation: string, name: string): string => {
    if (name) {
      if (salutationMap.get(salutation)) {
        return `${salutationMap.get(salutation)} ${name}`;
      }
      return name;
    }
    return "";
  };
  return { formatSalutation };
};

export { useReferenceDataMap, useSalutation };

import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import { InvoiceStatusMap } from "@/components/finance/Modules/InvoiceDetail/InvoiceDetailCard";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import RegistrationLink from "@/components/widget/CommonLink/RegistrationLink";
import { InvoiceStatus, TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { Typography } from "@thepiquelab/archus-components-web";
import { formatDate } from "@thepiquelab/web-moment";
import React from "react";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const ReminderTaskTrialLesson: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        const { id: classId, shorthand } = enrollment?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Enrollment No."),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        const registration = enrollment?.registration;

        return registration?.id ? (
          <div>
            <RegistrationLink id={registration?.id}>
              {registration?.name}
            </RegistrationLink>
          </div>
        ) : (
          <>-</>
        );
      },
    },

    {
      title: formatLabel("Payment Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        let invoices = enrollment?.invoices || [];
        if (!invoices?.length) {
          return <>-</>;
        }
        invoices = invoices?.filter(
          (invoice) => invoice.status !== InvoiceStatus.Void
        );
        return invoices.map((invoice) => (
          <div>
            <Typography.Text
              size="middle"
              style={{ color: InvoiceStatusMap[invoice.status]?.color }}
            >
              {`${invoice.status}`}
            </Typography.Text>
          </div>
        ));
      },
    },

    {
      title: formatLabel("Trial Dates"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;

        return enrollment?.attendances?.map((a) => (
          <div>{formatDate(a.startDateTime, { format: "SHORT" })}</div>
        ));
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Trial Lesson Reminder")}
        taskType={TaskType.ReminderTrialLesson}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReminderTaskTrialLesson;

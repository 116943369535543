import {
  Row,
  Select,
  SelectProps,
  Spin,
} from "@thepiquelab/archus-components-web";
import { PageResult, SearchSelectQuery } from "@thepiquelab/web-types";
import { useDebounceFn, usePagination } from "ahooks";
import { UIEvent, useEffect, useState } from "react";

const { Option } = Select;

async function fetchOptions<T, K extends keyof T>(
  request: (query: SearchSelectQuery) => Promise<PageResult<T>>,
  searchValue: string,
  page: number,
  fieldLabel: K,
  fieldValue: K
): Promise<{
  list: { label: T[K]; value: T[K]; original: T }[];
  total: number;
  current: number;
}> {
  const res = await request({
    pageIndex: page,
    name: searchValue,
    pageSize: 20,
  });

  return {
    list: res.items?.map((item) => ({
      label: item[fieldLabel],
      value: item[fieldValue],
      original: item,
    })),
    total: res.total,
    current: res.pageIndex,
  };
}

interface Props<T, K extends keyof T> extends SelectProps {
  request: (query: SearchSelectQuery) => Promise<PageResult<T>>;
  fieldLabel: K;
  fieldValue: K;
  onChange?: (value: string) => void;
}

function RemoteSelect<T>(props: Props<T, keyof T>): JSX.Element {
  const { request, fieldLabel, fieldValue, value, onChange, ...rest } = props;
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(value);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
    total: 0,
  });

  const { loading, run } = usePagination(
    ({ current }) =>
      fetchOptions(request, searchValue, current, fieldLabel, fieldValue),
    {
      onSuccess: (result) => {
        setList((e) => [...e, ...result.list]);
        setPagination({
          ...pagination,
          current: result.current,
          total: result.total,
        });
      },
      defaultPageSize: 20,
      manual: true,
    }
  );

  const Loader = (
    <Row justify={"center"} key={0}>
      <Spin size="small" />
    </Row>
  );

  const { run: handleSearch } = useDebounceFn(
    (value) => {
      setList([]);
      setSearchValue(value);
    },
    { wait: 300 }
  );

  const handleChange = (value: any) => {
    setSelectedValue(value);
    onChange(value);
  };

  useEffect(() => {
    run({ current: 0, pageSize: pagination.pageSize });
  }, [searchValue]);

  return (
    <Select
      {...rest}
      showSearch
      value={selectedValue}
      notFoundContent={loading ? Loader : null}
      filterOption={false}
      onSearch={handleSearch}
      dropdownRender={(menu) => (
        <>
          {menu} {loading && list.length ? Loader : null}
        </>
      )}
      labelInValue
      onChange={handleChange}
      onPopupScroll={(e: UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        if (
          target.scrollTop + target.offsetHeight > target.scrollHeight - 10 &&
          !loading
        ) {
          if (pagination.current * pagination.pageSize < pagination.total) {
            run({
              current: pagination.current + 1,
              pageSize: pagination.pageSize,
            });
          }
        }
      }}
      style={{ width: "100%" }}
    >
      {list.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
}

export default RemoteSelect;

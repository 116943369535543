import { ReactComponent as IconFilter } from "@/assets/images/IconFilter.svg";
import { ReactComponent as OverViewIcon } from "@/assets/images/IconOverview.svg";

import { SortDirection, TaskStatus, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Input,
  Menu,
  Space,
  ArchusSwitch as Switch,
  Tabs,
} from "@thepiquelab/archus-components-web";
import moment, { Dayjs, Moment } from "@thepiquelab/web-moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataPageHeader from "../common/DataPage/DataPageHeader";
import FollowUpFeeBalance from "./Task/FollowUpFeeBalance";
import FollowUpReplacementLessonOffer from "./Task/FollowUpReplacementLessonOffer";
import AbsentFollowUpTask from "./Task/FollowUpTaskAbsent";
import PaymentFollowUpTask from "./Task/FollowUpTaskPayment";
import ReservationFollowUpTask from "./Task/FollowUpTaskReservation";
import TrialFollowUpTask from "./Task/FollowUpTaskTrial";
import OperationalTaskList from "./Task/OperationalTasks/OperationalTaskList";
import ReminderTaskAdditionalLesson from "./Task/ReminderTaskAdditionalLesson";
import ReminderTaskAdditionalLessonOffer from "./Task/ReminderTaskAdditionalLessonOffer";
import ReminderTaskClassArrangementChange from "./Task/ReminderTaskClassArrangementChange";
import ReminderTaskLesson from "./Task/ReminderTaskLesson";
import ReminderTaskLessonUnderHoliday from "./Task/ReminderTaskLessonUnderHoliday";
import ReminderTaskTrialLesson from "./Task/ReminderTaskTrialLesson";
import ReminderTaskWorkshopRL from "./Task/ReminderTaskWorkshopRL";
import { useQueryTasks } from "./Task/hooks";
import { TaskInnerFilterType, TaskTabType } from "./Task/types";

import { IsProduction } from "@/config";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import TermBillingFollowUpTask from "./Task/FollowUpTaskTermBilling";
import FollowUpTaskTrialRequest from "./Task/FollowUpTaskTrialRequest";
import TaskFilter from "./Task/TaskFilter";
import "./index.scss";
import { QueryTaskIndexProps } from "./index.utils";

const TabNames = [
  {
    title: "Follow Up Tasks",
    key: TaskTabType.FollowUpTasks,
    taskTypes: [
      { value: TaskType.FollowUpAbsent, label: "Absent Follow Up" },
      { value: TaskType.FollowUpFeeBalance, label: "Fee Balance Follow Up" },
      {
        value: TaskType.FollowUpTermBilling,
        label: "GAP Term Billing Follow Up",
      },
      { value: TaskType.FollowUpPayment, label: "Payment Follow Up" },
      {
        value: TaskType.FollowUpReplacementLessonOffer,
        label: "Replacement Lesson Offer Follow Up",
      },
      {
        value: TaskType.FollowUpReservation,
        label: "Reservation Follow Up",
      },
      { value: TaskType.FollowUpTrial, label: "Trial Follow Up" },

      !IsProduction && {
        value: TaskType.FollowUpTrialRequest,
        label: "Trial Request Follow Up",
      },
    ],
  },
  {
    title: "Reminder Tasks",
    key: TaskTabType.ReminderTasks,
    taskTypes: [
      {
        value: TaskType.ReminderAdditionalLesson,
        label: "Additional Lesson Reminder",
      },
      {
        value: TaskType.ReminderAdditionalLessonOffer,
        label: "Additional Lesson Offer Reminder",
      },
      {
        value: TaskType.ReminderChangeClass,
        label: "Change in Teacher/Venue/Learning Arrangement Reminder",
      },
      { value: TaskType.ReminderLesson, label: "Lesson Reminder" },
      {
        value: TaskType.ReminderLessonUnderHoliday,
        label: "Lesson Under PH Reminder",
      },
      {
        value: TaskType.ReminderTrialLesson,
        label: "Trial Lesson Reminder",
      },
      {
        value: TaskType.ReminderWorkshopReplacementLesson,
        label: "Workshop Replacement Lesson Reminder ",
      },
    ],
  },
  {
    title: "Operational Tasks",
    key: TaskTabType.OperationalTasks,
    taskTypes: [
      {
        value: TaskType.Operational,
        label: "Operational",
      },
    ],
  },
];

const DashboardPage: React.FC = () => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [sorter, setSorter] = useState<QueryTaskIndexProps["sorter"]>();
  const [selectedTaskType, setSelectedTaskType] = useState<TaskType>(
    TaskType.FollowUpTrial
  );
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/dashboard/operational") {
      setSelectedTaskType(TaskType.Operational);
    }
  }, [pathname]);

  const [keyword, setKeyword] = useState<string>(null);
  const { state } = useLocation();
  const [filterInput, setFilterInput] = useState<{
    dueDateRange: [Dayjs, Dayjs];
    lastModifiedDateRange?: [Dayjs, Dayjs];
    status?: TaskStatus[];
    whatsappAccountId?: string;
    isWithoutWAline?: boolean;
  }>({
    dueDateRange: [
      moment("2023-12-1").startOf("day").toDayjs(),
      moment().endOf("D").toDayjs(),
    ],
    status: [TaskStatus.Overdue, TaskStatus.Pending],
  });
  const [innerFilterInput, setInnerFilterInput] = useState<TaskInnerFilterType>(
    {}
  );

  const {
    getPendingOverdueTaskCount,
    fetchIndexTasks,
    loading,
    refetch: refetchTasks,
    data,
    count,
    allCount,
  } = useQueryTasks();

  const navigate = useNavigate();

  const tasks = data?.tasksWithIndex?.items;
  const pagination: { pageIndex: number; pageSize: number; total: number } = {
    pageIndex: data?.tasksWithIndex?.pageIndex,
    pageSize: data?.tasksWithIndex?.pageSize,
    total: data?.tasksWithIndex?.total,
  };

  const queryIndexTasks = (option?: QueryTaskIndexProps): void => {
    const pageIndex = option?.pagination?.pageIndex
      ? option?.pagination?.pageIndex - 1
      : 0;
    const pageSize = option?.pagination?.pageSize;
    const sortKey = option?.sorter?.sortKey;
    const order = option?.sorter?.sort;

    setSorter(option?.sorter);

    let sortDirection = null;
    if (order === "ascend") {
      sortDirection = SortDirection.Asc;
    } else if (order === "descend") {
      sortDirection = SortDirection.Desc;
    }

    fetchIndexTasks({
      dueDateRange: filterInput?.dueDateRange?.map((d) => moment(d)) as [
        Moment,
        Moment,
      ],
      lastModifiedDateRange: filterInput?.lastModifiedDateRange?.map((d) =>
        moment(d)
      ) as [Moment, Moment],
      whatsappAccountIds: filterInput?.whatsappAccountId
        ? [filterInput?.whatsappAccountId]
        : [],
      taskTypes: [selectedTaskType],
      taskStatus: filterInput?.status,
      isWithoutWAline: filterInput?.isWithoutWAline,
      ...innerFilterInput,
      pageIndex,
      pageSize,
      sortKey,
      sortDirection,
      keyword,
    });
  };

  useEffect(() => {
    if (selectedTaskType !== TaskType.Operational) {
      queryIndexTasks();
    }
  }, [selectedTaskType, filterInput, keyword, innerFilterInput]);

  useEffect(() => {
    setInnerFilterInput({});
  }, [selectedTaskType]);

  useEffect(() => {
    if (selectedTaskType !== TaskType.Operational) {
      queryIndexTasks();
    }
  }, []);

  /**
   * focus on specific task grid.
   */
  useEffect(() => {
    if (state?.taskType) {
      setSelectedTaskType(state?.taskType);
      setSorter({});
    }
  }, [state?.taskType]);

  return (
    <Container data-testid="DashboardPage">
      <Container.Header>
        <DataPageHeader
          title={"Dashboard"}
          description={
            <div className="flex gap-2">
              <Badge
                color="#5B9DFF"
                text={`Pending: ${allCount?.pending || 0} `}
              />
              <Badge
                color="#F36B7F"
                text={`Overdue: ${allCount?.overdue || 0}`}
              />
            </div>
          }
          rightBar={
            <Button
              className={`btn  flex-shrink-0`}
              onClick={() => {
                setFilterVisible(!filterVisible);
              }}
            >
              <IconFilter />
              <span>Filters</span>
            </Button>
          }
        />
        <div className={`flex flex-col mb-6 gap-6`}>
          <div className="flex gap-2 justify-between">
            <Input
              allowClear
              size="large"
              className="search-input w-72"
              placeholder="Search"
              prefix={
                <SearchOutlined className="text-primary-navyLight text-5" />
              }
              onChange={debounce((e) => {
                setKeyword(e.target.value);
              }, 300)}
              onPressEnter={debounce((e) => {
                setKeyword(e.target.value);
              }, 100)}
              data-testid="dashboard-keyword-search-input"
            />
            <Space size={8}>
              <Switch
                checked={filterInput?.status?.includes(TaskStatus.Void)}
                label="Show Voided"
                onChange={(checked) => {
                  if (checked) {
                    setFilterInput({
                      ...filterInput,
                      status: [...(filterInput?.status || []), TaskStatus.Void],
                    });
                  } else {
                    setFilterInput({
                      ...filterInput,
                      status: (filterInput?.status || [])?.filter(
                        (i) => i !== TaskStatus.Void
                      ),
                    });
                  }
                }}
              />
              <Button
                className="inline-flex items-center"
                size="large"
                onClick={() => {
                  navigate("/dashboard/waiting-list-overview");
                }}
              >
                <OverViewIcon className="mr-2" />
                <span>{formatLabel("Waiting List Overview")}</span>
              </Button>
            </Space>
          </div>

          <div>
            <Tabs
              activeKey={
                TabNames?.find((TabName) =>
                  TabName?.taskTypes?.some(
                    (taskType) => taskType.value === selectedTaskType
                  )
                )?.key
              }
              className="leading-tight font_semibold text_sm text__primary"
            >
              {TabNames?.map((tabName) => (
                <Tabs.TabPane
                  tab={
                    !tabName?.taskTypes?.some(
                      (taskType) => taskType.value === TaskType.Operational
                    ) ? (
                      <Dropdown
                        overlay={
                          <Menu
                            onClick={(item: any) => {
                              setSelectedTaskType(item?.key);
                            }}
                          >
                            {tabName?.taskTypes
                              ?.filter(Boolean)
                              ?.map((item) => (
                                <Menu.Item key={item?.value}>
                                  <span className="ml-2">{item?.label}</span>{" "}
                                  {count?.[item?.value]?.pending ? (
                                    <Badge color="#5B9DFF" />
                                  ) : (
                                    <></>
                                  )}
                                  {count?.[item?.value]?.overdue ? (
                                    <Badge color="#F36B7F" />
                                  ) : (
                                    <></>
                                  )}
                                </Menu.Item>
                              ))}
                          </Menu>
                        }
                      >
                        <Space>
                          <>
                            {tabName.title}
                            <CaretDownOutlined />
                          </>
                        </Space>
                      </Dropdown>
                    ) : (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedTaskType(TaskType.Operational);
                        }}
                      >
                        {tabName.title}
                      </div>
                    )
                  }
                  key={tabName.key}
                />
              ))}
            </Tabs>
          </div>
        </div>
      </Container.Header>
      <Container.Content className="overflow-auto">
        <>
          <div className="flex flex-col gap-6">
            <TrialFollowUpTask
              data={tasks}
              loading={loading}
              count={count}
              callback={queryIndexTasks}
              pagination={pagination}
              visible={TaskType.FollowUpTrial === selectedTaskType}
            />
            <FollowUpTaskTrialRequest
              data={tasks}
              loading={loading}
              count={count}
              callback={queryIndexTasks}
              pagination={pagination}
              visible={
                TaskType.FollowUpTrialRequest === selectedTaskType &&
                !IsProduction
              }
            />
            <ReservationFollowUpTask
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.FollowUpReservation === selectedTaskType}
            />
            <PaymentFollowUpTask
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.FollowUpPayment === selectedTaskType}
            />
            <AbsentFollowUpTask
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.FollowUpAbsent === selectedTaskType}
            />
            <FollowUpReplacementLessonOffer
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={
                TaskType.FollowUpReplacementLessonOffer === selectedTaskType
              }
            />
            <FollowUpFeeBalance
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.FollowUpFeeBalance === selectedTaskType}
            />
            <ReminderTaskTrialLesson
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.ReminderTrialLesson === selectedTaskType}
            />
            <ReminderTaskAdditionalLesson
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.ReminderAdditionalLesson === selectedTaskType}
            />
            <ReminderTaskLesson
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.ReminderLesson === selectedTaskType}
              onInnerFilterChange={(value) => setInnerFilterInput(value)}
            />
            <ReminderTaskClassArrangementChange
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.ReminderChangeClass === selectedTaskType}
              onInnerFilterChange={(value) => setInnerFilterInput(value)}
            />
            <ReminderTaskWorkshopRL
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={
                TaskType.ReminderWorkshopReplacementLesson === selectedTaskType
              }
            />
            <ReminderTaskLessonUnderHoliday
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.ReminderLessonUnderHoliday === selectedTaskType}
            />
            <ReminderTaskAdditionalLessonOffer
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={
                TaskType.ReminderAdditionalLessonOffer === selectedTaskType
              }
            />
            <TermBillingFollowUpTask
              data={tasks}
              loading={loading}
              count={count}
              pagination={pagination}
              callback={queryIndexTasks}
              visible={TaskType.FollowUpTermBilling === selectedTaskType}
            />
          </div>
          {TaskType.Operational === selectedTaskType && (
            <div className="mb-6 h-full overflow-hidden">
              <OperationalTaskList refreshCount={getPendingOverdueTaskCount} />
            </div>
          )}
          <TaskFilter
            visible={filterVisible}
            onClose={() => setFilterVisible(false)}
            onChange={(value: {
              status: TaskStatus[];
              dueDateRange: [Dayjs, Dayjs];
              whatsappAccountId: string;
            }) => {
              if (
                !value?.dueDateRange &&
                !value?.status &&
                !value?.whatsappAccountId
              ) {
                setFilterInput(value);
              } else {
                setFilterInput({
                  ...value,
                  status: filterInput?.status?.includes(TaskStatus.Void)
                    ? [...(value?.status || []), TaskStatus.Void]
                    : value?.status,
                });
              }
              setFilterVisible(false);
            }}
            value={filterInput}
          />
        </>
      </Container.Content>
    </Container>
  );
};

export default DashboardPage;

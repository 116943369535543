import { Skeleton } from "@thepiquelab/archus-components-web";
import { isNumber } from "lodash";

const FormSkeleton = (props: {
  fieldNumber?: number;
  showButtons?: boolean;
  className?: string;
}) => (
  <div className={`flex flex-col gap-6 ${props?.className || ""}`}>
    {new Array(isNumber(props?.fieldNumber) ? props?.fieldNumber : 3)
      .fill(null)
      .map((_, index) => (
        <div className="flex gap-6" key={index.toString()}>
          <div style={{ flexBasis: "35%" }}>
            <Skeleton
              active
              paragraph={false}
              title={{
                style: {
                  margin: 0,
                  height: 24,
                  width: "100",
                },
              }}
            />
          </div>
          <div style={{ flexBasis: "60%" }}>
            <Skeleton
              active
              paragraph={false}
              title={{
                style: {
                  margin: 0,
                  height: 24,
                  width: "100%",
                },
              }}
            />
          </div>
        </div>
      ))}
    {props?.showButtons ? (
      <div className="flex flex-row-reverse gap-6">
        <Skeleton.Button active />
        <Skeleton.Button active />
      </div>
    ) : (
      <></>
    )}
  </div>
);

export default FormSkeleton;

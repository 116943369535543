import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import { InvoiceStatusMap } from "@/components/finance/Modules/InvoiceDetail/InvoiceDetailCard";
import AdditionalLink from "@/components/widget/CommonLink/AdditionalLink";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import { TaskFieldsFragment, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { formatDate } from "@thepiquelab/web-moment";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const ReminderTaskAdditionalLesson: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: formatLabel("AL Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        const { id: classId, shorthand } = enrollment?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Additional Lesson No."),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        if (!enrollment?.arrangements?.length) {
          return <>-</>;
        }
        return enrollment?.arrangements.map((r) => (
          <div>
            <AdditionalLink id={r.id}>{r.name}</AdditionalLink>
          </div>
        ));
      },
    },

    {
      title: formatLabel("Payment Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;
        if (!enrollment?.arrangements?.length) {
          return <>-</>;
        }
        return enrollment?.arrangements.map((r) => (
          <div style={{ color: InvoiceStatusMap[r?.invoice?.status]?.color }}>
            {InvoiceStatusMap[r?.invoice?.status]?.text}
          </div>
        ));
      },
    },

    {
      title: formatLabel("Additional Lesson Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { enrollment } = record;

        return enrollment?.attendances?.map((a) => (
          <div>{formatDate(a.startDateTime, { format: "SHORT" })}</div>
        ));
      },
    },
  ];
  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Additional Lesson Reminder")}
        taskType={TaskType.ReminderAdditionalLesson}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReminderTaskAdditionalLesson;

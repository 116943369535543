import { Col, Row } from "@thepiquelab/archus-components-web";
import { Moment } from "@thepiquelab/web-moment";
import React from "react";
import StartDateCalendar from "./Calendar/StartDateCalendar";

interface Props {
  lessonDates: Moment[];
  onRegistrationDateChange: (v: Date) => void;
}

const RegularModal: React.FC<Props> = ({
  lessonDates,
  onRegistrationDateChange,
}) => {
  const getRegistrationDate = (v: Date) => {
    onRegistrationDateChange(v);
  };

  return (
    <div data-testid="RegistrationDate">
      <Row justify={"center"}>
        <Col>
          <StartDateCalendar
            availableDates={lessonDates}
            onSelect={getRegistrationDate}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RegularModal;

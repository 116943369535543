import { IAuthorizedPermissions } from "@/components/authorize/AuthorizedByPermission";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { LoadState } from "@/experimental/components/DisplayEditor/types";
import { PageInfo, SortInfo, UserCustomiseColumn } from "@/graphql";
import { MenuProps } from "antd";
import React, { CSSProperties, ComponentType } from "react";
import { CommandType } from "../CommandCell";
import { GridCellProps, GridColumnProps } from "./GridType";

export declare interface DataPageCellProps<T>
  extends Omit<GridCellProps, "dataItem"> {
  dataItem: T;
}

export declare interface FilterProps<FilterInput extends Record<string, any>> {
  value?: FilterInput;
  onChange: (value: FilterInput) => void;
  /**
   * @description onlyShowFormItemsField = ["venueIds", "status"] means only venue and status in the filter form
   * @description onlyShowFormItemsField is falsy means show all the fields.
   */
  onlyShowFormItemsField?: string[];
  [propName: string]: any;
}

export declare interface QueryInputs<T = Record<string, any>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterInput?: T;
  sortInfo?: SortInfo;
  pageInfo?: PageInfo;
}

export interface SortDescriptor {
  /**
   * The field that is sorted.
   */
  field: string;
  /**
   * The sort direction. If no direction is set, the descriptor will be skipped during processing.
   *
   * The available values are:
   * - `asc`
   * - `desc`
   */
  dir?: "asc" | "desc";
}

export declare interface DataPageColumnProps<T = Record<string, any>>
  extends Omit<GridColumnProps, "cell"> {
  type?: EnhancedTableWidthType;
  isShow?: boolean;
  width?: number;
  fixed?: "left" | "right";
  cell?: ComponentType<DataPageCellProps<T>>;
}

export interface AddButtonPropsItems {
  content: React.ReactNode;
  icon?: React.ReactNode;
  key: string;
  disable?: boolean;
}

export enum EnumEnhancedTableWidthType {
  Short = "short",
  Middle = "middle",
  Large = "large",
  MiddleLarge = "middleLarge",
  Huge = "huge",

  XS_EXTRA_SMALL = "XS_EXTRA_SMALL",
  S_SMALL = "S_SMALL",
  M_MEDIUM = "M_MEDIUM",
  L_LARGE = "L_LARGE",
}

export const enumEnhancedTableWidthType = {
  /**
   * @deprecated
   */
  [EnumEnhancedTableWidthType.Short]: 104,
  /**
   * @deprecated
   */
  [EnumEnhancedTableWidthType.Middle]: 180,
  /**
   * @deprecated
   */
  [EnumEnhancedTableWidthType.MiddleLarge]: 250,
  /**
   * @deprecated
   */
  [EnumEnhancedTableWidthType.Large]: 400,
  /**
   * @deprecated
   */
  [EnumEnhancedTableWidthType.Huge]: 600,

  /**
   * Refer to https://zeroheight.com/73d6f179d/p/196a2f-data-grid/t/32b25c
   * @description For column widths we use 4 width variants which are
   *  extra-small5.5rem (88px)
   *  small 10rem (160px)
   *  medium18.75rem (300px)
   *  large28.75rem (460px)
   */
  [EnumEnhancedTableWidthType.XS_EXTRA_SMALL]: 90,
  [EnumEnhancedTableWidthType.S_SMALL]: 180,
  [EnumEnhancedTableWidthType.M_MEDIUM]: 300,
  [EnumEnhancedTableWidthType.L_LARGE]: 460,
};

export declare interface AddButtonProp extends Partial<IAuthorizedPermissions> {
  label: string;
  onClick(): void;
  disable?: boolean;
  icon?: React.ReactNode;
  items?: AddButtonPropsItems[];
  onItemClick?: MenuProps["onClick"];
}

type OriginEnhancedTableWidthType =
  | "short"
  | "middle"
  | "large"
  | "middleLarge"
  | "huge";
export type EnhancedTableWidthType =
  | OriginEnhancedTableWidthType
  | EnumEnhancedTableWidthType;

export declare interface EnhancedTableProps<T> {
  title?: React.ReactNode;
  data: Array<T>;
  total?: number;
  fields: Array<DataPageColumnProps<T>>;
  loadMore?: (pageInfo?: PageInfo) => Promise<void>;
  hasMore?: boolean;
  loading?: boolean;
  loadMoreLoading?: boolean;
  pageSize?: number;
  pageIndex?: number;
  loader?: React.ReactElement;
  onRowClick?: (dataItem: T) => void;
  onQueryChange?: (query: QueryInputs) => void;
  onExportCsv?: (query?: QueryInputs) => void;
  onExportPdf?: (items?: T[]) => void;
  commands?: CommandType;
  showMenuBar?: boolean;
  toolbar?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: React.FC<FilterProps<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterProps?: Partial<FilterProps<any>>;
  initFilterValue?: Record<string, any>;
  showCustomToolbarItemMiddleFilter?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inlineFilter?: React.FC<FilterProps<any>>;
  searchable?: boolean;
  sortable?: boolean;
  defaultSortState?: SortDescriptor;
  className?: string;
  setRowClassName?: (item: T) => string;
  style?: CSSProperties;
  addButtonProp?: AddButtonProp;
  customToolbarItemLeft?: React.ReactNode;
  customToolbarItemMiddle?: React.ReactNode;
  customToolbarItemRight?: React.ReactNode;
  gridTopToolBar?: () => React.ReactNode;
  getCustomHeaderItem?: (record: Array<T>) => React.ReactNode;
  isShowHeader?: boolean;
  isShowExport?: boolean;
  isShowGroupExport?: boolean;
  groupExportLoading?: boolean;
  showExportPermissions?: EnumPermissions[];
  isShowCustomise?: boolean;
  description?: string;
  toolTip?: string | React.ReactNode;
  height?: string;
  showSelect?: boolean;
  defaultSelectedItems?: Array<T>;
  onSelectChange?: (items: T[]) => void;
  checkboxDisable?: (item: T) => boolean;
  showToolBarFilter?: boolean;
  isFilterNextToSearch?: boolean;
  isStudentReport?: boolean;
  tableName?: string;
  /**
   * Optional customise columns
   * that is controlled by developer outside Grid
   * @todo Remove these props once ListStudents uses new common Grid component
   */
  userCustomiseColumnsList?: UserCustomiseColumn[];
  userCustomiseLoadState?: LoadState;
  handleCustomColumnsApply?: (
    columns: UserCustomiseColumn[],
    savePreset?: boolean
  ) => Promise<void>;
  handleCustomColumnsReset?: () => void;
  /**
   * Width to provide the command column a width. Defaults at 60
   */
  /** Include Column Name in filtering/sorting. */
  useColumnNameInFilter?: boolean;

  maxSelectedItemsNumber?: number;

  autoPagination?: boolean;
}

import React from "react";
import { Link } from "react-router-dom";

interface TransferLinkProps {
  id: string;
  linkText?: string;
  children?: React.ReactNode;
}
const TransferLink: React.FC<TransferLinkProps> = ({
  id,
  linkText,
  children,
}) => {
  if (!id) return <>{children}</>;

  const linkUrl = `/class-arrangement/transfer/${id?.trim()}`;
  return (
    <Link
      to={linkUrl}
      target={"_blank"}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <span className="font_semibold text-primary-blue">
        {linkText || children}
      </span>
    </Link>
  );
};

export default TransferLink;

import React from "react";
import { Link } from "react-router-dom";

interface IAdminLinkProps {
  id: string;
  linkText?: string;
  children?: React.ReactNode;
}
const AdminLink: React.FC<IAdminLinkProps> = ({ id, linkText, children }) => {
  if (!id) return <>{children}</>;

  const linkUrl = `/system-user/profile/${id?.trim()}`;
  return (
    <Link
      to={linkUrl}
      target={"_blank"}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <span className="font_semibold text-primary-blue">
        {linkText || children}
      </span>
    </Link>
  );
};

export default AdminLink;

import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import {
  EnumEnhancedTableWidthType,
  enumEnhancedTableWidthType,
} from "@/components/common/EnhancedTable/EnhancedTable";
import ClassLink from "@/components/widget/CommonLink/ClassLink";
import RegistrationLink from "@/components/widget/CommonLink/RegistrationLink";
import { TaskFieldsFragment, TaskStatus, TaskType } from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import { formatDate } from "@thepiquelab/web-moment";
import FollowUpStatusSelect, {
  FollowUpType,
} from "../Modules/FollowUpStatusSelect";
import TaskTable, { CustomCommonColumn } from "./TaskTable";
import { TaskTableProps } from "./types";

const ReservationFollowUpTask: React.FC<TaskTableProps> = (props) => {
  const { data, callback, visible, pagination, count, loading } = props;

  const columns: AdvanceTableColumnType[] = [
    CustomCommonColumn.StudentName,
    CustomCommonColumn.StudentID,
    CustomCommonColumn.PrimaryParentName,
    CustomCommonColumn.PrimaryParentMobile,
    CustomCommonColumn.PrimaryParentWALine,
    {
      title: "Primary Parent WA Line",
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { registration } = record;
        const { linkedWhatsappAccount } =
          registration?.student?.primaryParent || {};

        return linkedWhatsappAccount ? (
          <span className="font_regular">
            {`[${linkedWhatsappAccount?.number?.number?.slice(0, 4)}]`}
          </span>
        ) : (
          <></>
        );
      },
    },
    {
      title: formatLabel("Class Shorthand"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { registration } = record;
        const { id: classId, shorthand } = registration?.class || {};

        return <ClassLink classId={classId}>{shorthand}</ClassLink>;
      },
    },
    {
      title: formatLabel("Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { registration } = record || {};
        return registration?.status ? (
          formatLabel(registration?.status)
        ) : (
          <>-</>
        );
      },
    },
    {
      title: formatLabel("Reservation F/U Status"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        return (
          <FollowUpStatusSelect
            enrollment={{
              id: record?.registration?.enrollment?.id,
              followUpStatus: record?.registration?.enrollment?.followUpStatus,
              followUpStatusLastModifiedBy: {
                fullName:
                  record?.registration?.enrollment?.followUpStatusLastModifiedBy
                    ?.fullName,
              },
              followUpStatusLastModifiedDateTime:
                record?.registration?.enrollment
                  ?.followUpStatusLastModifiedDateTime,
            }}
            disabled={record?.status === TaskStatus.Void}
            followUpType={FollowUpType.Reservation}
            className="w-full"
            callback={() => callback?.()}
          />
        );
      },
    },
    {
      title: formatLabel("Enrollment No."),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render(value, record: TaskFieldsFragment) {
        const { name, id } = record?.registration || {};
        return name && id ? (
          <RegistrationLink id={id}>{name}</RegistrationLink>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: formatLabel("Reservation Due Date"),
      width: enumEnhancedTableWidthType[EnumEnhancedTableWidthType.S_SMALL],
      render: (value, record: TaskFieldsFragment) => {
        const { dueDate } = record?.registration || {};

        return <div>{formatDate(dueDate, { format: "SHORT" })}</div>;
      },
    },
  ];

  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <TaskTable
        data={data}
        loading={loading}
        count={count}
        columns={columns}
        title={formatLabel("Reservation Follow Up")}
        taskType={TaskType.FollowUpReservation}
        callback={callback}
        pagination={{
          ...pagination,
        }}
      />
    </div>
  );
};

export default ReservationFollowUpTask;

import { ReactComponent as IconCall } from "@/assets/images/IconCall.svg";
import { ReactComponent as IconChange } from "@/assets/images/IconChange.svg";
import { ReactComponent as IconCollectFee } from "@/assets/images/IconCollectFee.svg";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { AdvanceTableColumnType } from "@/components/common/AdvanceTable/AdvanceTable";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import ModalButtonGroup from "@/components/common/ModalButtonGroup";
import {
  KEYWORD_ENUM,
  useFeatureHook,
} from "@/components/settings/Features/utils";
import { DefaultColorCode } from "@/constants";
import { setSessionRegistrationStage } from "@/utils/checkSessionStorageHybrid";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import Icon, {
  CloseCircleFilled,
  LikeFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import {
  Form,
  Modal,
  Spin,
  Table,
  Typography,
  message,
} from "@thepiquelab/archus-components-web";
import { RegistrationStage } from "@thepiquelab/web-types";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EnrollmentGroupType,
  EnrollmentStatus,
  EnrollmentType,
  GetRegistrationForAction,
  GetRegistrationForActionQuery,
  GetRegistrationForActionQueryVariables,
  GetStudentOfActiveEnrollments,
  GetStudentOfActiveEnrollmentsQuery,
  GetStudentOfActiveEnrollmentsQueryVariables,
  InvoiceStatus,
  LearningArrangementType,
  RegistrationType,
  RegistrationsStatus,
  StudentCellFieldsFragment,
} from "../../../graphql";
import DropdownAction, {
  Action,
} from "../../common/DropdownAction/DropdownAction";
import HybridGroups from "../RegistrationFlow/HybridGroups";
import {
  useApproveRegistration,
  useCancelRegistration,
} from "../RegistrationHooks";

type RegistrationProps = {
  id: string;
  status: RegistrationsStatus;
  type: RegistrationType;
  class?: {
    course?: {
      id: string;
    };
    id: string;
    learningArrangement: LearningArrangementType;
  };
  course?: {
    id: string;
  };
  invoice?: {
    id: string;
    status?: InvoiceStatus;
  };
  effectedWaitingLists?: {
    id: string;
    startDate?: Date;
    student: StudentCellFieldsFragment;
    createDateTime: Date;
  }[];
  enrollment?: {
    preferredLearningArrangement?: LearningArrangementType;
    group?: EnrollmentGroupType;
  };
  student?: {
    id: string;
    fullName: string;
  };
  startDate?: Date;
  otherRegistrations?: {
    name?: string;
    class?: {
      name: string;
      course: {
        colorCode?: string;
      };
    };
  }[];
};

export interface RegistrationStatusActionsProps {
  onCollectFee?: (invoiceId: string) => void;
  onApprove?: (record: RegistrationProps) => void;
  onChangeToRegistration?: (record: RegistrationProps) => void;
  onChangeToWaitingList?: (record: RegistrationProps) => void;
  onAddTrial?: (record: RegistrationProps) => void;
  onCancelRegistration?: (id: string) => void;
  registration: RegistrationProps;
  loading?: boolean;
  children?: React.ReactNode;
}

export const RegistrationStatusActions: React.FC<
  RegistrationStatusActionsProps
> = ({
  registration,
  onCollectFee,
  children,
  onApprove,
  onChangeToRegistration,
  onChangeToWaitingList,
  onCancelRegistration,
  onAddTrial,
  loading,
}) => {
  const {
    status,
    id: registrationId,
    invoice,
    class: { id: classId },
    enrollment,
  } = registration;
  const { id: invoiceId, status: invoiceStatus } = invoice || {};
  const { preferredLearningArrangement, group } = enrollment || {};
  const isHybrid =
    registration?.class?.learningArrangement === LearningArrangementType.Hybrid;

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { refetch } = useQuery<
    GetRegistrationForActionQuery,
    GetRegistrationForActionQueryVariables
  >(GetRegistrationForAction, { skip: true });
  const [cancelRegistration, { loading: cancelLoading }] =
    useCancelRegistration();
  const [approveRegistration, { loading: approveLoading }] =
    useApproveRegistration();

  const { refetch: getStudentOfActiveEnrollments, loading: toTransferLoading } =
    useQuery<
      GetStudentOfActiveEnrollmentsQuery,
      GetStudentOfActiveEnrollmentsQueryVariables
    >(GetStudentOfActiveEnrollments, {
      skip: true,
      fetchPolicy: "network-only",
    });

  const { modal, setModalProps, show, close } = useConfirmModal();
  const [actionRegistration, setActionRegistration] =
    useState<GetRegistrationForActionQuery["registration"]>();
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [hybridVisible, setHybridVisible] = useState(false);
  const isShowLearningArrangement = useFeatureHook([
    KEYWORD_ENUM.global_learning_arrangement,
  ]);
  const isShowEmail = useFeatureHook([KEYWORD_ENUM.global_email]);
  const isShowWhatsApp = useFeatureHook([KEYWORD_ENUM.global_whatsApp]);
  const isShowProcessAutomation = useMemo(
    () => isShowEmail || isShowWhatsApp,
    [isShowWhatsApp, isShowEmail]
  );

  const cancel = async (
    id: string,
    isCancelOtherRegistration?: boolean
  ): Promise<void> => {
    try {
      await cancelRegistration({
        variables: { id, isCancelOtherRegistration },
      });
      onCancelRegistration?.(id);
      close();
      setActionRegistration(null);
      message.success("Registration Cancelled Successfully!");
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  const cancelColumns: AdvanceTableColumnType[] = [
    {
      title: formatLabel("Enrollment No."),
      dataIndex: "name",
      width: 150,
    },
    {
      title: formatLabel("Class"),
      dataIndex: "class.name",
      render: (val, row) => (
        <div
          style={{
            color: row?.class?.course?.colorCode || DefaultColorCode,
          }}
        >
          {row?.class?.name}
        </div>
      ),
    },
    {
      title: formatLabel("Status"),
      dataIndex: "status",
      render: (val, row) => <>{formatLabel(row?.status)}</>,
    },
  ];

  const approve = async (input: {
    id: string;
    isChangeToWaitingList?: boolean;
  }): Promise<void> => {
    const { id, isChangeToWaitingList } = input;
    try {
      switch (status) {
        case RegistrationsStatus.Waitlisted:
          await approveRegistration({
            variables: {
              input: {
                id,
                isChangeToWaitingList,
                preferredLearningArrangement,
                group,
              },
            },
          });
          navigate(`/class-arrangement/registration/create/step2/${id}`);
          break;
        case RegistrationsStatus.Reserved:
          if (!isChangeToWaitingList) {
            await approveRegistration({
              variables: {
                input: {
                  id,
                  isChangeToWaitingList,
                  preferredLearningArrangement,
                  group,
                },
              },
            });
            // await createRegistrationBilling({
            //   variables: {
            //     id,
            //     currentStep: 2,
            //   },
            // });
          }
          navigate(
            `/class-arrangement/registration/create/step${
              isChangeToWaitingList ? "4" : "2"
            }/${id}`,
            {
              state: {
                isChangeToWaitingList,
              },
            }
          );
          break;
        default:
          break;
      }
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
  };

  const handleCancel = async (id: string): Promise<void> => {
    setActionLoading(true);
    const res = await refetch({ id });
    setActionLoading(false);

    if (res?.data?.registration?.otherRegistrations?.length) {
      setActionRegistration(res?.data?.registration);
      return;
    }
    setModalProps({
      onConfirm: () => cancel(id),
      onClose: () => close(),
    });
    show();
  };

  const addTrail: Action = useMemo(() => {
    const label = "Add Trial";

    return {
      icon: <PlusCircleFilled />, // <IconPlus className="w-3 h-3" />,
      label,
      permissions: [EnumPermissions.REGISTRATION_UPDATE],
      hide: [
        RegistrationsStatus.Cancelled,
        RegistrationsStatus.Expired,
      ].includes(status),
      onClick: async () => {
        onAddTrial(registration);
      },
    };
  }, [onAddTrial, registrationId, status]);

  const cancelAction: Action = useMemo(() => {
    let label = "Cancel Registration";
    if (status === RegistrationsStatus.Reserved) {
      label = "Cancel Reservation";
    }
    if (status === RegistrationsStatus.Waitlisted) {
      label = "Remove From Waiting List";
    }

    return {
      icon: <CloseCircleFilled />,
      label,
      permissions: [EnumPermissions.REGISTRATION_UPDATE],
      hide: [
        RegistrationsStatus.Cancelled,
        RegistrationsStatus.Expired,
      ].includes(status),
      onClick: async () => {
        handleCancel(registrationId);
      },
    };
  }, [handleCancel, registrationId, status]);

  const approveAction: Action = useMemo(
    () => ({
      icon: <LikeFilled />,
      label: "Change Waiting list to Reservation",
      permissions: [EnumPermissions.REGISTRATION_UPDATE],
      onClick: async () => {
        setSessionRegistrationStage(RegistrationStage.Reservation);
        onApprove(registration);
      },
    }),
    [isHybrid, isShowLearningArrangement, onApprove, registration]
  );

  const changeToTransfer: Action = useMemo(
    () => ({
      icon: <Icon component={IconChange} />,
      label: "Change Reservation To Transfer",
      permissions: [EnumPermissions.ARRANGEMENT_UPDATE],
      onClick: async () => {
        try {
          const studentData = await getStudentOfActiveEnrollments({
            id: registration.student.id,
            input: {
              courseIds: [registration.course.id],
              enrollmentTypes: [EnrollmentType.Regular],
              status: [EnrollmentStatus.Active, EnrollmentStatus.Complete],
            },
          });
          if (!studentData?.data?.student?.enrollments?.length) {
            message.error(
              "Student doesn't have a registration in another class for this course."
            );
            return;
          }
          navigate("/class-arrangement/transfer", {
            state: {
              studentId: registration.student.id,
              fromClassId: studentData.data.student.enrollments[0].class.id,
              toClassId: registration.class.id,
            },
          });
        } catch (error) {
          message.error(formatErrorMessage(error));
        }
      },
    }),
    [
      getStudentOfActiveEnrollments,
      registration.student.id,
      registration.course.id,
      registration.class.id,
      history,
    ]
  );

  const collectFeeAction: Action = useMemo(
    () => ({
      icon: <Icon component={IconCollectFee} />,
      permissions: [EnumPermissions.INVOICE_UPDATE],
      label:
        invoiceStatus === InvoiceStatus.Paid
          ? "View Payment Records"
          : "Collect Fees",
      onClick: () => onCollectFee(invoiceId),
    }),
    [invoiceId, invoiceStatus, onCollectFee]
  );

  const changeToRegistration: Action = useMemo(
    () => ({
      icon: <Icon component={IconChange} />,
      label: "Change Reservation To Registration",
      permissions: [EnumPermissions.REGISTRATION_UPDATE],
      onClick: () => {
        setSessionRegistrationStage(RegistrationStage.Regular);
        onChangeToRegistration(registration);
      },
    }),
    [isHybrid, isShowLearningArrangement, onChangeToRegistration, registration]
  );

  const changeToWaitingList: Action = useMemo(
    () => ({
      icon: <Icon component={IconChange} />,
      label: "Change Reservation To Waiting List",
      permissions: [EnumPermissions.REGISTRATION_UPDATE],
      onClick: () => {
        setSessionRegistrationStage(RegistrationStage.WaitingList);
        onChangeToWaitingList(registration);
      },
    }),
    [isHybrid, isShowLearningArrangement, onChangeToWaitingList, registration]
  );

  const sendConfirmation: Action = useMemo(
    () => ({
      icon: <Icon component={IconCall} />,
      label: "Send Confirmation",
      hide: !isShowProcessAutomation,
      permissions: [EnumPermissions.NOTIFICATION_LOG_UPDATE],
      onClick: () => {
        navigate(
          `/class-arrangement/registration/sendConfirmation/${registrationId}`
        );
      },
    }),
    [history, isShowProcessAutomation, registrationId, registration]
  );

  const actions = useMemo(() => {
    switch (status) {
      case RegistrationsStatus.Complete: {
        return [cancelAction, collectFeeAction, sendConfirmation];
      }
      case RegistrationsStatus.Waitlisted: {
        return [approveAction, cancelAction, sendConfirmation];
      }
      case RegistrationsStatus.Reserved: {
        return [
          changeToRegistration,
          changeToWaitingList,
          changeToTransfer,
          addTrail,
          cancelAction,
          sendConfirmation,
        ];
      }
      default:
        return [];
    }
  }, [
    approveAction,
    addTrail,
    cancelAction,
    changeToRegistration,
    changeToTransfer,
    changeToWaitingList,
    collectFeeAction,
    sendConfirmation,
    status,
  ]);

  useEffect(() => {
    setModalProps({
      confirmLoading: cancelLoading,
    });
  }, [cancelLoading]);

  return (
    <Spin
      spinning={
        cancelLoading ||
        approveLoading ||
        loading ||
        actionLoading ||
        toTransferLoading
      }
    >
      <DropdownAction id={registrationId} actions={actions}>
        {children}
      </DropdownAction>
      {modal}
      <Modal
        visible={hybridVisible}
        destroyOnClose
        footer={
          <ModalButtonGroup
            onOK={() => {
              form.submit();
            }}
            okText={formatLabel("Next")}
            onCancel={() => {
              setHybridVisible(false);
              form.resetFields();
            }}
            confirmLoading={approveLoading}
          />
        }
        onCancel={() => {
          setHybridVisible(false);
          form.resetFields();
        }}
        title={formatLabel("Select Preferred Learning Arrangement")}
        className="min-w-md"
      >
        <HybridGroups
          classId={classId}
          form={form}
          onSubmit={(value) => {
            approve({
              id: registrationId,
              ...value,
            });
          }}
        />
      </Modal>
      <Modal
        width={800}
        title={formatLabel("Confirmation")}
        visible={!!actionRegistration}
        onCancel={() => setActionRegistration(null)}
        footer={
          <ModalButtonGroup
            onOK={() => {
              cancel(registrationId, false);
            }}
            okText={formatLabel("Yes")}
            cancelText={formatLabel("No")}
            onBack={() => {
              cancel(registrationId, true);
            }}
            backText={formatLabel("Cancel All Registration")}
            backButtonProps={{
              loading: cancelLoading,
            }}
            showBack
            onCancel={() => {
              setActionRegistration(null);
            }}
            confirmLoading={cancelLoading}
          />
        }
      >
        <Table
          title={() => (
            <Typography.Text>
              {`Are you sure you want to proceed? Please note ${
                registration?.student?.fullName || "student"
              } has other registration in this course.`}
            </Typography.Text>
          )}
          columns={cancelColumns}
          dataSource={actionRegistration?.otherRegistrations}
          pagination={false}
        />
      </Modal>
    </Spin>
  );
};

import { ReactComponent as IconEmail } from "@/assets/images/IconEmail.svg";
import { ReactComponent as IconWhatsAppOuter } from "@/assets/images/IconWhatsAppOuterForSender.svg";
import FeatureWrapper from "@/components/settings/Features/FeatureWrapper";
import { KEYWORD_ENUM } from "@/components/settings/Features/utils";
import { useLazyQueryTemplates } from "@/components/settings/SettingHooks";
import {
  addFontFamily,
  formatTemplateBr,
  formatVariableName,
  isUndefined,
} from "@/components/settings/Template/tools";
import {
  BulkSendReminderInput,
  GetReminderFormParent,
  GetReminderFormParentQuery,
  GetReminderFormParentQueryVariables,
  ReminderType,
  SendReminderInput,
  TemplateStatus,
  TemplateUsageType,
} from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { useLazyQuery } from "@apollo/client";
import {
  Form,
  FormInstance,
  Space,
  Store,
  message,
} from "@thepiquelab/archus-components-web";
import { useToggle } from "ahooks";
import React, { useEffect } from "react";
import ReminderCollapse from "../common/ReminderCollapse";
import { getReminderTitle } from "../utils";
import EmailForm from "./EmailForm";
import "./FinanceNotificationForm.scss";
import WhatsappForm from "./WhatsappForm";

interface ReminderFormProps {
  templateUsageType: TemplateUsageType;
  parentId?: string;
  form?: FormInstance;
  isShowSendInvoice?: boolean;
  onSubmit: (input: SendReminderInput | BulkSendReminderInput) => Promise<void>;
  onPreview?: (input: SendReminderInput | BulkSendReminderInput) => void;
  showPreview?: boolean;
  attachmentLabel?: string;
  isBulk: boolean;
  partialSendReminderInput: Partial<SendReminderInput>;
}

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 21,
  },
};

const ReminderForm: React.FC<ReminderFormProps> = (props) => {
  const {
    templateUsageType: usageType,
    form: formInstance,
    parentId,
    onSubmit,
    onPreview,
    isShowSendInvoice = true,
    showPreview = true,
    attachmentLabel,
    isBulk = false,
    partialSendReminderInput,
  } = props;
  const [form] = Form.useForm(formInstance);
  const [queryParent, { data: parentData }] = useLazyQuery<
    GetReminderFormParentQuery,
    GetReminderFormParentQueryVariables
  >(GetReminderFormParent);
  const [queryTemplates, { data: templates }] = useLazyQueryTemplates({
    fetchPolicy: "network-only",
  });

  const [isShowWhatsApp, { set: toggleShowWhatsApp }] = useToggle(false);
  const [isShowEmail, { set: toggleShowEmail }] = useToggle(false);

  const formatCompileInput = (
    isEmail: boolean
  ): SendReminderInput | BulkSendReminderInput => {
    const emailTemplateId = form.getFieldValue("emailTemplateId");
    const whatsAppTemplateId = form.getFieldValue("whatsAppTemplateId");
    const emailMessage = form.getFieldValue("emailMessage");
    const whatsappMessage = form.getFieldValue("whatsappMessage");

    const types: ReminderType[] = [];

    if (isEmail) {
      types.push(ReminderType.Email);
    } else {
      types.push(ReminderType.Whatsapp);
    }

    return {
      types,
      emailInput: isShowEmail
        ? {
            subject: formatVariableName(form.getFieldValue("subject")),
            message: isUndefined(formatVariableName(emailMessage?.toHTML?.())),
            templateId: emailTemplateId,
          }
        : null,
      whatsappInput: isShowWhatsApp
        ? {
            message: isUndefined(
              formatVariableName(whatsappMessage?.toText?.())
            ),
            templateId: whatsAppTemplateId,
          }
        : null,
    };
  };

  const handlePreview = async (isEmail: boolean): Promise<void> => {
    if (onPreview) {
      const input = formatCompileInput(isEmail);
      onPreview?.(input);
    }
  };

  const handleSubmit = async (value: Store): Promise<void> => {
    try {
      const {
        whatsAppSenderId,
        emailMessage,
        scheduledDate,
        attachment,
        whatsAppTemplateId,
        emailTemplateId,
        whatsAppReceiveIds,
        emailReceiveIds,
        subject,
        whatsappMessage,
        isSendInvoice,
        isWhatsappSendInvoice,
      } = value;
      const types = [];
      if (isShowWhatsApp) {
        types.push(ReminderType.Whatsapp);
      }
      if (isShowEmail) {
        types.push(ReminderType.Email);
      }

      await onSubmit({
        types,
        whatsappInput: isShowWhatsApp
          ? {
              senderId: whatsAppSenderId,
              templateId: whatsAppTemplateId,
              message: !whatsappMessage.toText()
                ? ""
                : formatVariableName(whatsappMessage.toText()),
              isIncludeAttachment: isWhatsappSendInvoice as boolean,
              receiverParentIds: whatsAppReceiveIds,
            }
          : null,
        emailInput: isShowEmail
          ? {
              file: attachment?.file,
              fileType: attachment?.file.type,
              fileName: attachment?.file.name,
              scheduledDate,
              subject: formatVariableName(subject),
              templateId: emailTemplateId,
              isIncludeAttachment: isSendInvoice as boolean,
              message: !emailMessage?.toHTML?.()
                ? ""
                : addFontFamily(
                    formatTemplateBr(
                      formatVariableName(emailMessage?.toHTML?.())
                    )
                  ),
              receiverParentIds: emailReceiveIds,
            }
          : null,
      });
    } catch (error) {
      message.error(formatErrorMessage(error));
    }
  };

  // Get parent data if parentId exists.
  useEffect(() => {
    if (parentId) {
      queryParent({ variables: { id: parentId } });
    }
  }, [parentId]);

  // Get Templates
  useEffect(() => {
    queryTemplates({
      variables: {
        filterInput: {
          usageTypes: [usageType],
          isArchived: false,
          status: TemplateStatus.Complete,
        },
        pageInfo: { pageIndex: 0, pageSize: 0 },
      },
    });
  }, [usageType]);

  return (
    <Form
      className="finance-notification-form"
      {...layout}
      form={form}
      colon={false}
      preserve={false}
      requiredMark={false}
      onFinish={handleSubmit}
      scrollToFirstError
    >
      <Space direction="vertical" className="w-full" size="large">
        <FeatureWrapper keywords={KEYWORD_ENUM.global_whatsApp}>
          <ReminderCollapse
            checked={isShowWhatsApp}
            onCheckboxChange={() => toggleShowWhatsApp(!isShowWhatsApp)}
            icon={<IconWhatsAppOuter className="w-4.5 h-4.5" />}
            title={formatLabel(
              `Send ${getReminderTitle(usageType)} via WhatsApp`
            )}
          >
            {isShowWhatsApp && (
              <WhatsappForm
                form={form}
                parent={parentData?.parent}
                templates={templates?.templates?.items}
                onPreview={handlePreview}
                showPreview={showPreview}
                isShowSendInvoice={isShowSendInvoice}
                isBulk={isBulk}
                partialSendReminderInput={partialSendReminderInput}
                formatCompileInput={formatCompileInput}
              />
            )}
          </ReminderCollapse>
        </FeatureWrapper>
        <FeatureWrapper keywords={KEYWORD_ENUM.global_email}>
          <ReminderCollapse
            checked={isShowEmail}
            onCheckboxChange={() => toggleShowEmail(!isShowEmail)}
            icon={<IconEmail />}
            title={formatLabel(`Send ${getReminderTitle(usageType)} via Email`)}
          >
            {isShowEmail && (
              <EmailForm
                form={form}
                parent={parentData?.parent}
                templates={templates?.templates?.items}
                onPreview={handlePreview}
                showPreview={showPreview}
                isShowSendInvoice={isShowSendInvoice}
                attachmentLabel={attachmentLabel}
                isBulk={isBulk}
                partialSendReminderInput={partialSendReminderInput}
                formatCompileInput={formatCompileInput}
              />
            )}
          </ReminderCollapse>
        </FeatureWrapper>
      </Space>
    </Form>
  );
};

export default ReminderForm;

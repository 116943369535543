import { calcCapacitySeatsLeft } from "@/components/class/NewClassCapacity/classUtils";
import { useConfirmModal } from "@/components/common/ConfirmModal/NewConfirmModal";
import CommonBadge from "@/components/widget/Badge/CommonBadge";
import {
  ClassType,
  GetClassWithRegistrations,
  GetClassWithRegistrationsQuery,
  GetClassWithRegistrationsQueryVariables,
  RegistrationsStatus,
} from "@/graphql";
import { useQuery } from "@apollo/client";
import { Modal, Table } from "@thepiquelab/archus-components-web";
import moment, { Moment, formatDate } from "@thepiquelab/web-moment";
import { findIndex, sortBy } from "lodash";
import "./index.scss";
import { addRanking } from "./utils";

export const useCheckShouldOfferSeatToWaitingListWarning = () => {
  const { refetch: getClassWithRegistrations, loading } = useQuery<
    GetClassWithRegistrationsQuery,
    GetClassWithRegistrationsQueryVariables
  >(GetClassWithRegistrations, {
    skip: true,
    fetchPolicy: "no-cache",
  });
  const { modal, setModalProps, show, close } = useConfirmModal();
  const checkOfferSeatToWaitingListWarning = (
    classId: string,
    targetDate: Date | Moment,
    option: {
      isWaitingListToReserved?: boolean;
      studentId: string;
    }
  ): Promise<boolean> =>
    new Promise((resolve, reject) => {
      const check = async () => {
        const { isWaitingListToReserved = false, studentId = "" } =
          option || {};
        const result = await getClassWithRegistrations({
          id: classId,
        });
        const classDetail = result?.data?.class;
        const seatLeft = calcCapacitySeatsLeft(classDetail?.capacity);
        const academicWeeks = sortBy(classDetail?.academicWeeks, "startDate");
        const validDateRange = academicWeeks?.reduce(
          (temp, academicWeek, index) => {
            if (
              moment(targetDate).isAfter(moment(academicWeek?.startDate)) &&
              moment(targetDate).isBefore(moment(academicWeek?.endDate))
            ) {
              temp.startDate = moment(academicWeek?.startDate);
              temp.endDate = moment(
                academicWeeks[index + 1]?.endDate || academicWeek?.endDate
              );
            }
            return temp;
          },
          {} as { startDate?: Moment; endDate?: Moment }
        );
        const waitListFilterByStatus = (
          registration: GetClassWithRegistrationsQuery["class"]["registrations"][0] & {
            order?: number;
          }
        ): boolean => {
          return registration?.status === RegistrationsStatus.Waitlisted;
        };
        const waitListFilterByValidRange = (
          registration: GetClassWithRegistrationsQuery["class"]["registrations"][0] & {
            order?: number;
          },
          classType: ClassType
        ): boolean => {
          if (classType === ClassType.Workshop) {
            return registration?.status === RegistrationsStatus.Waitlisted;
          }
          if (classType === ClassType.Recurring) {
            return (
              registration?.status === RegistrationsStatus.Waitlisted &&
              validDateRange?.endDate?.isAfter(
                moment(registration?.startDate)
              ) &&
              validDateRange?.startDate?.isBefore(
                moment(registration?.startDate)
              )
            );
          }
          return false;
        };
        const waitListFilterByCurrentStudent = (
          registration: GetClassWithRegistrationsQuery["class"]["registrations"][0] & {
            order?: number;
          }
        ): boolean => {
          if (registration?.student?.id === studentId) {
            return false;
          }
          return true;
        };
        const waitListFilterByOrder = (
          registration: GetClassWithRegistrationsQuery["class"]["registrations"][0] & {
            order?: number;
          },
          currentOrder: number
        ): boolean => {
          if (currentOrder && registration?.order >= currentOrder) {
            return false;
          }

          return true;
        };

        const registrationWithOrder = addRanking(classDetail?.registrations);
        const currentOrder = registrationWithOrder?.find(
          (item) => item?.student?.id === studentId
        )?.order;

        let waitListRegistrations = registrationWithOrder?.filter(
          (registration) =>
            waitListFilterByValidRange(
              registration as GetClassWithRegistrationsQuery["class"]["registrations"][0],
              classDetail.type
            )
        );

        waitListRegistrations = registrationWithOrder?.filter((registration) =>
          waitListFilterByStatus(
            registration as GetClassWithRegistrationsQuery["class"]["registrations"][0]
          )
        );

        waitListRegistrations = waitListRegistrations?.filter((registration) =>
          waitListFilterByCurrentStudent(
            registration as GetClassWithRegistrationsQuery["class"]["registrations"][0]
          )
        );

        waitListRegistrations = waitListRegistrations?.filter((registration) =>
          waitListFilterByOrder(
            registration as GetClassWithRegistrationsQuery["class"]["registrations"][0],
            currentOrder
          )
        );

        const isSeatLeftGreaterThanOrEqualWaitingListAmount =
          waitListRegistrations?.length > seatLeft ||
          waitListRegistrations?.length === seatLeft;

        let isNeedWarningForWaitinglistedToReverved = false;

        let checkWaitingListConfirmModal: {
          destroy: () => void;
        } = null;
        if (isWaitingListToReserved) {
          const dataSource = addRanking(classDetail?.registrations);
          const order = findIndex(
            dataSource,
            (registration) => registration?.student?.id === studentId
          );
          if (order >= seatLeft) {
            isNeedWarningForWaitinglistedToReverved = true;
          }
        }
        if (!waitListRegistrations?.length) {
          resolve(true);
          return;
        }
        if (
          (!isWaitingListToReserved &&
            isSeatLeftGreaterThanOrEqualWaitingListAmount) ||
          isNeedWarningForWaitinglistedToReverved
        ) {
          checkWaitingListConfirmModal = Modal.confirm({
            title: (
              <div className="check-waiting-list-modal-header c-text-sm p-6 border rounded-lg font-semibold">
                Confirmation
              </div>
            ),
            width: 900,
            icon: <></>,
            // closeIcon: <IconClose />,
            closable: true,
            wrapClassName: "check-waiting-list-modal",
            content: (
              <div className="flex flex-col gap-6 px-6 pt-6">
                <div>
                  The selected class currently has{" "}
                  {waitListRegistrations?.length} students on the waiting list.
                  Are you sure you want to offer the seat to this student?
                </div>

                <Table
                  dataSource={waitListRegistrations}
                  pagination={false}
                  columns={[
                    {
                      title: "Student Name",
                      dataIndex: "studentName",
                      key: "studentName",
                      render: (text, record) => {
                        const id = record?.student?.id;
                        const pathToClassDetail = `/customers/students/profile/${id?.trim()}`;

                        return (
                          <div>
                            <a
                              href={pathToClassDetail}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {record?.student?.fullName}
                            </a>

                            {record?.isPrioritized ? (
                              <div>
                                <CommonBadge
                                  label="Prioritized"
                                  textColor="#F36B7F"
                                  bgColor="#F36B7F33"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Student ID",
                      dataIndex: "studentId",
                      key: "studentId",
                      render: (text, record) => <>{record?.student?.userId}</>,
                    },
                    {
                      title: "Ranking",
                      dataIndex: "ranking",
                      key: "ranking",
                      render: (text, record) =>
                        record?.ranking ? record?.ranking : "-",
                    },
                    {
                      title: (
                        <>
                          Expected Enrollment <br />
                          Start Date
                        </>
                      ),
                      dataIndex: "startDate",
                      key: "startDate",
                      render: (text, record) =>
                        record?.startDate ? formatDate(record?.startDate) : "-",
                    },
                    {
                      title: "Waiting List Created At",
                      dataIndex: "waitingListCreatedAt",
                      key: "waitingListCreatedAt",
                      render: (text, record) =>
                        record?.waitingListCreatedAt
                          ? formatDate(record?.waitingListCreatedAt)
                          : "-",
                    },
                  ]}
                />
              </div>
            ),
            onCancel: () => {
              setTimeout(() => {
                reject();
              }, 200);
              checkWaitingListConfirmModal.destroy();
            },
            onOk: () => {
              setTimeout(() => {
                resolve(true);
              }, 200);
              checkWaitingListConfirmModal.destroy();
            },
          });
        } else {
          resolve(true);
        }
      };
      check();
    });
  return {
    checkOfferSeatToWaitingListWarning,
    modal,
    isCheckShouldOfferSeatToWaitingListWarning: loading,
  };
};

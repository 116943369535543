import { actions } from "@/MicroAppStateActions";
import { AppConfig } from "@/config";
import { useAuth0Client } from "@thepiquelab/web-auth0";
import useEventSource from "@thepiquelab/web-sse";
import {
  Registration,
  SubscriptionData,
  SubscriptionType,
} from "@thepiquelab/web-types";
import { useRef, useState } from "react";

const useRegistrationEventSource = () => {
  const { getToken } = useAuth0Client();
  const evtSource = useRef<EventSource>();
  const [data, setData] = useState<Registration>();

  const { init } = useEventSource();

  const initEventSource = async () => {
    const authorization = await getToken(actions);
    const url = `${AppConfig.CsdControllerServiceUrl}/devices/listener`;

    init({
      url,
      authorization,
      onMessage(e) {
        const res: string = e?.data || "";
        if (res.includes(`"type":`)) {
          const subscriptionData: SubscriptionData<Registration> =
            JSON.parse(res);

          if (subscriptionData.type === SubscriptionType.Registration) {
            setData(subscriptionData.data);
          }
        }
      },
    });
  };

  return {
    data,
    initEventSource,
  };
};

export default useRegistrationEventSource;

import { Popover } from "@thepiquelab/archus-components-web";
import React from "react";
import AssigneeAvatar from "./AssigneeAvatar";

interface AssigneeAvatarsProps {
  assignees: {
    _id: string;
    initials?: string;
    fullName?: string;
    avatarUrl?: string;
  }[];
}

const AssigneeAvatars: React.FC<AssigneeAvatarsProps> = (props) => {
  const { assignees } = props;
  return (
    <div className="flex gap-2 flex-wrap items-center">
      {assignees?.length > 0 ? (
        assignees
          ?.slice(0, 3)
          .map((assignee) => <AssigneeAvatar assignee={assignee} />)
      ) : (
        <></>
      )}
      {assignees?.length > 3 && (
        <Popover
          content={
            <div className="flex gap-2 flex-wrap">
              {assignees
                ?.slice(3, assignees?.length)
                .map((assignee) => <AssigneeAvatar assignee={assignee} />)}
            </div>
          }
        >
          +{assignees?.length - 3}...
        </Popover>
      )}
    </div>
  );
};

export default AssigneeAvatars;

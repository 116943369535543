import { ReactComponent as IconCopy } from "@/assets/images/IconCopyFull.svg";
import { ReactComponent as IconPaperClip } from "@/assets/images/IconPaperClip.svg";
import {
  controls,
  formatVariableName,
  isUndefined,
  removeBraftEditorClassName,
} from "@/components/settings/Template/tools";
import {
  BasicTemplateFieldsFragment,
  BulkSendReminderInput,
  GetReminderFormParentQuery,
  ReminderType,
  SendReminderInput,
} from "@/graphql";
import formatLabel from "@/utils/formatLabel";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormInstance,
  Input,
  Select,
  Upload,
  UploadChangeParam,
  message,
} from "@thepiquelab/archus-components-web";
import BraftEditor, { ControlType } from "braft-editor";
import { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ContentUtils } from "braft-utils";

import VariableName from "@/components/settings/Template/VariableName";
import useCopy from "@/hooks/dom/useCopy";
import { useSalutation } from "@/hooks/reference.hook";
import { download } from "@/utils/download";
import formatErrorMessage from "@/utils/formatErrorMessage";
import { ExportOutlined } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import "braft-editor/dist/index.css";
import {
  handleCopyAllReminder,
  handleExportReminder,
} from "../PreviewMessage/Utils";
import ReceiverCheckBox from "../common/ReceiverCheckbox";
import {
  useCompileTemplate,
  useLazyQueryBulkExportReminderDocx,
  useLazyQueryExportReminderDocx,
} from "../hooks";
import { RichTextEditorType, richTextEditorValidator } from "../utils";

interface EmailFormProps {
  form?: FormInstance;
  parent?: GetReminderFormParentQuery["parent"];
  templates?: BasicTemplateFieldsFragment[];
  attachmentLabel?: string;
  isShowSendInvoice?: boolean;
  showPreview?: boolean;
  onPreview?: (isEmail: boolean) => void;
  isShowPreview?: boolean;
  isBulk: boolean;
  partialSendReminderInput: Partial<SendReminderInput>;
  formatCompileInput: (
    isEmail: boolean
  ) => SendReminderInput | BulkSendReminderInput;
}

const messageKey = "tasks";
const EmailForm: React.FC<EmailFormProps> = (props) => {
  const {
    form,
    parent,
    templates,
    showPreview,
    attachmentLabel = "Invoice",
    isShowSendInvoice = true,
    onPreview,
    isBulk = false,
    partialSendReminderInput,
    formatCompileInput,
  } = props;
  const emailTemplateId = Form.useWatch("emailTemplateId", form);
  const [fileItem, setFileItem] = useState(null);

  const { copy } = useCopy();
  const { formatSalutation } = useSalutation();
  const [
    compileTemplate,
    { data: compiledData, loading: compiledDataLoading },
  ] = useCompileTemplate((error: ApolloError) => {
    message.error(formatErrorMessage(error));
  });

  const [ExportReminderDocx, { data: exportData, loading: exportLoading }] =
    useLazyQueryExportReminderDocx({
      fetchPolicy: "network-only",
      onCompleted() {
        download(exportData?.exportReminderDocx, messageKey);
      },
      onError() {
        message.error({
          content: "Export was unsuccessful",
          key: messageKey,
        });
      },
    });
  const [
    bulkExportReminderDocx,
    { data: bulkExportData, loading: bulkExportLoading },
  ] = useLazyQueryBulkExportReminderDocx({
    fetchPolicy: "network-only",
    onCompleted() {
      download(bulkExportData?.bulkExportReminderDocx, messageKey);
    },
    onError() {
      message.error({
        content: "Export was unsuccessful",
        key: messageKey,
      });
    },
  });

  const selectedTemplate = useMemo(() => {
    if (!emailTemplateId) {
      return null;
    }
    return templates?.find((t) => t.id === emailTemplateId);
  }, [templates, emailTemplateId]);

  let emailControls: ControlType[] = [...controls];
  if (isBulk) {
    emailControls = [
      ...emailControls,
      {
        key: "email-variable-dropdown",
        type: "dropdown",
        text: "Add variables",
        autoHide: true,
        className: "email-variable-dropdown",
        component: (
          <VariableName
            usageType={selectedTemplate?.usageType}
            onClick={(name) => {
              const editor = form?.getFieldValue("emailMessage");
              if (editor) {
                form?.setFieldsValue({
                  emailMessage: ContentUtils.insertText(editor, name),
                });
              } else {
                form?.setFieldsValue({
                  emailMessage: ContentUtils.insertText(
                    BraftEditor.createEditorState(null),
                    name
                  ),
                });
              }
              removeBraftEditorClassName("email-variable-dropdown");
            }}
          />
        ),
      },
    ];
  }

  const handleUploadChange = (info: UploadChangeParam): void => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileItem(fileList[0]);
  };

  const updatedByTemplate = (template: BasicTemplateFieldsFragment): void => {
    const { subject, emailMessage } = template;
    const emailMessageFormatted = formatVariableName(emailMessage, false);
    if (!isBulk) {
      form.setFieldsValue({
        emailTemplateId: template?.id,
      });
      return;
    }
    form.setFieldsValue({
      subject: formatVariableName(subject, false),
      emailMessage: BraftEditor.createEditorState(emailMessageFormatted),
      emailTemplateId: template?.id,
    });
  };

  const handleEmailTemplateChange = async (
    templateId: string
  ): Promise<void> => {
    if (templateId && templates?.length) {
      const template = templates.find(({ id }) => id === templateId);
      updatedByTemplate(template);
    }
  };

  const handleExport = async (type: ReminderType): Promise<void> => {
    const emailMessage =
      form?.getFieldValue("emailMessage") || selectedTemplate?.emailMessage;
    handleExportReminder({
      type,
      input: {
        ...formatCompileInput(true),
        ...partialSendReminderInput,
      },
      emailInput: {
        subject: form?.getFieldValue("subject") || selectedTemplate?.subject,
        message: isUndefined(formatVariableName(emailMessage?.toHTML?.())),
        templateId: selectedTemplate.id,
      },
      isBulk,
      bulkExportReminderDocx,
      ExportReminderDocx,
    });
  };
  const handleCopyAll = (type: ReminderType): void => {
    let emailMessage =
      form?.getFieldValue("emailMessage") || selectedTemplate?.emailMessage;
    emailMessage = isUndefined(formatVariableName(emailMessage?.toHTML?.()));
    handleCopyAllReminder({
      type,
      compiledData: [
        {
          ...compiledData.compileTemplate,
          emailMessage,
        },
      ],
      copy,
      formatSalutation,
      isBulk,
    });
  };
  useEffect(() => {
    if (templates?.length) {
      const template = templates[0];
      updatedByTemplate(template);
    }
  }, [templates]);

  useEffect(() => {
    if (selectedTemplate && !isBulk) {
      compileTemplate({
        variables: {
          input: {
            types: [ReminderType.Email],
            emailInput: {
              subject: selectedTemplate?.subject,
              message: selectedTemplate?.emailMessage,
              templateId: selectedTemplate.id,
            },
            ...partialSendReminderInput,
          },
        },
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    const emailMessage = compiledData?.compileTemplate?.emailMessage;
    const subject = compiledData?.compileTemplate?.subject;
    if (emailMessage) {
      form.setFieldsValue({
        emailMessage: BraftEditor.createEditorState(emailMessage),
      });
    }
    if (subject) {
      form.setFieldsValue({
        subject,
      });
    }
  }, [compiledData]);

  return (
    <div className="p-4 leading-tight text-4">
      {parent ? (
        <Form.Item
          name={"emailReceiveIds"}
          label="To "
          colon
          rules={[
            {
              required: true,
              message: formatLabel("Field is required."),
            },
          ]}
          initialValue={[parent?.id]}
        >
          <ReceiverCheckBox
            primaryParent={parent}
            parents={parent?.relatedParents}
            reminderType={ReminderType.Email}
          />
        </Form.Item>
      ) : null}
      <Divider />
      <Form.Item
        name="emailTemplateId"
        label={formatLabel("Template")}
        rules={[
          {
            required: true,
            message: "Field is required.",
          },
        ]}
      >
        <Select
          // loading={templatesLoading}
          allowClear
          onChange={handleEmailTemplateChange}
        >
          {templates?.map((t) => (
            <Select.Option key={t.id} value={t.id}>
              {formatLabel(t.title)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={"subject"}
        label={formatLabel("Subject ")}
        colon
        rules={[{ required: true, message: formatLabel("Field is required.") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"emailMessage"}
        label={formatLabel("Message")}
        rules={[
          {
            validator: () =>
              richTextEditorValidator(RichTextEditorType.emailMessage, form),
          },
        ]}
        colon
      >
        <BraftEditor
          language={"en"}
          className="bg-gray-200 border border-gray-500 rounded-default font-arial"
          controls={emailControls}
        />
      </Form.Item>
      <Form.Item
        className="mb-0"
        wrapperCol={{
          span: 21,
          offset: 3,
        }}
      >
        <div className="flex items-center justify-between font_regular text-4 text-primary-blue">
          <Form.Item
            name="attachment"
            valuePropName="attachment"
            className="flex items-start justify-start ml-2 mb-0"
          >
            {fileItem ? (
              <div
                className="flex items-center justify-start mr-5  truncate cursor-pointer text-4 text-primary-blue"
                onClick={() => setFileItem(null)}
              >
                <IconPaperClip className="text-primary-blue" />
                <div className="truncate" style={{ maxWidth: "160px" }}>
                  {fileItem.name}
                </div>
              </div>
            ) : (
              <Upload
                name="attachmentFile"
                multiple={false}
                fileList={null}
                onChange={handleUploadChange}
                beforeUpload={() => false}
              >
                <div>
                  <div className="flex items-center justify-start mr-6  truncate font_regular text-4 text-primary-blue">
                    <IconPaperClip className="text-primary-blue" />
                    <div className="truncate">{formatLabel("Attach file")}</div>
                  </div>
                </div>
              </Upload>
            )}
          </Form.Item>
          <div className="flex items-center justify-end gap-x-2">
            {showPreview && isBulk && (
              <div className="flex items-center">
                <Button
                  onClick={() => {
                    onPreview(true);
                  }}
                >
                  Preview
                </Button>
              </div>
            )}
            {!isBulk && (
              <>
                <Button
                  icon={<IconCopy className="mr-2" />}
                  onClick={() => {
                    handleCopyAll(ReminderType.Email);
                  }}
                >
                  Copy
                </Button>
                <Button
                  icon={<ExportOutlined className="mr-2" />}
                  loading={exportLoading}
                  onClick={() => {
                    handleExport(ReminderType.Email);
                  }}
                >
                  Export
                </Button>
              </>
            )}
          </div>
        </div>
      </Form.Item>
      {isShowSendInvoice ? (
        <Form.Item
          label={formatLabel(" ")}
          name="isSendInvoice"
          valuePropName="checked"
          style={{ margin: 0 }}
          initialValue
          // initialValue={!!templates?.templates?.items?.[0]?.id}
        >
          <Checkbox className="ml-2 text-primary-blue">
            {`Attach ${attachmentLabel}`}
          </Checkbox>
        </Form.Item>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmailForm;

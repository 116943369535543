import { Calendar, Col, Row, Select } from "@thepiquelab/archus-components-web";
import moment, { Dayjs, Moment } from "@thepiquelab/web-moment";
import { max, maxBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

export interface Props {
  availableDates: Moment[];
  registrationStartDate?: Date;
  onSelect: (v: Date) => void;
}

const StartDateCalendar: React.FC<Props> = ({
  availableDates,
  onSelect,
  registrationStartDate,
}) => {
  const dates = useMemo(() => availableDates, [availableDates]);
  const years = useMemo(
    () => [...new Set(dates.map((i) => i.year()))].sort((a, b) => a - b),
    [dates]
  );
  const months = useMemo(
    () => [...new Set(dates.map((i) => i.month()))].sort((a, b) => a - b),
    [dates]
  );
  const [calendarValue, setCalendarValue] = useState<Dayjs>();

  useEffect(() => {
    if (!calendarValue && dates?.length) {
      const d = dates.find((date) =>
        date.isSameOrAfter(moment(registrationStartDate), "day")
      );
      setCalendarValue(
        d?.toDayjs() || maxBy(dates, (date) => date.unix()).toDayjs()
      );
      onSelect(d ? d?.toDate() : maxBy(dates, (date) => date.unix())?.toDate());
    }
  }, [calendarValue, dates, registrationStartDate]);

  const handleDisabledDate = (currentDate: Dayjs): boolean =>
    !dates.find((date) => date.isSame(moment(currentDate), "day"));

  return (
    <div className="w-auto mt-2">
      <Calendar
        value={calendarValue}
        disabledDate={handleDisabledDate}
        fullscreen={false}
        onSelect={(v) => {
          setCalendarValue(v);
          onSelect(v.toDate());
        }}
        headerRender={({ value, onChange }) => (
          <Row gutter={24} className="mb-6">
            <Col span={12}>
              <Select
                className="w-full"
                placeholder="Please select"
                size={"middle"}
                dropdownMatchSelectWidth={false}
                onChange={(selectedMonth: number) => {
                  const date = dates.find((d) =>
                    d.isSameOrAfter(moment(value).month(selectedMonth), "month")
                  );
                  onChange(date.toDayjs());
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                value={
                  calendarValue
                    ? moment(value).month()
                    : moment().month(max(months)).month()
                }
              >
                {months.map((monthNum) => (
                  <Select.Option key={monthNum} value={monthNum}>
                    {moment().month(monthNum).format("MMMM")}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={12}>
              <Select
                placeholder="Please select"
                className="w-full"
                size={"middle"}
                dropdownMatchSelectWidth={false}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(newYear): void => {
                  const date = dates.find((d) =>
                    d.isSameOrAfter(moment(value).year(newYear), "year")
                  );
                  onChange(date.toDayjs());
                }}
                value={moment(value).year()}
              >
                {years.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
      />
    </div>
  );
};

export default StartDateCalendar;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  FollowUpStatus,
  GetEnrollmentWithFollowUpStatus,
  GetEnrollmentWithFollowUpStatusQuery,
  GetEnrollmentWithFollowUpStatusQueryVariables,
  RegistrationsStatus,
  UpdateEnrollmentFollowUpStatus,
  UpdateEnrollmentFollowUpStatusMutation,
  UpdateEnrollmentFollowUpStatusMutationVariables,
} from "@/graphql";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Select, message } from "@thepiquelab/archus-components-web";
import { formatDateTime } from "@thepiquelab/web-moment";
import { useEffect } from "react";

export enum FollowUpType {
  "Reservation" = "Reservation",
  "Trial" = "Trial",
  "Workshop" = "Workshop",
}

const ReservationStatusOptions = [
  {
    label: formatLabel(FollowUpStatus.Pending),
    value: FollowUpStatus.Pending,
  },
  {
    label: formatLabel(FollowUpStatus.FollowUpSent),
    value: FollowUpStatus.FollowUpSent,
  },
];

const TrialStatusOptions = [
  {
    label: "1st Follow Up Sent",
    value: FollowUpStatus.FirstFollowUpSent,
  },
  {
    label: "2nd Follow Up Sent",
    value: FollowUpStatus.SecondFollowUpSent,
  },
  {
    label: formatLabel(FollowUpStatus.Pending),
    value: FollowUpStatus.Pending,
  },
  {
    label: formatLabel(FollowUpStatus.Enrolled),
    value: FollowUpStatus.Enrolled,
  },
  {
    label: formatLabel(FollowUpStatus.ScheduleCall),
    value: FollowUpStatus.ScheduleCall,
  },
  {
    label: formatLabel(FollowUpStatus.SeatReleased),
    value: FollowUpStatus.SeatReleased,
  },
];

const WorkshopStatusOptions = [
  {
    label: formatLabel(FollowUpStatus.Pending),
    value: FollowUpStatus.Pending,
  },
];

/**
 * @param enrollmentId required if no enrollment provided
 * @param enrollment required if no enrollmentId provided
 */
export interface FollowUpStatusSelectProps {
  enrollmentId?: string;
  enrollment?: {
    id: string;
    followUpStatus: FollowUpStatus;
    followUpStatusLastModifiedBy: {
      fullName: string;
    };
    followUpStatusLastModifiedDateTime: Date;
  };
  showLabel?: boolean;
  registrationStatus?: RegistrationsStatus;
  emptyLabel?: string;
  className?: string;
  followUpType: FollowUpType;
  callback?: () => void;
  label?: string;
  disabled?: boolean;
}

const FollowUpStatusSelect = (props: FollowUpStatusSelectProps) => {
  const {
    enrollment,
    enrollmentId,
    showLabel = false,
    registrationStatus,
    emptyLabel = "",
    className = "",
    label = "Reservation Follow Up Status:",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback,
    followUpType,
    disabled,
  } = props;
  const [updateEnrollmentFollowUpStatus] = useMutation<
    UpdateEnrollmentFollowUpStatusMutation,
    UpdateEnrollmentFollowUpStatusMutationVariables
  >(UpdateEnrollmentFollowUpStatus);

  const [getDetail, { data: enrollmentDetail, loading }] = useLazyQuery<
    GetEnrollmentWithFollowUpStatusQuery,
    GetEnrollmentWithFollowUpStatusQueryVariables
  >(GetEnrollmentWithFollowUpStatus, {
    fetchPolicy: "network-only",
  });

  const enrollmentData = enrollmentDetail?.enrollment || enrollment;
  useEffect(() => {
    if (enrollmentId) {
      getDetail({
        variables: {
          enrollmentId,
        },
      });
    }
  }, [enrollmentId]);

  if (
    followUpType === FollowUpType.Reservation &&
    registrationStatus &&
    registrationStatus !== RegistrationsStatus.Reserved
  ) {
    return <div className={className}>{emptyLabel}</div>;
  }
  const optionsMap = {
    [FollowUpType.Reservation]: ReservationStatusOptions,
    [FollowUpType.Trial]: TrialStatusOptions,
    [FollowUpType.Workshop]: WorkshopStatusOptions,
  };

  const lastModifiedBy = enrollmentData?.followUpStatusLastModifiedBy?.fullName;

  const lastModifyDateTime = enrollmentData?.followUpStatusLastModifiedDateTime;

  return (
    <>
      {showLabel && <div className="mb-2">{label}</div>}
      <Select
        loading={loading}
        value={enrollmentData?.followUpStatus}
        className={`${className}`}
        disabled={disabled}
        onChange={async (value) => {
          try {
            await updateEnrollmentFollowUpStatus({
              variables: {
                input: {
                  id: enrollmentData?.id,
                  followUpStatus: value,
                },
              },
            });
            callback?.();
            if (enrollmentId) {
              getDetail({
                variables: {
                  enrollmentId,
                },
              });
            }
          } catch (error) {
            message.error(formatErrorMessage(error));
          }
        }}
        options={optionsMap[followUpType]}
      />
      {lastModifyDateTime ? (
        <div className="text-primary-navyLight mt-2">
          {`Last Modified By ${
            lastModifiedBy ? `${lastModifiedBy}` : ""
          }, at ${formatDateTime(lastModifyDateTime, {
            format: "SHORT",
          })}`}
        </div>
      ) : null}
    </>
  );
};

export default FollowUpStatusSelect;

import { ReactComponent as IconDelete } from "@/assets/images/IconDelete.svg";
import { ReactComponent as IconEditPan } from "@/assets/images/IconEdit.svg";
import { ReactComponent as IconGear } from "@/assets/images/IconGear.svg";
import { EnumPermissions } from "@/components/authorize/Permissions";
import { useConfirmModal } from "@/components/common/ConfirmModal/ConfirmModal";
import DropdownAction, {
  Action,
} from "@/components/common/DropdownAction/DropdownAction";
import PaymentRecordModalContentSkeleton from "@/components/common/Skeleton/FinanceSkeleton/PaymentRecordModalContentSkeleton";
import InvoiceDetailSkeleton from "@/components/common/Skeleton/ReceiptDetailSkeleton";
import { prevWhatsAppMessage } from "@/components/settings/Template/tools";
import AddFeesButton from "@/components/widget/Bill/AddFees/AddFeesButton";
import InvoiceDueDate from "@/components/widget/Bill/InvoiceDueDate/InvoiceDueDate";
import LineItemsModal from "@/components/widget/Bill/LineItemsModal";
import { LineItemsModalType } from "@/components/widget/Bill/LineItemsModal/types";
import BillContact from "@/components/widget/Bill/common/BillContact";
import BillLineItems, {
  BillLineItemsProps,
  TotalItem,
} from "@/components/widget/Bill/common/BillLineItems";
import BillTitle from "@/components/widget/Bill/common/BillTitle";
import {
  DiscountType,
  GetPaymentRecordModalData,
  GetPaymentRecordModalDataQuery,
  GetPaymentRecordModalDataQueryVariables,
  InvoiceStatus,
  LineItemFieldsFragment,
  RemoveLineItemDiscount,
  RemoveLineItemDiscountMutation,
  RemoveLineItemDiscountMutationVariables,
} from "@/graphql";
import { useAccessControl } from "@/hooks/useAccessControl";
import formatErrorMessage from "@/utils/formatErrorMessage";
import formatLabel from "@/utils/formatLabel";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Col, Row, message } from "@thepiquelab/archus-components-web";
import { formatCurrency } from "@thepiquelab/phonenumber";
import BigNumber from "bignumber.js";
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLazyQueryInvoice } from "../../FinanceHooks";
import BillingItemCell from "../../FinanceOfAccount/common/BillingItemCell";
import PaymentRecordModalContent from "../../PaymentRecordModal/PaymentRecordModalContent";
import InvoiceButtons from "./InvoiceButtons";
import { InvoiceActionType, InvoiceDetailType } from "./InvoiceDetailTypes";
import { InvoiceLineItemDO } from "./InvoiceLineItemUtil";
import useHandleInvoiceDetailData from "./useDataLogic";

const formatAmount = (str: string): string =>
  str?.replaceAll("$", "") < "0" ? "0" : str;

type InvoiceDetailProps = {
  invoiceId?: string;
  invoiceItem?: InvoiceDetailType;
  showBillTitle?: boolean;
  dueDateDisabled?: boolean;
  showPaymentStatus?: boolean;
  className?: string;
  skipActions?: boolean;
  showReferenceNumber?: boolean;
  isShowDueDate?: boolean;
  callback?: (type?: InvoiceActionType) => void;
  noSolidWidth?: boolean;
  showButtons?: boolean;
  showPaymentRecord?: boolean;
};

// const Toggle: ForwardRefRenderFunction<any, ToggleProps> = (props, ref) => {

const InvoiceDetail: ForwardRefRenderFunction<any, InvoiceDetailProps> = (
  props: InvoiceDetailProps,
  ref: any
): React.ReactElement => {
  const {
    invoiceId,
    invoiceItem,
    showBillTitle,
    className = "",
    skipActions,
    showReferenceNumber = true,
    isShowDueDate = false,
    callback,
    dueDateDisabled,
    showPaymentStatus = true,
    noSolidWidth = true,
    showButtons = true,
    showPaymentRecord = true,
  } = props;

  const { modal, setModalProps, show, close } = useConfirmModal();
  const [removeDiscount] = useMutation<
    RemoveLineItemDiscountMutation,
    RemoveLineItemDiscountMutationVariables
  >(RemoveLineItemDiscount);
  const [modalInfo, setModalInfo] = useState<{
    visible: boolean;
    type: LineItemsModalType | null;
  }>();

  const [
    getInvoice,
    { data: invoiceDetail, loading: detailLoading, refetch: refetchInvoice },
  ] = useLazyQueryInvoice();

  const [paymentRecordStatus, setPaymentRecordStatus] =
    useState<string>("paid");

  const [
    queryRecord,
    { data, loading: recordLoading, refetch: refetchRecord },
  ] = useLazyQuery<
    GetPaymentRecordModalDataQuery,
    GetPaymentRecordModalDataQueryVariables
  >(GetPaymentRecordModalData, { fetchPolicy: "no-cache" });

  let item = invoiceItem || invoiceDetail?.invoice;
  item = useHandleInvoiceDetailData(item);

  const [lineItem, setLineItem] = useState<LineItemFieldsFragment>();

  const menus = (l: LineItemFieldsFragment): Action[] => [
    {
      icon: <IconDelete />,
      label: formatLabel("Remove"),
      onClick: () => {
        setModalInfo({ visible: true, type: "remove" });
        setLineItem(l);
      },
    },
    {
      icon: <IconGear />,
      label: formatLabel("discount"),
      onClick: () => {
        setModalInfo({ visible: true, type: "discount" });
        setLineItem(l);
      },
    },
    {
      icon: <IconEditPan />,
      label: formatLabel("Edit"),
      onClick: () => {
        setModalInfo({ visible: true, type: "edit" });
        setLineItem(l);
      },
    },
  ];

  const subMenus = (l: LineItemFieldsFragment): Action[] => [
    {
      icon: <IconDelete />,
      label: formatLabel("Remove"),
      onClick: () => {
        setModalProps({
          onConfirm: () => handleRemoveDiscount(l.id),
          title: "Remove Discount",
          children: "Are you sure you want to remove discount from this item?",
          onCancel: close,
        });
        show();
      },
    },
  ];

  const handleRemoveDiscount = async (id: string): Promise<void> => {
    try {
      await removeDiscount({
        variables: { lineItemId: id },
      });
      refreshAndCallback();
    } catch (e) {
      message.error(formatErrorMessage(e));
    }
    close();
  };

  const isIncludesItemRemarks = useMemo(
    () => item?.lineItems?.some((i) => i.remarks),
    [item]
  );

  const fields: BillLineItemsProps<LineItemFieldsFragment>["fields"] = [
    {
      title: "item",
      className: "w-80",
      textAlign: "text-left",
      render: (line: any) => (
        // Modify Enrollment from Registration, it will cause UI issue
        <BillingItemCell
          lineItem={line}
          holidays={item?.arrangement?.fromLesson?.holidays}
          enrollmentType={item?.destination?.type}
          detailsType={item?.isOtherFee ? "class" : "lesson"}
          isAdhocAdditionalLesson={item?.arrangement?.isAdhocAdditionalLesson}
        />
      ),
    },
    {
      title: "price",
      className: isIncludesItemRemarks ? "w-32" : "w-40",
      textAlign: "text-center",
      render: (line: any) => {
        if (item?.isImported) {
          return <td className="text-center align-top py-4">-</td>;
        }
        const invoiceLineItemDO = new InvoiceLineItemDO(line);
        const company = invoiceLineItemDO.getBillingFrequency(item?.isOtherFee);

        return (
          <td className="text-center align-top py-4">
            {`${formatCurrency(line.unitAmount)}${company}`}
          </td>
        );
      },
    },
    {
      title: "Qty",
      className: isIncludesItemRemarks ? "w-24" : "w-40",
      textAlign: "text-center",
      render: (line: any) => {
        if (item?.isImported) {
          return <td className="text-center align-top py-4">-</td>;
        }
        return <td className="text-center align-top py-4">{line?.quantity}</td>;
      },
    },
    isIncludesItemRemarks
      ? {
          title: "Remarks",
          className: "w-48",
          textAlign: "text-left",
          titleClassName: "pl-6",
          render: (line: any) => (
            <td className="align-top py-4 pl-6">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: prevWhatsAppMessage(line?.remarks || ""),
                }}
              />
            </td>
          ),
        }
      : null,
    {
      title: "total",
      className: isIncludesItemRemarks ? "w-24" : "w-48",
      textAlign: "text-center",
      render: (line) => (
        <td className="text-center align-top py-3 text-primary-navyDark font_bold  text-lg">
          {formatCurrency(line.lineAmount)}
        </td>
      ),
    },
  ];

  const subFields: BillLineItemsProps<LineItemFieldsFragment>["subFields"] = [
    {
      render: (line) =>
        line.discountAmount || line.discountRate ? (
          <td className="align-top py-2 font_semibold text-lg min-w-80">
            <>
              <div>{formatLabel("Discount")}</div>
              <div className="text-gray-500">{line.discountRemarks || ""}</div>
            </>
          </td>
        ) : null,
    },
    {
      render: (line) =>
        line.discountAmount || line.discountRate ? (
          <td className="text-center py-2 align-top">
            {line.discountType === DiscountType.Percentage
              ? `${Number(line.discountRate)}%`
              : formatCurrency(line.discountAmount)}
          </td>
        ) : null,
    },
    {
      render: (line) =>
        line.discountAmount || line.discountRate ? (
          <td className="text-center py-2 align-top">{`-`}</td>
        ) : null,
    },
    isIncludesItemRemarks
      ? {
          render: () => (
            <td className="text-left py-2 align-top pl-6">{`-`}</td>
          ),
        }
      : null,
    {
      render: (line) => {
        const invoiceLineItemDO = new InvoiceLineItemDO(line);
        return (
          <td className="text-center py-2 text-lg font_bold align-top">
            {invoiceLineItemDO.getDiscountedPrice()}
          </td>
        );
      },
    },
  ];

  const solidWidthClass = noSolidWidth ? "" : "w-a4";
  const isStatusPaidOrPendingOrOverdue =
    [InvoiceStatus.Paid, InvoiceStatus.Pending, InvoiceStatus.Overdue].includes(
      item?.status
    ) && showPaymentRecord;

  const refreshAndCallback = (type?: InvoiceActionType): void => {
    callback?.(type);
    refetchInvoice({ id: invoiceId });
    if (isStatusPaidOrPendingOrOverdue) {
      refetchRecord({ invoiceId });
    }
  };
  const { hasAllPermissions } = useAccessControl();
  const paymentViewAuth = hasAllPermissions([EnumPermissions.PAYMENT_VIEW]);

  const showPaymentRecordContent = (): JSX.Element => {
    if (!paymentViewAuth) {
      return null;
    }
    return (
      isStatusPaidOrPendingOrOverdue && (
        <>
          <Col span={8} className="border-l border-gray-400">
            {/* Collect Fees */}
            {data?.invoice?.id ? (
              <PaymentRecordModalContent
                visible
                data={data}
                loading={recordLoading}
                refetch={refetchRecord}
                invoiceId={item?.id}
                onOk={async () => {
                  refreshAndCallback();
                }}
                paymentRecordStatus={paymentRecordStatus}
                setPaymentRecordStatus={setPaymentRecordStatus}
                verticalAlign
                ref={ref}
              />
            ) : (
              <PaymentRecordModalContentSkeleton />
            )}
          </Col>
        </>
      )
    );
  };

  const showInvoiceDueDate = () =>
    ([InvoiceStatus.Pending, InvoiceStatus.Overdue].includes(item?.status) ||
      isShowDueDate) && (
      <InvoiceDueDate
        dueDateDisabled={dueDateDisabled}
        invoiceId={item?.id}
        className="mt-6"
        callback={() => {
          refreshAndCallback();
        }}
      />
    );

  const showAddFeesButtons = () => {
    const isVoidOrPaid = [InvoiceStatus.Void, InvoiceStatus.Paid].includes(
      item?.status
    );
    const amountPaidIs0 = new BigNumber(item?.amountPaid).eq(0);
    return !isVoidOrPaid && !skipActions && amountPaidIs0
      ? [
          <AddFeesButton
            invoiceId={item?.id}
            callback={() => {
              refreshAndCallback();
            }}
          />,
        ]
      : [];
  };

  const showButtonsNode = () =>
    showButtons ? (
      <InvoiceButtons
        invoiceId={item?.id}
        status={item?.status}
        isOtherFee={item?.isOtherFee}
        isOnHold={item?.isOnHold}
        callback={async (type: InvoiceActionType) => {
          refreshAndCallback(type);
        }}
        invoice={item}
      />
    ) : (
      <></>
    );

  const isLoading = !invoiceItem && detailLoading;

  useEffect(() => {
    if (invoiceId) {
      getInvoice({
        variables: {
          id: invoiceId,
        },
      });
    }
  }, [invoiceId]);

  useEffect(() => {
    if (isStatusPaidOrPendingOrOverdue && item?.id) {
      queryRecord({
        variables: {
          invoiceId: item?.id,
        },
      });
    }
  }, [isStatusPaidOrPendingOrOverdue, item?.id]);

  const colSpan = (): number => {
    if (!paymentViewAuth) {
      return 24;
    }
    return isStatusPaidOrPendingOrOverdue ? 16 : 24;
  };

  if (isLoading) {
    return (
      <div className="h-60vh align-middle text-center mt-4">
        <InvoiceDetailSkeleton />
      </div>
    );
  }

  return (
    <div
      className={`px-6 ${solidWidthClass} flex flex-col bg-white rounded-default ${className}`}
    >
      <Row>
        <Col
          span={colSpan()}
          className={isStatusPaidOrPendingOrOverdue ? "pr-6" : ""}
        >
          {showButtonsNode()}
          {showBillTitle && (
            <BillTitle
              date={new Date(item?.createDateTime)}
              title={showReferenceNumber ? item?.referenceNumber : ""}
              status={item?.status}
              showPaymentStatus={showPaymentStatus}
              showVoidStatus
              showOnHoldStatus={item?.isOnHold}
              subTitle={formatLabel("invoice")}
            />
          )}
          <div className="flex justify-between">
            <BillContact parent={item?.billedTo} />
            {showInvoiceDueDate()}
          </div>
          <BillLineItems<LineItemFieldsFragment>
            student={item?.billedFor}
            lineItems={item?.lineItems as LineItemFieldsFragment[]}
            totalItems={[
              !item?.isImported && (
                <TotalItem
                  text={`${formatLabel("Subtotal without")} GST`}
                  total={formatAmount(item?.subtotal)}
                />
              ),
              !item?.isImported && (
                <TotalItem
                  text={`GST (${item?.taxRate || ""}%)`}
                  total={formatAmount(item?.totalTax)}
                />
              ),
              <TotalItem
                text={`${formatLabel("Total amount payable")}`}
                total={formatAmount(item?.total)}
                bold
              />,
            ]?.filter(Boolean)}
            actionItems={showAddFeesButtons()}
            fields={fields.filter(Boolean)}
            fieldsAction={(l: LineItemFieldsFragment) =>
              [
                InvoiceStatus.Draft,
                InvoiceStatus.Pending,
                InvoiceStatus.Overdue,
              ].includes(item?.status) &&
              Number(item?.amountPaid) === 0 &&
              !skipActions && <DropdownAction id={null} actions={menus(l)} />
            }
            subFields={subFields?.filter(Boolean)}
            subFieldsAction={(l: LineItemFieldsFragment) =>
              [
                InvoiceStatus.Draft,
                InvoiceStatus.Pending,
                InvoiceStatus.Overdue,
              ].includes(item?.status) &&
              Number(item?.amountPaid) === 0 &&
              (l.discountAmount || l.discountRate) && (
                <DropdownAction id={null} actions={subMenus(l)} />
              )
            }
          />
          {modalInfo && lineItem && (
            <LineItemsModal
              lineItem={lineItem}
              type={modalInfo.type}
              visible={modalInfo.visible}
              setVisible={(v: boolean) => {
                setModalInfo({ ...modalInfo, visible: v });
              }}
              taxRatePercentage={item?.taxRate}
              callback={() => {
                refreshAndCallback();
              }}
            />
          )}
          {modal}
        </Col>
        {showPaymentRecordContent()}
      </Row>
    </div>
  );
};

export default forwardRef(InvoiceDetail);

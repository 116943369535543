import { PureQueryOptions, RefetchQueriesFunction } from "@apollo/client";
import { Typography } from "@thepiquelab/archus-components-web";
import { formatCurrency } from "@thepiquelab/phonenumber";
import { formatDate } from "@thepiquelab/web-moment";
import BigNumber from "bignumber.js";
import React from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceStatus } from "../../../../graphql";
import formatLabel from "../../../../utils/formatLabel";
import ExpandableCard from "../../../common/ExpandableCard/ExpandableCard";
import Progress from "../../../common/Progress";
import { invoiceTransform } from "../../../common/Progress/progressStepsHelper";
import InvoiceDetail from "./InvoiceDetail";
import { InvoiceDetailType } from "./InvoiceDetailTypes";

export const InvoiceStatusMap = {
  [InvoiceStatus.Pending]: { text: "Pending", color: "#5B9DFF" },
  [InvoiceStatus.Paid]: { text: "Paid", color: "#2BD832" },
  [InvoiceStatus.Overdue]: { text: "Overdue", color: "#F36B7F" },
  [InvoiceStatus.Void]: { text: "Void", color: "#F36B7F" },
  [InvoiceStatus.Draft]: { text: "Draft", color: "#8C9297" },
  OnHold: { text: "On Hold", color: "#FF7324" },
};

export interface InvoiceItemProps {
  item: InvoiceDetailType;
  showBillTitle?: boolean;
  defaultExpand?: boolean;
  onExpand?: () => void;
  showProgress?: boolean;
  showSelect?: boolean;
  onSelect?: (id: string, checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  expandable?: boolean;
  showShorthand?: boolean;
  refetchQueriesForGenerateNextInvoice?:
    | Array<string | PureQueryOptions>
    | RefetchQueriesFunction;
}

const InvoiceItemCard: React.FC<InvoiceItemProps> = ({
  item,
  showShorthand,
  defaultExpand,
  showProgress = true,
  showBillTitle = true,
  showSelect,
  checked,
  disabled,
  onSelect,
  onExpand,
}) => {
  const navigate = useNavigate();

  const left = (
    <div className="min-w-56">
      <div className="font_semibold mb-1 text-xl text-primary-navyDark">
        {formatDate(item.createDateTime)}
      </div>
      <Typography.Text size="middle">
        {formatLabel(`For: `)}
        <span className="font_semibold text-primary-navy">
          {item.billedFor?.fullName}
        </span>
      </Typography.Text>
    </div>
  );

  const right = (
    <div className="min-w-56 flex justify-end">
      {showShorthand && (
        <div className="mr-2 text-sm font-semibold">
          {`Amount Due: 
          ${formatCurrency(
            new BigNumber(item?.total ?? 0)
              .minus(item?.amountPaid ?? 0)
              ?.toString()
          )}`}
        </div>
      )}
      {item.status !== InvoiceStatus.Draft && (
        <div
          style={{
            color: InvoiceStatusMap[item.status].color,
          }}
        >
          {InvoiceStatusMap[item.status].text}
        </div>
      )}
    </div>
  );

  const content = showShorthand && (
    <div className="mr-2 text-sm font-semibold min-w-32">
      {`${item?.class?.course?.nameShort} ${item?.class?.shorthand || ""}`}
    </div>
  );

  return (
    <>
      <ExpandableCard
        id={item.id}
        defaultExpand={defaultExpand}
        title={left}
        right={right}
        content={content}
        showSelect={showSelect}
        checked={checked}
        disabled={disabled}
        onSelected={onSelect}
        className="w-a4 mx-auto"
        bodyClassName=""
        headerClassName="px-6"
        onExpand={() => {
          if (onExpand) {
            onExpand();
          } else {
            navigate(
              `/finance/statement/${item.billedTo.id}/invoice/${item.id}`
            );
          }
        }}
        destroyOnCollapse
      >
        {showProgress && <Progress step={invoiceTransform(item)} />}
        <InvoiceDetail
          invoiceId={item.id}
          showBillTitle={showBillTitle}
          showPaymentRecord={false}
          className="mx-auto"
        />
      </ExpandableCard>
    </>
  );
};

export default InvoiceItemCard;

import { ReactComponent as IconNotification } from "@/assets/images/IconNotifi.svg";
import empty from "@/assets/images/empty.svg";
import {
  GetSystemNotifications,
  GetSystemNotificationsQuery,
  GetSystemNotificationsQueryVariables,
  NotificationHyperLinkType,
  SystemNotification,
  SystemNotificationSubscription,
  SystemNotificationSubscriptionVariables,
  SystemNotificationType,
} from "@/graphql";
import { useLoadMore } from "@/hooks/useLoadMore";
import { useSubscription } from "@apollo/client";
import {
  Badge,
  Empty,
  Popover,
  PopupNotificationType,
  Row,
  Spin,
  generateNotificationConfig,
  notification,
} from "@thepiquelab/archus-components-web";
import { PopupNotificationProps } from "@thepiquelab/archus-components-web/dist/components/PopupNotification";
import { useDBUser } from "@thepiquelab/web-auth0";
import { FC, memo, useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroller";
import NotificationItem from "./NotificationItem";

export const systemNotificationConfigMap: Record<
  SystemNotificationType,
  PopupNotificationType
> = {
  [SystemNotificationType.WhatsappLoginFailed]: "error",
  [SystemNotificationType.EmailSendFailed]: "error",
  [SystemNotificationType.WhatsappLogoutFailed]: "error",
  [SystemNotificationType.WhatsappMessageSendFailed]: "error",
  [SystemNotificationType.WhatsappOffline]: "success",
  [SystemNotificationType.WhatsappOnline]: "success",
  [SystemNotificationType.WhatsappAbnormalOffline]: "warning",
  [SystemNotificationType.ClassIsAvailableFromFull]: "info",
  [SystemNotificationType.AsyncRequestFailed]: "error",
  [SystemNotificationType.MessagePreparedFailed]: "error",
  [SystemNotificationType.ClassSyncGoogleCalendarFailed]: "error",
  [SystemNotificationType.ClassSyncZoomMeetingFailed]: "error",
  [SystemNotificationType.ClassSyncGoogleCalendarSuccess]: "success",
  [SystemNotificationType.ClassSyncZoomMeetingSuccess]: "success",
  [SystemNotificationType.TaskAssignment]: "info",
  [SystemNotificationType.NewUserRequest]: "info",
  [SystemNotificationType.GoogleMeetEventsConflict]: "warning",
};

export const getMessageWithLink = (
  systemNotification: SystemNotificationSubscription["systemNotification"]
) => {
  let { message } = systemNotification;
  const links = systemNotification?.links;
  const type = systemNotification?.type;
  const hyperLinkMessage = systemNotification?.hyperLinkMessage;

  if (links?.length && hyperLinkMessage) {
    let index = 0;
    message = hyperLinkMessage?.replaceAll("%s", (match) => {
      const link = links[index];
      index += 1;
      const { type, keyword, id } = link;
      switch (type) {
        case NotificationHyperLinkType.Parent:
          return `<a target="_blank" href="/customers/parents/profile/${id}">${keyword}</a>`;
        case NotificationHyperLinkType.SystemUser:
          return `<a target="_blank" href="/system-user/profile/${id}">${keyword}</a>`;
        case NotificationHyperLinkType.TaskList:
          return `<a target="_blank" href="/dashboard/operational">${keyword}</a>`;
        case NotificationHyperLinkType.Class:
          return `<a target="_blank" href="/course-management/class/${id}">${keyword}</a>`;
        case NotificationHyperLinkType.UserRequest:
          return `<a target="_blank" href="/parent-centre/arrangement-requests/${id}">${keyword}</a>`;
        default:
          return match;
      }
    });
  }

  if (type === SystemNotificationType.ClassIsAvailableFromFull) {
    const buttonClass = "ant-btn ant-btn-primary mt-6";
    const linkUrl = "/dashboard/waiting-list-overview";
    message += `<div class="flex flex-row-reverse"><button type="button" class="${buttonClass}"><a target="_blank" href="${linkUrl}">Go Manage</a></button><div>`;
  }
  return message;
};

const Notification: FC<{ iconClass?: string }> = ({ iconClass = "" }) => {
  const [
    getSystemNotifications,
    { data, loading, refetch },
    { hasMore, loadMore },
  ] = useLoadMore<
    GetSystemNotificationsQuery,
    GetSystemNotificationsQueryVariables
  >(GetSystemNotifications, "systemNotifications", false, false, true, {
    errorPolicy: "all",
  });
  const { data: subscriptionData } = useSubscription<
    SystemNotificationSubscription,
    SystemNotificationSubscriptionVariables
  >(SystemNotification);

  const { dbUser } = useDBUser();

  const userId = dbUser?._id;

  useEffect(() => {
    if (subscriptionData) {
      const { systemNotification } = subscriptionData;

      const message = getMessageWithLink(systemNotification);
      const type = systemNotification?.type;

      if (type) {
        const customConfig: Partial<PopupNotificationProps> = {};
        if (type === SystemNotificationType.EmailSendFailed) {
          customConfig.isDismissable = true;
          customConfig.duration = 0;
        }
        notification.open(
          generateNotificationConfig({
            type: systemNotificationConfigMap[systemNotification.type],
            title: systemNotification.title || systemNotification.type,
            // eslint-disable-next-line
            // @ts-ignore
            description: <div dangerouslySetInnerHTML={{ __html: message }} />,
            ...customConfig,
          })
        );
      }

      refetch?.({
        filterInput: {
          relatedUserId: userId,
        },
      });
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (userId) {
      getSystemNotifications({
        variables: {
          filterInput: {
            relatedUserId: userId,
          },
        },
      });
    }
  }, [userId]);

  const notificationData = useMemo(
    () =>
      (data?.systemNotifications?.items || []).filter(
        (item) => item?.type !== SystemNotificationType.ClassIsAvailableFromFull
      ),
    [data]
  );

  const showBadge = useMemo(
    () => notificationData.every((i) => i.read),
    [notificationData]
  );

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={
        <div style={{ width: 420, maxHeight: 720 }} className="overflow-y-auto">
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              <Row justify={"center"} key={0}>
                <Spin />
              </Row>
            }
            useWindow={false}
          >
            {notificationData.length ? (
              <>
                {notificationData.map((item) => (
                  <NotificationItem item={item} key={item._id} />
                ))}
              </>
            ) : (
              <Empty
                image={empty}
                className="flex flex-col items-center justify-center w-half"
                imageStyle={{ width: "250px", height: "250px" }}
              />
            )}
          </InfiniteScroll>
        </div>
      }
    >
      {!showBadge ? (
        <Badge dot>
          <IconNotification className={`w-4 h-4 cursor-pointer ${iconClass}`} />
        </Badge>
      ) : (
        <IconNotification className={`w-4 h-4 cursor-pointer ${iconClass}`} />
      )}
    </Popover>
  );
};

export default memo(Notification);

import { showOrganizationLogo } from "@/components/navigation/SideBar/SideBar";
import { AppConfig } from "@/config";
import { InvoiceStatus, VoidableStatus } from "@/graphql";
import { enumToArray } from "@/utils/enumUnit";
import formatLabel from "@/utils/formatLabel";
import { formatDate } from "@thepiquelab/web-moment";
import { FC } from "react";
import CommonBadge from "../../Badge/CommonBadge";

export interface BillTitleProps {
  date?: Date;
  title?: string;
  subTitle?: string;
  status?: InvoiceStatus | VoidableStatus;
  showPaymentStatus?: boolean;
  showOnHoldStatus?: boolean;
  showVoidStatus?: boolean;
}

export const invoiceStatusMap = new Map(
  enumToArray(InvoiceStatus).map((status) => {
    switch (status.value) {
      case InvoiceStatus.Paid:
        return [
          status.value,
          { text: "Paid", color: "#2BD832", bgColor: "#64DD1733" },
        ];
      case InvoiceStatus.Pending:
        return [
          status.value,
          { text: "Pending", color: "#5B9DFF", bgColor: "#5B9DFF33" },
        ];
      case InvoiceStatus.Overdue:
        return [
          status.value,
          { text: "Overdue", color: "#F36B7F", bgColor: "#F36B7F33" },
        ];
      case InvoiceStatus.Void:
        return [
          status.value,
          { text: "Void", bgColor: "#F0F1F9", color: "#4F537B" },
        ];

      default:
        return [
          status.value,
          { text: status.key, color: "#8C9297", bgColor: "#8C9297" },
        ];
    }
  })
);

const BillTitle: FC<BillTitleProps> = (props) => {
  const {
    date,
    subTitle = "",
    title = "",
    status,
    showPaymentStatus = false,
    showOnHoldStatus = false,
    showVoidStatus = false,
  } = props;
  return (
    <div className="flex justify-between items-center mt-6">
      <div>
        {showOnHoldStatus && (
          <CommonBadge
            textColor={"#FF7324"}
            bgColor={"#FFE3D3"}
            label={"On Hold"}
            className="w-maxcontent mr-2"
          />
        )}
        {![InvoiceStatus.Draft, InvoiceStatus.Void].includes(
          status as InvoiceStatus
        ) &&
          showPaymentStatus && (
            <CommonBadge
              textColor={invoiceStatusMap.get(status)?.color}
              bgColor={invoiceStatusMap.get(status)?.bgColor}
              label={status}
              className="w-maxcontent"
            />
          )}
        {[VoidableStatus.Void].includes(status as VoidableStatus) &&
          showVoidStatus && (
            <CommonBadge
              textColor={invoiceStatusMap.get(status)?.color}
              bgColor={invoiceStatusMap.get(status)?.bgColor}
              label={status}
              className="w-maxcontent"
            />
          )}

        <div className="font-semibold tracking-wider my-2">{title}</div>
        <div className="font-bold text-2xl my-2">
          {date ? formatDate(date) : ""}
        </div>
        <div className="text-primary-navyLight tracking-wider my-2">
          {formatLabel(subTitle)}
        </div>
      </div>
      <div className="text-right text-primary-navyLight">
        <div className="flex justify-end">
          <img
            src={showOrganizationLogo(AppConfig.OrganizationName)}
            alt={"logo"}
            className="object-contain h-14 w-auto"
          />
        </div>
        <div className="pt-2">
          GR.iD, 1 Selegie Road, #07-01, Singapore 188306
        </div>
        <div>{`GST ${formatLabel("Registration No.: 201700819R")}`}</div>
      </div>
    </div>
  );
};

export default BillTitle;

/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { ClassSeatDetailDTO } from "@/components/class/NewClassCapacity/classUtils";
import UserAvatar from "@/components/common/UserAvatar/UserAvatar";
import { StudentCell } from "@/components/widget/UserCell/StudentCell";
import { Enrollment, Lesson } from "@/graphql";
import { Col, Row } from "@thepiquelab/archus-components-web";
import React, { memo } from "react";
import ClassStatusBtn from "../../RegistrationDetail/common/StatusBtn/ClassStatusBtn";

const EnrollmentItem: React.FC<{
  data?: ClassSeatDetailDTO;
  lesson?: Lesson[];
  currentEnrollment?: Enrollment;
  isEmpty?: boolean;
}> = (itemProps) => {
  const { isEmpty = false, data, lesson, currentEnrollment } = itemProps;

  const { student } = data;

  return (
    <Row className="px-4 py-2 min-h-16">
      <Col span={12}>
        {isEmpty ? (
          ""
        ) : (
          <>
            {student ? (
              <StudentCell profile={student} />
            ) : (
              <UserAvatar isStudent name={""} gender={"female"} size={48} />
            )}
          </>
        )}
      </Col>
      <Col
        span={12}
        className="flex flex-col items-start justify-center text-4 leading-tight "
      >
        <ClassStatusBtn
          seats={[data]}
          currentEnrollment={currentEnrollment}
          lesson={lesson}
        />
      </Col>
    </Row>
  );
};

export default memo(EnrollmentItem);

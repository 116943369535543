import {
  ClassFilterInput,
  RegistrationStage,
  StudentBaseFieldsFragment,
  StudentFilterInput,
} from "@/graphql";
import { enumToLabelArray } from "@/utils/enumUnit";
import {
  Input,
  Select,
  Table,
  message,
} from "@thepiquelab/archus-components-web";
import { CourseApi, UserApi } from "@thepiquelab/web-api";
import { FilterOptionClassDTO, StudentOptionDTO } from "@thepiquelab/web-types";
import { cloneDeep, debounce, trim } from "lodash";
import React, { useCallback, useState } from "react";
import {
  CsvColumnTitles,
  EnumImportRegistrationTempTitle,
  ImportRegistrationColumnMap,
  ImportRegistrationRecord,
  checkIsRowValid,
} from "../Config";
import { ClassForValidate } from "../useClassListToValidate";
import RemoteSelect from "./RemoteSelect";
import { RenderWrap } from "./RenderWrap";

interface UploadFileStePropProp {
  dataSource: ImportRegistrationRecord[];
  setRegistrationData: (data: ImportRegistrationRecord[]) => void;
  studentList: StudentBaseFieldsFragment[];
  mergeStudentListUniq: (students: StudentBaseFieldsFragment[]) => void;
  queryStudents: (
    filterInput: StudentFilterInput
  ) => Promise<StudentBaseFieldsFragment[]>;
  classList: ClassForValidate[];
  queryClasses: (filterInput: ClassFilterInput) => Promise<ClassForValidate[]>;
  mergeClassListUniq: (classes: ClassForValidate[]) => void;
  setSelectedRows: (rowKeys: ImportRegistrationRecord[]) => void;
  selectedRows: ImportRegistrationRecord[];
}

const DataPreviewStep: React.FC<UploadFileStePropProp> = ({
  dataSource,
  setRegistrationData,
  queryStudents,
  mergeStudentListUniq,
  studentList,
  classList,
  queryClasses,
  mergeClassListUniq,
  selectedRows,
  setSelectedRows,
}) => {
  const [editingTableKey, setEditingTableKey] = useState<string>("");

  const onStudentNameSearch = useCallback(
    debounce(async (value) => {
      const dataWithStudentName = await queryStudents({
        studentNames: [trim(value)],
      });
      const studentWithStudentNames = dataWithStudentName || [];

      mergeStudentListUniq([...studentWithStudentNames]);
    }, 300),
    []
  );

  const onStudentIdSearch = useCallback(
    debounce(async (value) => {
      const dataWithStudentId = await queryStudents({
        userIds: [trim(value)],
      });
      const studentWithStudentIds = dataWithStudentId || [];

      mergeStudentListUniq([...studentWithStudentIds]);
    }, 300),
    []
  );

  const onClassCodeSearch = useCallback(
    debounce(async (value) => {
      const dataWithClassCodes = await queryClasses({
        classCodes: [trim(value)],
      });
      const classWithClassCodes = dataWithClassCodes || [];

      mergeClassListUniq([...classWithClassCodes]);
    }, 300),
    []
  );

  const renderMap = {
    [EnumImportRegistrationTempTitle.EnrollmentType]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.EnrollmentType;
        return (
          <Select
            value={text}
            className="w-full"
            onChange={(value) => {
              const result = cloneDeep(dataSource);
              result[index][dataIndex] = value;
              setRegistrationData(result);
              setSelectedRows([]);
            }}
            options={enumToLabelArray(RegistrationStage)}
          />
        );
      },
    },
    [EnumImportRegistrationTempTitle.StudentID]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.StudentID;

        return (
          <RemoteSelect<StudentOptionDTO>
            request={(prpps) => UserApi.getStudentsOptions(prpps)}
            value={text}
            fieldLabel="userId"
            fieldValue="userId"
            onChange={(value: any) => {
              const val = value?.value;
              const result = cloneDeep(dataSource);
              result[index][dataIndex] = val;
              setRegistrationData(result);
              onStudentIdSearch(val);
              setSelectedRows([]);
            }}
          />
        );
      },
    },
    [EnumImportRegistrationTempTitle.StudentName]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.StudentName;

        return (
          <RemoteSelect<StudentOptionDTO>
            request={(prpps) => UserApi.getStudentsOptions(prpps)}
            value={text}
            fieldLabel="fullName"
            fieldValue="fullName"
            onChange={(value: any) => {
              const val = value?.value;
              const result = cloneDeep(dataSource);
              result[index][dataIndex] = val;
              setRegistrationData(result);
              onStudentNameSearch(val);
              setSelectedRows([]);
            }}
          />
        );
      },
    },
    [EnumImportRegistrationTempTitle.ClassCode]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.ClassCode;

        return (
          <RemoteSelect<FilterOptionClassDTO>
            request={(props) => CourseApi.getClassOptions(props)}
            value={text}
            fieldLabel="classCode"
            fieldValue="classCode"
            onChange={(value: any) => {
              const val = value?.value;
              const result = cloneDeep(dataSource);
              result[index][dataIndex] = val;
              setRegistrationData(result);
              onClassCodeSearch(val);
              setSelectedRows([]);
            }}
          />
        );
      },
    },
    [EnumImportRegistrationTempTitle.EnrollmentStartDate]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.EnrollmentStartDate;

        return (
          <Input
            value={text}
            className="w-full"
            onChange={(e) => {
              const { value } = e.target;

              const result = cloneDeep(dataSource);
              result[index][dataIndex] = value;
              setRegistrationData(result);
              setSelectedRows([]);
            }}
          />
        );
      },
    },
    [EnumImportRegistrationTempTitle.TrialDates]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.TrialDates;

        return (
          <Input
            value={text}
            className="w-full"
            onChange={(e) => {
              const { value } = e.target;

              const result = cloneDeep(dataSource);
              result[index][dataIndex] = value;
              setRegistrationData(result);
              setSelectedRows([]);
            }}
          />
        );
      },
    },
    [EnumImportRegistrationTempTitle.StartLessonNo]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.StartLessonNo;

        return (
          <Input
            value={text}
            className="w-full"
            onChange={(e) => {
              const { value } = e.target;

              const result = cloneDeep(dataSource);
              result[index][dataIndex] = value;
              setRegistrationData(result);
              onStudentNameSearch(value);
              setSelectedRows([]);
            }}
          />
        );
      },
    },
    [EnumImportRegistrationTempTitle.ReserveForTrial]: {
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => {
        const dataIndex = EnumImportRegistrationTempTitle.ReserveForTrial;

        return (
          <Select
            value={text}
            className="w-full"
            onChange={(value) => {
              const result = cloneDeep(dataSource);
              result[index][dataIndex] = value;
              setRegistrationData(result);
              setSelectedRows([]);
            }}
            options={[
              { label: "Y", value: "Y" },
              { label: "N", value: "N" },
            ]}
          />
        );
      },
    },
  };

  const columns = CsvColumnTitles?.map(
    (title: EnumImportRegistrationTempTitle) => ({
      ...ImportRegistrationColumnMap[title],
      render: (
        text: string,
        record: ImportRegistrationRecord,
        index: number
      ) => (
        <RenderWrap
          isEditing={editingTableKey === record.rowKey}
          title={title}
          value={text}
          rowKey={record.rowKey}
          setEditingTableKey={setEditingTableKey}
          data={{
            students: studentList,
            record,
            classes: classList,
          }}
        >
          {renderMap[title].render(text, record, index)}
        </RenderWrap>
      ),
    })
  );

  return (
    <div>
      <Table
        columns={columns}
        rowKey="rowKey"
        pagination={false}
        dataSource={dataSource}
        scroll={{
          x: columns?.reduce((result, column) => result + column?.width, 0),
          y: "45vh",
        }}
        onRow={(record, index) => ({
          onDoubleClick: () => {
            if (editingTableKey !== record.rowKey) {
              setEditingTableKey(record.rowKey);
            } else {
              setEditingTableKey("");
            }
          },
        })}
        rowSelection={{
          selectedRowKeys: selectedRows.map((record) => record.rowKey),
          onChange: (selectedRowKeys, selectedRows, { type }) => {
            const validSelectedRows = selectedRows.filter((record) =>
              checkIsRowValid(record, {
                students: studentList,
                classes: classList,
                record,
              })
            );
            setSelectedRows(validSelectedRows);

            /**
             *   show error message only when user tried to select one invalid row
             */
            if (
              selectedRows.length &&
              type === "single" &&
              selectedRows.length > validSelectedRows.length
            ) {
              message.error("Item selected is invalid! ");
            }
          },
          columnWidth: 48,
          fixed: true,
        }}
      />
    </div>
  );
};
export default DataPreviewStep;

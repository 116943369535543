import { CourseApi } from "@thepiquelab/web-api";
import { ReferenceData, ReferenceDataCategory } from "@thepiquelab/web-types";
import { useRequest } from "ahooks";
import { useMemo } from "react";
import { OptionType } from "../types";

export const useReferenceDataOptions = (
  category: ReferenceDataCategory
): {
  loading: boolean;
  options: OptionType[];
  references: ReferenceData[];
} => {
  const { loading, data: references = [] } = useRequest(
    () => CourseApi.getReferencesByCategory(category),
    {
      cacheKey: `reference-data-options-${category}`,
      staleTime: 5000,
    }
  );

  const options = useMemo(
    () =>
      references
        .filter((i) => !i.isArchived)
        .map((i) => ({
          label: i.value,
          value: i.key,
        })),
    [references]
  );

  return {
    loading,
    options,
    references,
  };
};

import formatErrorMessage from "@/utils/formatErrorMessage";
import { graphqlValidator } from "@/utils/graphqlValidator";
import { useMutation } from "@apollo/client";
import { Form, message, Modal } from "@thepiquelab/archus-components-web";
import React, { useState } from "react";
import { ReactComponent as IconPlus } from "../../../../assets/images/IconPlus.svg";
import {
  AddFeesForInvoice,
  AddFeesForInvoiceMutation,
  AddFeesForInvoiceMutationVariables,
  AddLineItemCommand,
} from "../../../../graphql";
import formatLabel from "../../../../utils/formatLabel";
import AddFeesForm from "./AddFeesForm";

interface AddFeesButtonProps {
  invoiceId: string;
  callback?: () => void;
}

const AddFeesButton: React.FC<AddFeesButtonProps> = ({
  invoiceId,
  callback,
}) => {
  const [form] = Form.useForm();
  const [addFeesForInvoice, { loading }] = useMutation<
    AddFeesForInvoiceMutation,
    AddFeesForInvoiceMutationVariables
  >(AddFeesForInvoice);

  const [visible, setVisible] = useState(false);

  const handleApply = async (lineItem: AddLineItemCommand): Promise<void> => {
    try {
      await addFeesForInvoice({
        variables: { input: { invoiceId, lineItem } },
      });
      callback?.();
      setVisible(false);
      form.resetFields();
      message.success("Fee added successfully!");
    } catch (error) {
      if (error?.graphQLErrors?.length) {
        graphqlValidator(error?.graphQLErrors, form, (data) => {
          if (!data?.length) {
            message.error(formatErrorMessage(error));
            return;
          }
          if (data?.length) {
            if ((data[0].name as string[]).includes("lineItem")) {
              const formattedData = data.map((d) => ({
                ...d,
                name: (d.name as string[]).filter((n) => n !== "lineItem"),
              }));

              form.setFields(formattedData);
              form?.scrollToField(formattedData?.[0]?.name);
            } else {
              form?.setFields(data);
              form?.scrollToField(data?.[0]?.name);
            }
          }
        });
      } else {
        message.error(formatErrorMessage(error));
      }
    }
  };

  return (
    <>
      <div
        className="inline-flex text-primary-blue cursor-pointer"
        onClick={() => setVisible(true)}
      >
        <IconPlus />
        <span className="ml-2">{formatLabel("Add Item")}</span>
      </div>
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        title={formatLabel("Add Item")}
        destroyOnClose
        footer={null}
        bodyStyle={{ paddingBottom: 10 }}
        width={500}
      >
        <AddFeesForm
          form={form}
          onSubmit={handleApply}
          loading={loading}
          onCancel={() => setVisible(false)}
        />
      </Modal>
    </>
  );
};

export default AddFeesButton;
